import React from "react";
import { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import IconBdigitall from "../../imgs/BDIGITALL.svg";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import MzLabel from "../../components/ui/MzLabel";
import MzErrorText from "../../components/ui/MzErrorText";
import AlertError from "../../components/ui/AlertError";
import AlertSuccess from "../../components/ui/AlertSuccess";
import { useNavigate } from "react-router-dom";
import bg from "../../imgs/BG_LOGIN.png";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

function Login() {
  const [errorLogin, setErroNotFound] = useState();
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmitLogin = (values) => {
    setIsLoading(true);
    setErroNotFound();
    setSuccess();
    Api.post(
      Urls.PASSWORD_RESET,
      {
        email: values.email,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setSuccess(`Votre nouveau mot de passe a été envoyé par email. `);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErroNotFound("Email non valide");
        } else {
          setErroNotFound(err.response.data.message);
        }
        setIsLoading(false);
      });
  };

  //initial values form state
  const initialValues = {
    email: "",
  };

  //validators form state
  const validationSchema = Yup.object({
    email: Yup.string().email("Format email invalid").required("Ce champ est obligatoire"),
  });

  return (
    <Box sx={{ backgroundImage: `url(${bg})`, backgroundSize: "cover", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ padding: "2.5rem", width: "450px", maxHeight: "560px", borderRadius: "24px", margin: "1rem" }} backgroundColor="white">
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "3rem" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <img src={IconBdigitall} height={50} alt="moonz logo" style={{ marginRight: "1rem" }} />
            </Box>
          </Box>
          <Typography marginBottom="2rem" fontWeight="bold">
            Mot de passe oublié
          </Typography>
        </Box>
        <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitLogin}>
          {({ values }) => (
            <Form>
              <Box className="field" sx={{ marginBottom: "1rem" }}>
                <MzLabel htmlFor="email" name="email">
                  Email
                </MzLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="email"
                  type="email"
                  placeholder="Email"
                  id="email"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="email" component={MzErrorText} />
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button sx={{ height: "3.5rem" }} disabled={isLoading} className="submit" type="submit" variant="primary" fullWidth>
                  Récupérer
                </Button>
              </Box>
              {errorLogin && <AlertError>{errorLogin}</AlertError>}
              {success && (
                <AlertSuccess sx={{ cursor: "pointer" }}>
                  {success}{" "}
                  <Typography onClick={() => navigate("/login")} sx={{ textDecoration: "underline", cursor: "pointer" }} fontSize="0.875rem" display="inline">
                    Retournez vers Authentification
                  </Typography>
                </AlertSuccess>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Login;
