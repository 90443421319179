import { Box, Button, TextField, Typography, IconButton, CircularProgress } from '@mui/material'
import { Formik, Form, Field } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import { useContext, useRef, useState } from 'react';
import { colors } from '../theme/Colors';
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import API from '../api/Api_1_3';
import { useAuthContext } from '../hooks/useAuthContext';


const SendPasscode = () => {
    const [colls, setColls] = useState([])
    const { user } = useAuthContext();
    const access_token = user?.access_token;
    const cards = user?.user?.enterprise?.allowed_cards_number
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        collaborators_emails: colls,
      });
    const formikRef = useRef();

    const handleAddColls = () => {
        setColls((preValues)=>{
         return [...preValues, '']
        })
    }

    const handleSubmit = async (values) => {
        setLoading(true)
        
        try {
           const res = await API.post('/prosumers/send-passcode-to-collaborators', 
            {
                collaborators_emails: values.collaborators_emails
            }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
            });

            if(res.status === 200){
                toast.success('passcode(s) envoyée(s) avec success')
            }
        } 
        
        catch (error) {
            toast.error(error.response.data.message);
        }

        finally{
        setLoading(false)
        }
    };
    return ( 
        <Box>
            <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Box>
                <Button 
                  variant="secondary" 
                  startIcon={<ArrowBackIosIcon/>}
                  onClick={() => navigate('/admin/gestion-des-cartes/')} // Navigate to the specified route
                >
                  Retour 
                </Button>
            </Box>
            {
             cards - 1 > 0 ? 
                <Formik
             innerRef={formikRef}
             initialValues={formValues}
             onSubmit={handleSubmit}
             enableReinitialize
            >
             {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1} mt={4} mb={5}>
                <Typography variant='h5' fontSize={'25px'} fontWeight={500} textAlign={'center'}>Add Collaborators Emails</Typography>
                <Typography variant='body2' fontSize={'12px'} fontWeight={500} color={colors.gray} mb={4} textAlign={'center'} sx={{width:{
                    xs:'100%',
                    sm:'100%',
                    md:'80%',
                    lg:'80%',
                    xl:'80%'
                }}}>You can invite up to {cards - 1} collaborators by entering their email addresses. Collaborators will receive an invitation to join your project.</Typography>
                        
                    <Box display={'flex'} width="90%" flexDirection={'column'} gap={2} mb={2}>
                          {colls.map((email, index) => (
                          <Box display={'flex'}>
                            <Field
                              key={index}
                              name={`collaborators_emails[${index}]`}
                              as={TextField}
                              placeholder="Enter collaborator's email"
                              fullWidth
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F7F7F7',
                                  width: '100%',
                                  borderRadius: '8px',
                                  border: 'transparent'
                                },
                              }}
                            />
                            <IconButton
                                onClick={() => {
                                  setColls((prevValues) => prevValues.filter((_, i) => i !== index));
                                }}
                                sx={{ color: 'red' }} // Set the color to red
                              >
                                <CloseIcon />
                            </IconButton>
                          </Box>
                          ))}
                    </Box>
                        
                          {
                          colls.length < cards && 
                          <Button
                            variant="secondary"
                            onClick={handleAddColls}
                            startIcon={<AddIcon/>}
                            sx={{borderRadius:'8px'}}
                          >
                            Add Another Email
                          </Button>
                          }
                        <Button
                            variant="primary"
                            type="submit"
                            sx={{borderRadius:'8px', padding:1}}
                          >
                            {loading ? <CircularProgress sx={{color: colors.textWhite}}/> : 'Envoyer'}
                        </Button>
                    </Box>
                </Form>
             )}
                </Formik>
                : 
                <Box display="flex" alignItems="center" justifyContent="Center" pt={12}>
                    <Typography variant="h6">
                        Sorry, you can't add more collaborators.
                    </Typography>
                </Box>    
        }
        </Box>
     );
}
 
export default SendPasscode;