import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { Box, Modal } from "@mui/material";
import { Activity } from 'iconsax-react'
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import QrCodeIcon from '@mui/icons-material/QrCode';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareIcon from '@mui/icons-material/Share';
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import { colors } from "../../theme/Colors";
import IconBdigitalWhite from "../../imgs/BDIGITALL_WHITE.svg";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DefaultProfilePic from "../../imgs/user.png";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconCommande from "../../imgs/commande.svg";
import IconApresVente from "../../imgs/apresVente.svg";
import IconEntreprise from "../../imgs/entreprise.svg";
import IconCarte from "../../imgs/carte.svg";
import BdigitalWhite from "../../imgs/BDIGITALL_WHITE.svg";
import { toast, ToastContainer } from 'react-toastify'
import { NavLink } from "react-router-dom";
import { ModalStyleQRCODE } from "../../theme/ModalStyleQRCODE";
import { useProfile } from '../../context/ProfileContext';

const drawerWidth = 300;
const navigation = [
  { title: "Mon entreprise", path: "/admin/mon-entreprise" },
  { title: "Gestion des BCARDs", path: "/admin/gestion-des-cartes" },
  { title: "Gestion des commandes", path: "/admin/gestion-des-commandes" },
  { title: "Service après vente", path: "/admin/service-apres-vente" },
];

const icons = {};
icons["Mon entreprise"] = IconEntreprise;
icons["Gestion des BCARDs"] = IconCarte;
icons["Gestion des commandes"] = IconCommande;
icons["Service après vente"] = IconApresVente;

function SharedLayoutAbonnee(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const access_token = user?.access_token;
  const id = user?.user?.id;
  const { selectedProfile } = useProfile()

  const imageSrc = null;
  const [data, setData] = useState({});
  const imageProfile = useRef();
  useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}/account-details`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setData(response.data.user);
        const img = new Image();
        img.onload = function () {
          if (this.width > this.height) {
            imageProfile.current.style.height = "3rem";
            imageProfile.current.style.maxWidth = "auto";
            imageProfile.current.style.display = "block";
          } else {
            imageProfile.current.style.height = "auto";
            imageProfile.current.style.width = "3rem";
            imageProfile.current.style.display = "block";
          }
        };
        img.src = response?.data?.user?.url_profile_picture;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, []);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Box sx={{ padding: "2.5rem 0rem" }}>
            <Box>
              <img src={IconBdigitalWhite} width={150} alt="moonz logo" />
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List>
        {navigation.map((l, index) => (
          <ListItem key={index} disablePadding>
            <NavLink id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={l.path}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ListItemIcon>
                  <img style={{ width: "20px", marginLeft: "20px" }} src={icons[`${l.title}`]} />
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary={l.title} />
              </Box>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const [open, setOpen] = useState(false);
  
  const share = () => {
    var browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "edge";
        case agent.indexOf("edg") > -1:
          return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "firefox";
        case agent.indexOf("safari") > -1:
          return "safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    const shareData = {
      title: "Partage de code QR",
      url: `${process.env.REACT_APP_PROFILE + selectedProfile.url_profile }`,
      text: "Venez consulter mon profil sur BdigitAll !",
    };
    if (
      navigator.share &&
      navigator.canShare(shareData) &&
      browser !== "chromium based edge (dev or canary)" &&
      window.location.href.split("/")[0] === "https:"
    ) {
      navigator.share(shareData);
    } else {
      navigator["clipboard"].writeText(`${shareData.url}`);
      toast.success("Le lien a été copié !");
    }
    handleCloseUserMenu()
  };

  const previewProfile = () =>{
    navigate(`${process.env.NODE_ENV === 'production' ? selectedProfile?.url_profile : '/preview/' + selectedProfile?.url_profile}`, '_blank', { replace: true })
    handleCloseUserMenu()
  }

  return (
    <Box sx={{ display: "flex", 
      backgroundColor: colors.textWhite, 
      width:'100%', 
      height: {
        xs:'100%',
        sm:'100%',
        md:'max-height',
        lg:'100vh',
        xl:'100vh'
      }
      }}
    >
      <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
        }}
      >
        <Toolbar sx={{ backgroundColor: `${colors.main_purple}`, color: "white", display: "flex", justifyContent: "space-between", py:1 }}>
          <Box sx={{ justifyContent: "space-between", width: "100%", alignItems: "center" }} display="flex" gap="1.5rem">

            {/* bdigitall logo */}
            <Box sx={{ display: { xs: "none", md: "block", lg: "block", sm: "block" } }}>
              <img src={BdigitalWhite} style={{ height: "40px", width: "120px" }} alt="imagebdigital" />
            </Box>

            {/* bdigitall logo - mobile */}
            <Box sx={{ display: { xs: "block", md: "none", lg: "none", sm: "none" } }}>
              <img style={{ height: "20px", width: "100px" }} src={BdigitalWhite} alt="imagebdigital" />
            </Box>

            {/* user infos */}
            <Box display="flex" gap={1} alignItems="center">

              {/* profile picture */}
                    <Avatar sx={{ width: "2.3rem", height: "2.3rem", borderRadius: "50%" }}>
                      {data?.url_profile_picture === null ? <img style={{ height: "2.3rem", width: "2.3rem" }} src={DefaultProfilePic} alt="default_pic" /> : <img ref={imageProfile} style={{ objectFit: "fill", objectPosition: "50% 50%", display: "none" }} id="imgNavbar" src={data?.url_profile_picture} alt="account_pic" />}
                    </Avatar>

              <IconButton sx={{color: colors.textWhite}} onClick={handleOpenUserMenu}>
                <KeyboardArrowDownIcon/>
              </IconButton>

              <Menu
                sx={{ mt: "45px" }}
                PaperProps={{
                  sx: {
                    borderRadius: '20px',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                  {/* account section */}
                  <MenuItem
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      padding: '7px 10px',
                      fontSize: '14px',
                      borderBottom: '1px solid' + colors.tFieldGray,
                      pr:{
                        xs:0,
                        sm:0,
                        md:10,
                        lg:10,
                        xl:10
                      }
                    }}
                  >
                    <Avatar sx={{ width: "2.2rem", height: "2.2rem", borderRadius: "50%" }}>
                      {data?.url_profile_picture === null ? <img style={{ height: "2.2rem", width: "2.2rem" }} src={DefaultProfilePic} alt="default_pic" /> : <img ref={imageProfile} style={{ objectFit: "fill", objectPosition: "50% 50%", display: "none" }} id="imgNavbar" src={data?.url_profile_picture} alt="account_pic" />}
                    </Avatar>
                    <Box>
                    <Typography variant="body2" fontWeight={500}>
                      {user?.user.first_name + ' ' + user?.user?.last_name}
                    </Typography>
                    <Typography variant="body2" color={colors.tFieldGray}>
                      {user?.user.function}
                    </Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => previewProfile()} 
                    sx={{
                      padding: '6px 10px',
                      fontSize: '14px',
                      pr:{
                        xs:0,
                        sm:0,
                        md:10,
                        lg:10,
                        xl:10
                      }
                    }}
                  >
                    <VisibilityOutlinedIcon 
                      style={{
                        fontSize: 35,
                        color: colors.main_purple,
                        padding: '4px',
                        marginRight: '5px'
                      }}
                    />
                    Afficher mon Profile
                  </MenuItem>

                  <MenuItem onClick={() => share()} 
                    sx={{
                      padding: '6px 10px',
                      fontSize: '14px',
                      pr:{
                        xs:0,
                        sm:0,
                        md:10,
                        lg:10,
                        xl:10
                      }
                    }}
                  >
                    <ShareIcon
                      style={{
                        fontSize: 35,
                        color: colors.main_purple,
                        padding: '4px',
                        marginRight: '5px'
                      }}
                    />
                    Partager mon Profile
                  </MenuItem>
                  
                  <MenuItem onClick={() => {
                    setOpen(true)
                    handleCloseUserMenu()
                  }}
                    sx={{
                      padding: '6px 10px',
                      fontSize: '14px'
                    }}
                  >
                    <QrCodeIcon
                      style={{
                        fontSize: 35,
                        color: colors.main_purple,
                        padding: '4px',
                        marginRight: '5px'
                      }}
                    />
                    Afficher mon QR Code
                  </MenuItem>

                  <MenuItem onClick={() => handleLogout()}
                    sx={{
                      padding: '6px 10px',
                      fontSize: '14px'
                    }}
                  >
                    <LogoutIcon
                      style={{
                        fontSize: 35,
                        color: colors.main_purple,
                        padding: '4px',
                        marginRight: '5px'
                      }}
                    />
                    Déconnecter
                  </MenuItem>
              </Menu>
            </Box>
              <Modal open={open} onClose={() => setOpen(false)}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={ModalStyleQRCODE}
                    >
                      <Avatar
                        sx={{
                          position: "relative",
                          width: "17rem",
                          height: "17rem",

                          marginBottom: "0rem",
                        }}
                        variant="rounded"
                      >
                        {data?.url_qrcode ? (
                          <img
                            alt="url_logo"
                            src={data?.url_qrcode}
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        ) : (
                          <img
                            src={null}
                            alt="placeholder"
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        )}
                      </Avatar>
                    </Box>
              </Modal>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar />

        <Box 
          sx={{ 
            padding: "1rem", 
            backgroundColor: 'white'
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

SharedLayoutAbonnee.propTypes = {
  window: PropTypes.func,
};

export default SharedLayoutAbonnee;
