import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Modal, Autocomplete, CircularProgress } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { columns } from "../../data/cartesAbonnesData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme/Colors";
import { toast, ToastContainer } from "react-toastify";
import { ModalStyle } from "../../theme/ModalStyles";
import FileIcon from "../../imgs/file.svg";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { Stack } from "@mui/system";
import SendIcon from '@mui/icons-material/Send';

function CartesAbonnes() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //navigate to create enterprise
  const navigate = useNavigate();

  const status = [
    { id: "COMMANDED", name: "Commandée" },
    { id: "SUSPENDED", name: "Suspendue" },
    { id: "ACTIVE", name: "Active" },
  ];

  const formRef = useRef();

  //add docs modal
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [docToAddName, setDocToAddName] = useState();
  const handleOpenAddDoc = () => {
    setOpenAddDoc(true);
  };
  const handleCloseAddDoc = () => setOpenAddDoc(false);

  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [],
    document: "",
  };

  //File config
  const FILE_SIZE = 5000 * 1024;

  //data grid pages configuration
  const [selectedIDs, setSelectedIDs] = useState([]);
  // const [openParams, setOpenParams] = React.useState(false);
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);

  //initialValues
  const initialValuesCartes = {
    id: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    department: "",
    function: "",
    expirationDateMin: "",
    expirationDateMax: "",
    email: "",
    statusCard: "",
  };

  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
    lastName: Yup.string(),
    firstName: Yup.string(),
    phoneNumber: Yup.string(),
    department: Yup.string(),
    function: Yup.string(),
    expirationDateMin: Yup.date(),
    expirationDateMax: Yup.date(),
    email: Yup.string(),
    statusCard: Yup.string(),
  });

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  //on page load
  useEffect(() => {
    setIsLoading(true);
    Api.get(Urls.GET_COLLABS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
      .then((response) => {
        setPage(0);
        setIsLoading(false);
        setRowsAPI(response.data.users);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [access_token, perPage, navigate]);

  //onPageChange
  useEffect(() => {
    setIsLoading(true);
    Api.get(Urls.GET_COLLABS + `?perPage=${perPage}&page=${page + 1}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: nonEmptyObject(formRef.current.values),
    })
      .then((response) => {
        setRowsAPI(response.data.users);
        setTotal(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [page, access_token, perPage, navigate]);

  // //on search
  const onSubmitSearchUsers = (values) => {
    let realValues = nonEmptyObject(values);

    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_COLLABS + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: realValues,
    })
      .then((response) => {
        setIsLoading(false);
        setRowsAPI(response.data.users);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const [loadingDoc, setLoadingDoc] = useState(false);
  const [progress, setProgress] = useState(null);
  //add docs

  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  const addDocs = (values) => {
    setLoadingDoc(true);
    Api.post(
      Urls.ADD_USER_DOCUMENT,
      {
        title: values.title,
        user_ids: selectedIDs,
        document: values.document,
      },
      configs
    )
      .then((response) => {
        if (response.status === 201) {
          handleCloseAddDoc();
          setLoadingDoc(false);
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        setDocToAddName("");
        setLoadingDoc(false);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  return (
    <Box>
      {/* Header Section */}
      {/* Filter  Section */}
      {/* Form Section */}
      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          innerRef={formRef}
          //initial values
          initialValues={initialValuesCartes}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtre
                  </Typography>
                </Box>

                {/*Search fields  */}

                <Box display="flex" maxWidth="900px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="id" type="input" as={TextField} label="Id" size="small" />
                    <ErrorMessage name="id" as={MzErrorText} />
                  </Box>
                  {/* <Box flex={1.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Entreprise (nom commercial )" name="collaboratedEnterpriseCommercialName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="collaboratedEnterpriseCommercialName" component={MzErrorText} />
                  </Box> */}
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Nom" name="lastName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="lastName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Prénom" name="firstName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="firstName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Téléphone" name="phoneNumber" as={TextField} type="input" size="small" />
                    <ErrorMessage name="phoneNumber" component={MzErrorText} />
                  </Box>
                </Box>

                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { md: "row", xs: "column" } }}>
                  <Box flex={0.6} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Département" name="department" as={TextField} type="input" size="small" />
                    <ErrorMessage name="department" component={MzErrorText} />
                  </Box>
                  <Box flex={0.6} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Fonction" name="function" as={TextField} type="input" size="small" />
                    <ErrorMessage name="function" component={MzErrorText} />
                  </Box>
                  <Box flex={0.6} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Email" name="email" as={TextField} type="input" size="small" />
                    <ErrorMessage name="email" component={MzErrorText} />
                  </Box>
                </Box>
                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { md: "column", xs: "column", sm: "column", lg: "row" } }}>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="expirationDateMin" as={MyDPFullDate} max={values.expirationDateMax} setFieldValue={setFieldValue} variant="outlined" label="Date d'expiration min" size="small" />
                    <ErrorMessage name="expirationDateMin" component={MzErrorText} />
                  </Box>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="expirationDateMax" as={MyDPFullDate} min={values.expirationDateMin} setFieldValue={setFieldValue} variant="outlined" label="Date d'expiration max" size="small" />
                    <ErrorMessage name="expirationDateMax" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      fullWidth
                      name="statusCard"
                      component={Autocomplete}
                      options={status}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(s) => s.name}
                      onChange={(event, value) => {
                        setFieldValue("statusCard", value ? value.id : "");
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Statut carte" />}
                      size="small"
                    />
                    <ErrorMessage name="statusCard" component={MzErrorText} />
                  </Box>

                  <Box flex={0.5} marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Chercher
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {/* End form section */}

      {/* Actions section */}
      <Box>
        <Typography noWrap mr marginTop="0.45rem" mb>
          Actions
        </Typography>
        <Box display="flex" gap="0.5rem" mb>
          <Button
            disabled={selectedIDs.length === 0 ? true : false}
            onClick={() => {
              handleOpenAddDoc();
            }}
            variant="primary"
            sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }}
          >
            Ajouter un document
          </Button>
          <Button variant="primary" startIcon={<SendIcon/>} onClick={()=> navigate("/admin/gestion-des-cartes/envoyer-mot-de-pass")}>
              Envoyer mot de passe
          </Button>
        </Box>
        <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </Box>
      {/* Modal add DOC */}
      <Modal open={openAddDoc} onClose={handleCloseAddDoc}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
            Ajouter un document
          </Typography>
          {/* form add doc */}
          <Formik initialValues={initialValuesAddDoc} validationSchema={validationSchemaAddDoc} onSubmit={addDocs}>
            {({ values, setFieldValue, errors }) => (
              <Form>
                {/* doc title */}
                <Box className="field" sx={{ marginBottom: "1rem" }}>
                  <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                  <ErrorMessage name="title" component={MzErrorText} />
                </Box>
                {loadingDoc && (
                  <Stack margin="1rem 0rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                    <Typography color={colors.main_purple}>{progress ? progress + "%" : null}</Typography>
                    <CircularProgress />
                  </Stack>
                )}
                {/* doc file */}
                <Box display="flex" flexDirection="column" sx={{ marginBottom: "1rem" }}>
                  <Button variant="upload" sx={{ height: "200px", display: "flex", flexDirection: "column" }} component="label">
                    <img src={FileIcon} width="50px" alt="" style={{ marginBottom: "0.5rem" }} />
                    <input
                      style={{ opacity: "0%", width: "100%", height: "100%", position: "absolute" }}
                      id="document"
                      name="document"
                      type="file"
                      // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(event) => {
                        const doc = event.target.files[0];
                        setFieldValue("document", event.target.files[0]);
                        setDocToAddName(doc.name);
                      }}
                    />
                    <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>Déposer votre fichier</Typography>
                  </Button>
                </Box>
                <MzErrorText>{errors.document}</MzErrorText>
                {docToAddName && (
                  <Box>
                    <Typography sx={{ margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }} fontSize="0.875rem">
                      {docToAddName}
                    </Typography>
                  </Box>
                )}

                {/* confirm add doc */}
                <Box display="flex" flexDirection="row" gap="0.5rem">
                  <Button variant="primary" type="submit">
                    Ajouter
                  </Button>
                  <Button onClick={handleCloseAddDoc} variant="third">
                    Annuler
                  </Button>
                </Box>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </Form>
            )}
          </Formik>
          {/* end form add DOC */}
        </Box>
      </Modal>


      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          // sx={{ maxWidth: "1180px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on
          onSelectionModelChange={(ids) => {
            setSelectedIDs(ids);
          }}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //allow selection
          checkboxSelection
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          pagination
          page={page}
        />
      </Box>
    </Box>
  );
}

export default CartesAbonnes;
