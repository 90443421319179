import React, { createContext, useContext, useState } from 'react';

const ProfileContext = createContext();

export const useProfile = () => {
    return useContext(ProfileContext);
};

export const ProfileProvider = ({ children }) => {
    const [selectedProfile, setSelectedProfile] = useState(null);

    return (
        <ProfileContext.Provider value={{ selectedProfile, setSelectedProfile }}>
            {children}
        </ProfileContext.Provider>
    );
};