import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, StepButton, CircularProgress, Stack } from "@mui/material";
import ConfirmationModal from "../../components/ui/ConfirmationModal";

import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import downloadIcon from "../../imgs/Telecharger.svg";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, useFormik, FormikProvider } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation } from "react-router-dom";
import FileIcon from "../../imgs/file.svg";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Close } from "@mui/icons-material";

function CreerCommande() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  //navigate to create enterprise
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.pathname?.split("/")[3];
  const idEntreprise = user?.user?.enterprise?.id;
  const [data, setData] = useState();
  //File config
  const FILE_SIZE = 5000 * 1024;

  //validation schema first form
  const validationSchemaFirstForm = Yup.object({
    number_of_cards: Yup.number().typeError("ce champs doit etre un nombre").integer("Nombre invalide").required("ce champ est obligatoire"),
  });

  //validation schema add bon de commande file
  const validationSchemaBonCommande = Yup.object({
    file: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  //bon de commande name
  const [bonDeCommande, setBonDeCommande] = useState("");

  //formik first form
  const formikFirstForm = useFormik({
    initialValues: {
      number_of_cards: "",
    },
    validationSchema: validationSchemaFirstForm,
    onSubmit: () => {
      HandleCreateOrder(formikFirstForm.values.number_of_cards);
    },
  });

  //formik second form
  const formikUploadBonDeCommande = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: validationSchemaBonCommande,
    onSubmit: () => {
      UploadBonDeCommande();
    },
  });

  //config upload fichier bon de commande
  const [progressBon, setProgressBon] = useState();
  const [loadingDocBon, setLoadingDocBon] = useState(false);
  var configsBon = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressBon(percentCompleted);
    },
  };

  //upload bon de commande
  const UploadBonDeCommande = () => {
    setLoadingDocBon(true);
    Api.post(
      Urls.UPLOAD_BON_DE_COMMANDE + data?.id + "/purchase_order_file",
      {
        file: formikUploadBonDeCommande.values.file,
      },
      configsBon
    )
      .then((res) => {
        setLoadingDocBon(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        setLoadingDocBon(false);
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const [docToAddName, setDocToAddName] = useState();
  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  //create order
  const HandleCreateOrder = (number_of_cards) => {
    Api.post(
      Urls.CREATE_ORDER + idEntreprise + "/orders",
      {
        number_of_cards: number_of_cards,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res.data.message);
        navigate("/admin/gestion-des-commandes/" + res?.data?.order?.id);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [],
    document: "",
  };

  //data grid pages configuration
  const [selectedIDs, setSelectedIDs] = useState([]);

  //onPageChange

  const [loadingDoc, setLoadingDoc] = useState(false);
  const [progress, setProgress] = useState(null);
  //add docs

  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  const addDocs = (values) => {
    setLoadingDoc(true);
    Api.post(
      Urls.ADD_USER_DOCUMENT,
      {
        title: values.title,
        user_ids: selectedIDs,
        document: values.document,
      },
      configs
    )
      .then((response) => {
        if (response.status === 201) {
          setLoadingDoc(false);
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
        }
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        setDocToAddName("");
        setLoadingDoc(false);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  const steps = [
    {
      label: "Etape 1",
      description: `  Demande de devis.`,
    },
    {
      label: "Etape 2",
      description: `Validation de devis.`,
    },
    {
      label: "Etape 3",
      description: `Commande.`,
    },
    {
      label: "Etape 4",
      description: `Création de compte collaborateur.`,
    },
    {
      label: "Etape 5",
      description: `Facturation.`,
    },
    {
      label: "Etape 6",
      description: `Paiement.`,
    },
    {
      label: "Etape 7",
      description: `Production.`,
    },
  ];
  const [activeStep, setActiveStep] = React.useState(0);

  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const stepperStyle = {
    ".MuiSvgIcon-root:not(.Mui-completed)": {
      color: colors.light,
    },
    ".MuiSvgIcon-root.Mui-active": {
      color: colors.main_purple,
      marginY: "-3px",
    },
  };

  //Get order details
  useEffect(() => {
    Api.get(Urls.GET_ORDER_DETAILS + id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setData(res?.data?.order);
        formikFirstForm.setFieldValue("number_of_cards", res?.data?.order?.number_of_cards ? res?.data?.order?.number_of_cards : "");
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, []);
  const [confirmCreerCommande, setConfirmCreerCommande] = useState(false);
  //doc name estimate
  const renderSwitch = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
            <Box>
              {/* NOMBRE DE CARTES */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmCreerCommande(true);
                }}
              >
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography mb>Nombre de cartes</Typography>
                  {data?.number_of_cards ? (
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <Typography>Nombre de carte commandées :</Typography>
                      <Typography color={colors.bd_Purple} fontWeight="bold">
                        {data?.number_of_cards}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box flexWrap="wrap" display="flex" flexDirection="row" sx={{ alignItems: "center" }} gap="0.5rem">
                        <TextField value={formikFirstForm.values.number_of_cards} name="number_of_cards" onChange={formikFirstForm.handleChange} label="Nombre de cartes" size="small"></TextField>
                        <Button disabled={!formikFirstForm.errors.number_of_cards && formikFirstForm.values.number_of_cards ? false : true} type="submit" variant="primary">
                          <Typography>Enregistrer</Typography>
                        </Button>
                        <ConfirmationModal
                          title="Confirmer la création de la commande"
                          message="Êtes-vous sûr de bien vouloir créer une commande ?"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={confirmCreerCommande}
                          onClose={() => setConfirmCreerCommande(false)}
                          acceptMethod={formikFirstForm.handleSubmit}
                          acceptMethodParams="SUSPENDED"
                        />
                      </Box>
                      <MzErrorText>{formikFirstForm.errors.number_of_cards}</MzErrorText>
                    </>
                  )}
                </Box>
              </form>
            </Box>
          </>
        );

      case 1:
        return (
          <>
            <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
          </>
        );
      case 2:
        return (
          <>
            <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
          </>
        );
      case 4:
        return (
          <>
            <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
          </>
        );
      case 5:
        return (
          <>
            <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
          </>
        );
      case 6:
        return (
          <>
            <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
          </>
        );
    }
  };

  return (
    <Box>
      {/* Header Section */}
      <Box>
        <ToastContainer style={{ top: "5rem", width: "300px" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

        <Typography sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>Détail de la commande</Typography>
        {/* FORMULAIRE  */}
      </Box>

      {/* stepper */}
      <Box sx={{ maxWidth: "800px" }}>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical" sx={stepperStyle}>
          {steps.map((step, index) => (
            <Step disabled={index === 1 || index === 2 ? true : false} key={step.label} completed={completed[index]}>
              <StepButton disableRipple color="inherit" onClick={handleStep(index)}>
                <StepLabel sx={{ padding: "0rem" }}>
                  <Box display="flex" flexDirection="column">
                    <Typography>{step.label}</Typography>
                  </Box>
                  <Typography color={colors.bd_Purple} fontSize="0.7rem" fontWeight="bold">
                    {step.description}
                  </Typography>
                </StepLabel>
              </StepButton>
              <StepContent>{renderSwitch(index)}</StepContent>
            </Step>
          ))}
        </Stepper>
        {/* <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            ".MuiSvgIcon-root:not(.Mui-completed)": {
              color: "white",
            },
            ".MuiSvgIcon-root.Mui-active": {
              color: colors.main_purple,
              marginY: "-3px",
            },
          }}>
          {steps.map((step, index) => (
            <Step active={true} key={step.label}>
              <StepLabel sx={{ padding: "0rem" }}>
                <Box display="flex" flexDirection="column">
                  <Typography>{step.label}</Typography>
                </Box>
              </StepLabel>

              <StepContent>
                <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{step.description}</Typography>
                <Box padding="1rem" borderRadius="1.5rem" bgcolor="#6E2DFF">
                  <Typography color="white">{step.description}</Typography>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper> */}
      </Box>
    </Box>
  );
}

export default CreerCommande;
