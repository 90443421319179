import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Autocomplete } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { columns } from "../../data/ticketsData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import Api from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";

import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { StripedDataGrid } from "../../components/ui/StripedGrid";

function Tickets() {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const formRef = useRef();

  //navigate to create enterprise
  const navigate = useNavigate();
  const navigateCreateTicket = () => {
    navigate("/admin/service-apres-vente/creer-ticket");
  };

  //data grid pages configuration
  // const [selectedRows, setSelectedRows] = React.useState([]);
  // const [openParams, setOpenParams] = React.useState(false);
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [initialValues, setInitialValues] = useState({
    id: "",
    motif:"",
    enterpriseCommercialName:"",
    status:""
  });

  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
    motif: Yup.string(),
    enterpriseCommercialName: Yup.string(),
    status: Yup.string(),
  });

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  //on page load
  useEffect(() => {
    setIsLoading(true);
    Api.get(Urls.GET_TICKETS_TABLE + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
      .then((response) => {
        setPage(0);
        setIsLoading(false);
        setRowsAPI(response.data.tickets);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, []);

  //onPageChange
  useEffect(() => {
    setIsLoading(true);
    Api.get(Urls.GET_TICKETS_TABLE + `?perPage=${perPage}&page=${page + 1}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: nonEmptyObject(formRef.current.values),
    })
      .then((response) => {
        setRowsAPI(response.data.tickets);
        setTotal(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [page, perPage]);

  //on search
  const onSubmitSearchTickets = (values) => {
    let realValues = nonEmptyObject(values);
    if (realValues.status === "Ouvert") {
      realValues.status = "OPEN";
    }
    if (realValues.status === "Fermé") {
      realValues.status = "CLOSED";
    }
    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_TICKETS_TABLE + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: realValues,
    })
      .then((response) => {
        setIsLoading(false);
        setRowsAPI(response.data.tickets);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response?.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  return (
    <Box>
      {/* Header Section */}

      {/* Filter  Section */}
      {/* Form Section */}

      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchTickets(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Box>
                    <Button variant="primary" sx={{ marginBottom: "1rem" }} onClick={navigateCreateTicket}>
                      Créer un ticket
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtre
                  </Typography>
                </Box>
                <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

                {/*Search fields */}

                <Box display="flex" maxWidth="900px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field name="id" type="input" as={TextField} label="Id" id="id" size="small" />
                    <ErrorMessage name="id" as={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field label="Motif" name="motif" as={TextField} type="input" size="small" />
                    <ErrorMessage name="motif" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field label="Nom commercial" name="enterpriseCommercialName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="enterpriseCommercialName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="status"
                      component={Autocomplete}
                      options={["Ouvert", "Fermé"]}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setFieldValue("status", value ? value : "");
                      }}
                      renderInput={(params) => <TextField sx={{ maxWidth: "600px" }} {...params} name="autocomplete" label="Statut" />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="status" component={MzErrorText} />
                  </Box>
                  <Box marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Chercher
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </Box>
      {/* End form section */}

      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          // sx={{ maxWidth: "1180px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on

          // onSelectionModelChange={(ids) => {
          //   const selectedIDs = new Set(ids);
          //   const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          //   setSelectedRows(selectedRows);
          // }}

          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //allow selection
          // checkboxSelection
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          //onpage change
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          pagination
          page={page}
        />
        {/* <pre>{JSON.stringify(selectedRows, null, 2)}</pre>*/}
      </Box>
    </Box>
  );
}

export default Tickets;
