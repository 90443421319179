import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import API from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
import { PieChart } from '@mui/x-charts/PieChart';
import StatsCard from '../../components/ui/StatsCard'
import { BarChart } from '@mui/x-charts/BarChart';
import { ArrowBack } from "@mui/icons-material";

const BdisplayStats = () => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const [stats,setStats] = useState([])
    const location = useLocation()
    const pageId = location.pathname.split("/")[3]
    const [serviceTypes,setServicesType] = useState([])
    const [serviceContacts,setServiceContacts] = useState([])
    const [chartData,setChartData] = useState([])
    const [xLabels,setXLabels] = useState([])


    const getServiceTypeCounts = (data) => {
        const internalCount = data.services?.filter(service => service.display_type === 'INTERNAL').length;
        const externalCount = data.services?.filter(service => service.display_type === 'EXTERNAL').length;
        return [
            { id: 0, value: internalCount || 0, label: 'Interieur', color: 'skyblue' },
            { id: 1, value: externalCount || 0, label: 'Exterieur', color: 'purple' }
        ];
    }
    
    const series = [
        { dataKey: 'nbr_url_consultations', name: 'Consultations', color: '#8884d8' },
        { dataKey: 'nbr_downloads', name: 'Downloads', color: '#82ca9d' },
    ];

    const getServiceContacts = (data) => {
        const getColor = (str) => {
            // Generate a hash code based on the service's name or ID
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
    
            // Convert the hash code to a hexadecimal color code
            let color = '#';
            for (let i = 0; i < 3; i++) {
                let value = (hash >> (i * 8)) & 0xff;
                color += ('00' + value.toString(16)).substr(-2);
            }
    
            return color;
        };
        const serviceData = data.services.map(service => ({
            id: service.id,
            value: service.nbr_contacts,
            label: `${service.name} (${service.nbr_contacts})`,
            color: getColor(service.id)
        }));
    
        return serviceData;
    }

    const getServiceDataForBarChart = (data) => {
        const serviceData = data.services?.flatMap(service => ([
            {
                name: service.name,
                type: 'Consultations',
                value: service.nbr_url_consultations,
            },
            {
                name: service.name,
                type: 'Downloads',
                value: service.nbr_downloads,
            },
        ]));

        return serviceData;
    }

    const getStats = async () =>{
        try {
            const res = await API.get('/bdisplay-reports?bdisplay_id=' + pageId,{
                headers: {
                  "Content-Type": "application/json",
                  accept: "application/json",
                  authorization: `Bearer ${access_token}`,
                },
              })
            const data = await res.data
            setChartData(getServiceDataForBarChart(data))
            setServicesType(getServiceTypeCounts(data))
            setServiceContacts(getServiceContacts(data))
            setStats(data)
            const xLabels = data?.services.map(data => data.name);
            setXLabels(xLabels);
        } 
        catch (error) {
            console.log(error)
        }

    }

    useEffect(()=>{
        getStats()
    },[])
    return ( 
        <main className="flex flex-col">
           <div className="flex items-center justify-start gap-3 mb-8">
            <NavLink
                to={"/admin/pages/"}
                className={'bg-purple-600 text-white p-1 rounded-full'}
              >
               <ArrowBack/>
              </NavLink>
           <h1 className="font-bold text-2xl">Statistics - {stats.bdisplay_name}</h1>
            </div>
           <section className="flex flex-col md:flex-row lg:flex-row items-center gap-3">
            <StatsCard total={stats.BdisplayConsultationsNbr} title="Nombre de Scans Total" bgColor=" bg-[#342457]"/>
            <StatsCard total={stats.bdisplayContactsNbr} title="Nombre de Contacts Total" bgColor=" bg-[#7b3fff]"/>
           </section>

           <h1 className="font-bold text-2xl my-8">Services</h1>
           <section className="flex flex-col md:flex-col lg:flex-row w-full pl-12 md:pl-0 lg:pl-0 items-start">

            {serviceContacts.length !== 0 ? 
            <div className="flex flex-col items-center w-[12rem] lg:w-[30rem]">
                <PieChart
                series={[
                    {
                        data: serviceContacts,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        outerRadius: 103,
                    },
                ]}
                width={950}
                height={250}
                />
            <h2 className="text-base font-bold">Contact par service</h2>
            </div>
             : 'No Data to Show'}

            
           </section>
           <h1 className="font-bold text-2xl mt-12 mb-8">Consultations et Téléchargement</h1>

           <div className="flex flex-col md:flex-col lg:flex-row w-[60rem] overflow-x-scroll pl-12 items-start">
            {chartData.length !== 0 ? <BarChart
                xAxis={[{ scaleType: 'band', data: xLabels }]}
                series={[
                    { data: chartData.filter(item => item.type === 'Consultations').map(item => item.value),label: "Consultations" },
                    { data: chartData.filter(item => item.type === 'Downloads').map(item => item.value),label: "Téléchargement" },
                ]}
                width={1000}
                height={300}
                dataKey="name"
            /> : 'No Data to show'}
            </div>
        </main>
     );
}
 
export default BdisplayStats;