import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, IconButton, Box } from "@mui/material";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import moment from "moment";
import { useRef } from "react";

const MyDPFullDate = ({ children, setFieldValue, color, min, max, value, show, ...props }) => {
  const inputRef = useRef();

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <DesktopDatePicker
          ref={inputRef}
          minDate={min}
          maxDate={max}
          label={props.label}
          size="small"
          disabled={props.disabled}
          components={{ OpenPickerIcon: CalendarMonthIcon }}
          name={props.name}
          value={value ? value : null}
          onChange={(newValue) => {
            setFieldValue(props.name, moment(newValue)?.format()?.split("T")[0]);
          }}
          InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${color}` } } }}
          renderInput={(params) => {
            return (
              <TextField
                size="small"
                variant="outlined"
                onKeyDown={onKeyDown}
                type="date"
                autoComplete="off"
                {...params}
                error={false}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "",
                }}
              />
            );
          }}
        >
          {children}
        </DesktopDatePicker>
        <IconButton
          // size="small"
          disableRipple
          sx={{ padding: "0rem 0.2rem", display: `${show === false ? "none" : "block"}` }}
          onClick={() => {
            // inputRef.current.value = "";
            setFieldValue(props.name);
            //setValue(null);
          }}
        >
          <ClearRoundedIcon />
        </IconButton>
      </Box>
    </LocalizationProvider>
  );
};

export default MyDPFullDate;
