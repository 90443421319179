
import QrCodeIcon from '@mui/icons-material/QrCode';
import contacts from "../../imgs/Contacts.svg";
import { AccountCircle, Co2Outlined, Image, PaidOutlined, SpaOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../theme/Colors';
import { People, DocumentDownload } from 'iconsax-react'
const TotalStatsCard = ({ total, title, type, bgColor, current, isAvg }) => {

  const navigate = useNavigate()
  const handleBrochureDetails = () => {
    if(type === 'brochure' && !current){
      navigate('/admin/stats/brochures')
    }
    else if(type === 'contact' && !current){
      navigate('/admin/stats/contacts')
    }
  }

  const iconStyle = (bgColor, color, borderColor, isWide) => {
    return {
      backgroundColor: bgColor,
      color: color,
      fontSize: 36,
      p: .8,
      mr: 1,
      borderRadius: '18px',
      border: '2px solid' + borderColor
    }
  }
  const getIcon = (type) =>{
    switch(type){
      case 'scan':
        return <QrCodeIcon fontSize='medium' sx={iconStyle(colors.active_chip_text, colors.textWhite, colors.active_chip_bg)}/>;
      case 'brochure':
        return <DocumentDownload size={36} style={{...iconStyle(colors.light_purple, colors.textWhite, colors.main_purple_hover_light_hover), padding: '6px'}}/>;
      case 'money':
        return <PaidOutlined fontSize='medium'/>;
      case 'co2':
        return <SpaOutlined fontSize='medium'/>;
      case 'profile':
        return <AccountCircle fontSize='medium'/>;
      default:
        return <People size={36} style={{ ...iconStyle(colors.active_cyan, colors.textWhite, colors.active_cyan_bg), padding:'6px'}} />
    }
  }
    return (
      <section
        className={`flex w-full h-32 border bg-white ${type === 'brochure' && 'lg:col-start-[span_2]' } px-6 py-6 gap-2 rounded-lg`}
        onClick={()=> handleBrochureDetails()}
        style={{cursor: (type === 'brochure' || type === 'contact') && !current ? 'pointer' : 'default'}}
      >
        <h3 className="text-gray-500 font-medium txext-md">
          {getIcon(type)}
        </h3>
        <section className='flex flex-col text-gray-500 font-medium gap-2'>
          {title}
          <h3 className="text-gray-700 font-semibold text-3xl flex gap-2 items-center self-start">
            {total}
            {type === 'co2' && <h5 className=' text-xs mt-2'>(Kg CO2eq/kg de papier)</h5>}
            {type === 'money' && <h5 className=' text-xs mt-2'>(DZD)</h5>}
            {isAvg && <h5 className=' text-xs mt-2'>scan/user</h5>}
          </h3>
        </section>
      </section>
    );
  };
  
  export default TotalStatsCard;
  