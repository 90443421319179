import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, colors } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import API from "../../api/Api_1_3";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { blueGrey } from "@mui/material/colors";
import { Delete, Edit } from "@mui/icons-material";
const BrochureTypes = () => {
    const { user } = useAuthContext();
    const access_token = user?.access_token;
    const [stats, setStats] = useState([]);
    const navigate = useNavigate();
    const [gramBrochure, setGramBrochure] = useState(80);
    const [isEdit, setIsEdit] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [format, setFormat] = useState('A4');
    const [type, setType] = useState(null)

    const initialValues = {
        visit_card_unit_price: stats?.visit_card_unit_price ?? 0,
        brochure_unit_price: stats?.brochure_unit_price ?? 0,
        nbr_scan_wanted_per_month: stats?.nbr_scan_wanted_per_month ?? 0,
        grammage: stats?.visit_card_weight ?? 200,
        brochure_weight: stats?.brochure_weight ?? 200
    };

    const formikWeightBrochure = useFormik({
      initialValues: { grammage: gramBrochure, format: format },
      validationSchema: Yup.object({
          grammage: Yup.number().nullable(),
          format: Yup.string().nullable(),
      }),
      enableReinitialize: true,
      onSubmit: (values) => {
          API.patch('/setting/brochure-types/'+ type.id, { grammage: values.grammage, format: values.format }, {
              headers: {
                  "Content-type": "application/json",
                  accept: "application/json",
                  authorization: `Bearer ${access_token}`,
              },
          })
          .then(() => {
              toast.success('Operation Success');
              getTypes()
              setTimeout(()=> setIsEdit(false),500)
          })
          .catch((error) => {
              toast.error(error.response.data.message);
              console.error(error);
          });
      },
    });

    const formikWeightBrochureAdd = useFormik({
        initialValues: { grammage: gramBrochure, format: format },
        validationSchema: Yup.object({
            grammage: Yup.number().nullable(),
            format: Yup.string().nullable(),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            API.post('/setting/brochure-types', { grammage: values.grammage, format: values.format }, {
                headers: {
                    "Content-type": "application/json",
                    accept: "application/json",
                    authorization: `Bearer ${access_token}`,
                },
            })
            .then(() => {
                toast.success('Operation Success');
                getTypes()
                setTimeout(()=> setIsAdd(false),500)
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.error(error);
            });
        },
    });

    const getTypes = () => {
        API.get('/setting/brochure-types', {
            headers: {
                "Content-type": "application/json",
                accept: "application/json",
                authorization: `Bearer ${access_token}`,
            },
        })
            .then((response) => {
                setStats(response.data.data);
            })
            .catch((error) => {
                if (error.response.status === 403) toast.error("Une erreur est survenue");
                else toast.error(error.response.data.message);
                if (error.response.status === 401) {
                    localStorage.removeItem("user");
                    navigate("/login", { replace: true });
                    navigate(0, { replace: true });
                }
            });
    };

    const BROCHURE_FORMATS = [
      { label: 'A0', size: '84.1 cm x 118.9 cm' },
      { label: 'A1', size: '59.4 cm x 84.1 cm' },
      { label: 'A2', size: '42.0 cm x 59.4 cm' },
      { label: 'A3', size: '29.7 cm x 42.0 cm' },
      { label: 'A4', size: '29.7 cm x 42.0 cm' },
      { label: 'A5', size: '14.8 cm x 21.0 cm' },
      { label: 'A6', size: '10.5 cm x 14.8 cm' },
      { label: 'A7', size: '7.4 cm x 10.5 cm' },
      { label: 'A8', size: '5.2 cm x 7.4 cm' },
      { label: 'A9', size: '3.7 cm x 5.2 cm' },
      { label: 'A10', size: '2.6 cm x 3.7 cm' },
      { label: 'DL', size: '9.9 cm x 21 cm' }
    ];

  const toggleEdit = (id) => {
   const selectedType =  stats.find(stat => stat.id === id)
   if(selectedType){
    setType(selectedType)
    setGramBrochure(selectedType.grammage)
    setFormat(selectedType.format)
    setIsEdit(true);
   }
  }
  const deleteType = (id) => {
    API.delete('/setting/brochure-types/' + id, {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
            },
                })
                .then((response) => {
                    toast.success("Type de brochure supprimé avec succès");
                    getTypes()
                    })
                    .catch((error) => {
                        if (error.response.status === 403) toast.error("Une erreur est survenue");
                        else toast.error(error.response.data.message);
                        if (error.response.status === 401) {
                            localStorage.removeItem("user");
                            navigate("/login", { replace: true });
                            navigate(0, { replace: true });
                            }
                            });

  }

    useEffect(() => {
        getTypes();
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'} 
            width={'100%'} gap={{
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
            }} 
            justifyContent={'start'} py={1}>
            <ToastContainer
                style={{ top: "5rem", width: "300px" }}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            {(!isEdit && !isAdd) && 
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h6">List Des Types</Typography>
                    <Button variant="primary" width={'auto'} onClick={()=> setIsAdd(true)} alignSelf={'end'}>Nouveau Type</Button>
                </Box>
            }
            
            {/* Types List */}
            {(!isEdit && !isAdd) && stats.map((stat,index) =>(
                <span className="border w-full gap-3 " key={index} style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:' 12px 24px',borderRadius:'8px',boxShadow: '4px 17px 21px -4px rgba(0,0,0,0.15)','backgroundColor':blueGrey}}>
                    <h4 className='font-medium flex gap-2 items-center'>
                        {stat.format} - ({stat.grammage}g/m²)
                    </h4>

                    <div className='flex items-center gap-2'>
                    <button onClick={()=> toggleEdit(stat.id)} style={{backgroundColor: colors.purple[800],padding:'8px',color:'white',borderRadius:'8px'}}>
                          <Edit/>
                    </button>
                    <button onClick={()=>deleteType(stat.id)} style={{backgroundColor: colors.purple[600],padding:'8px',color:'white',borderRadius:'8px'}}>
                          <Delete/>
                    </button>                
                    </div>
                </span>
            ))}

            {/* edit brochure type */}
            { isEdit
                && 
                <Box display={'flex'} gap={3} pl={4} >
                    <span className="border-2" style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:' 12px 24px',borderRadius:'8px',boxShadow: '4px 17px 21px -4px rgba(0,0,0,0.15)','backgroundColor':blueGrey}}>
                    <h4 className='font-medium flex gap-2 items-center'>
                    {formikWeightBrochure.values.format} - ({formikWeightBrochure.values.grammage}g/m²)
                    </h4>
                    </span>
                    <Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h6" fontSize={15} fontWeight={600} my={4}>
                    Modifier votre Détails: 
                  </Typography>
                </Box>
                <form style={{display:'flex', flexDirection:'column', alignItems:'start', gap:9}} onSubmit={formikWeightBrochure.handleSubmit}>
                    <TextField
                        id="gramBrochure"
                        fullWidth
                        name="brochure_weight"
                        label="Grammage du brochure(g/m²)"
                        type="number"
                        value={formikWeightBrochure.values.grammage}
                        onChange={(event) => {
                            setGramBrochure(event.target.value);
                            formikWeightBrochure.setFieldValue('grammage', event.target.value);
                        }}
                    />
                    <Typography variant="subtitle2" fontSize={12} mb={2}> poid actuel: {initialValues.brochure_weight} g</Typography>

                   
                    <FormControl sx={{width:'100%'}}>
                          <InputLabel id="format-select-label">Format</InputLabel>
                          <Select
                              labelId="format-select-label"
                              id="format-select"
                              name="format"
                              value={formikWeightBrochure.values.format}
                              onChange={(event) => {
                                  setFormat(event.target.value);
                                  formikWeightBrochure.setFieldValue('format', event.target.value);
                              }}
                              label="Format"
                          >
                            {BROCHURE_FORMATS.map((format) => (
                                <MenuItem defaultChecked={format.label === 'A4'} key={format.label} value={format.label}>
                                    {format.label} ({format.size})
                                </MenuItem>
                            ))}
                          </Select>
                    </FormControl>

                    <Button variant="primary" type="submit">
                        Soumettre
                    </Button>
                </form>
                    </Box>
                </Box>
            }

            {/* Add new type */}
            { isAdd
                && 
                <Box display={'flex'} gap={3} pl={4} >
                    <span className="border-2" style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:' 12px 24px',borderRadius:'8px',boxShadow: '4px 17px 21px -4px rgba(0,0,0,0.15)','backgroundColor':blueGrey}}>
                    <h4 className='font-medium flex gap-2 items-center'>
                    {formikWeightBrochureAdd.values.format} - ({formikWeightBrochureAdd.values.grammage}g/m²)
                    </h4>
                    </span>
                    <Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h6" fontSize={15} fontWeight={600} my={4}>
                    Ajouter votre Détails: 
                  </Typography>
                </Box>
                <form className="flex flex-col items-start gap-6 " onSubmit={formikWeightBrochureAdd.handleSubmit}>
                    <TextField
                        id="gramBrochure"
                        fullWidth
                        name="brochure_weight"
                        label="Grammage du brochure(g/m²)"
                        type="number"
                        value={formikWeightBrochureAdd.values.grammage}
                        onChange={(event) => {
                            setGramBrochure(event.target.value);
                            formikWeightBrochureAdd.setFieldValue('grammage', event.target.value);
                        }}
                    />
                   
                    <FormControl sx={{width:'100%'}}>
                          <InputLabel id="format-select-label">Format</InputLabel>
                          <Select
                              labelId="format-select-label"
                              id="format-select"
                              name="format"
                              value={formikWeightBrochureAdd.values.format}
                              onChange={(event) => {
                                  setFormat(event.target.value);
                                  formikWeightBrochureAdd.setFieldValue('format', event.target.value);
                              }}
                              label="Format"
                          >
                            {BROCHURE_FORMATS.map((format) => (
                                <MenuItem defaultChecked={format.label === 'A4'} key={format.label} value={format.label}>
                                    {format.label} ({format.size})
                                </MenuItem>
                            ))}
                          </Select>
                    </FormControl>

                    <Button variant="primary" type="submit">
                        Soumettre
                    </Button>
                </form>
                    </Box>
                </Box>
                
            }

            
        </Box>
    );
};

export default BrochureTypes;
