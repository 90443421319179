import React from "react";
import { Box, Typography } from "@mui/material";

function IconLink({ icon, title, value, slug }) {

  // Determine which icon to display
  const displayIcon =  typeof icon === 'string' ? <img width="20px" height="20px" alt={`${title} icon`} src={icon} /> : icon;

  return (
    <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
      <Box className="well" display="flex" sx={{ alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: "18px", margin: "0.5rem 1rem", width:'40px', height:'40px' }}>
        {displayIcon}
      </Box>
      <Box>
        <Typography>
          <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} href={value}>
            {title}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default IconLink;
