export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  width: {
    xs: '75%',
    sm: '75%', 
    md:'auto',
    lg:'auto',
    xl:'auto'
  },
};
