import React from 'react';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../theme/Colors';

const CustomStepIcon = styled(({ active, completed, ...props }) => (
    <div {...props} />
))(({ theme, active, completed }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `${active ? '7px' : completed ? '0px' : '7px'} solid ${active ? colors.main_purple : completed ? '#D1C4E9' : '#E0E0E0'}`, // Light purple for active and completed
    backgroundColor: active ? 'white' : completed ? colors.main_purple : colors.gray, // Light purple background for active
    color: active || completed ? '#673AB7' : '#BDBDBD', // Color for active and completed
}));

const StepperComponent = ({ steps, activeStep }) => {
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel StepIconComponent={CustomStepIcon}>
                        <Typography variant="body2" width='100%' fontSize={{xs: 11, sm: 11, md: 14, lg:14}}>{label}</Typography> 
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default StepperComponent;