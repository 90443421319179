import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Tab, Tabs, Button, Typography, useMediaQuery, Autocomplete, IconButton, Avatar, Modal, CircularProgress, Chip, Grid } from "@mui/material";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Document } from 'iconsax-react';
import moment from "moment";
import userIcon from "../../imgs/user.png";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import IconLink from "../../components/ui/IconLink";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import MyIconButton from "../../components/ui/MyIconButton";
import { ModalStyle } from "../../theme/ModalStyles";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import Contacts from '../admin/CollaboratorContacts'
import { useTheme } from "@mui/material/styles";
import API from "../../api/Api_1_3";
import { Close, CloudUploadOutlined } from "@mui/icons-material";
import { StatusChip } from "../../components/ui/StatusChip";
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import LinkIcon from '@mui/icons-material/Link';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import WebIcon from '@mui/icons-material/Web';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom'
import { ContactsCard } from "../admin/ContactsCards";
import { useAuthContext } from "../../hooks/useAuthContext";
import EditIcon from '@mui/icons-material/Edit';
import { useProfile } from "../../context/ProfileContext";
import TabsList from "../../components/ui/TabsList";
import Dashboard from '../admin/Dashboard'
import axios from 'axios'
import ProfileCard from "../admin/ProfileCard";

function AbonneDetails() {
  const [change, setChange] = useState(false);
  const { setSelectedProfile } = useProfile()
  const { user } = useAuthContext();

  const access_token = user?.access_token;
  const id = user?.user.id;

  //is empty object
  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  class Doc{
    constructor(id, title, url_file){
        this.id = id;
        this.title = title;
        this.url_file = url_file
    }
  }

  //allow "other" field add
  const [allowOtherField, setAllowOtherField] = useState();

  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
  //validation schema profile picture
  const validationSchemaProfilePicture = Yup.object({
    profile_picture: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
    ImageWidth: Yup.number().min(191, "Largeur minimale de 192 pixels"),
    ImageHeight: Yup.number().min(191, "Hauteur minimale de 192 pixels"),
  });

  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [selectedDocToDelete, setSelectedDocToDelete] = useState();

  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [data, setData] = useState(null);
  const [profiles, setProfiles] = useState(null)
  const [links, setLinks] = useState();
  const [possibleLinksList, setPossibleLinksList] = useState();
  const [docs, setDocs] = useState([]);
  const [docToAddName, setDocToAddName] = useState();

  //trigger reload infos
  const [didUpdate, setDidUpdate] = useState(false);
  const [didUpdateLink, setDidUpdateLink] = useState(false);
  const [didDeleteLink, setDidDeleteLink] = useState(false);
  const [didAddLink, setDidAddLink] = useState(false);
  const [didDeleteDoc, setDidDeleteDoc] = useState(false);
  const [didAddDoc, setDidAddDoc] = useState(false);

  //modal config
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);

  //add link modal
  const handleOpenAddLink = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenAddLink(true);
  };
  const handleCloseAddLink = () => setOpenAddLink(false);

  //delete link modal
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //delete document modal
  const handleOpenDeleteDoc = () => {
    setOpenDeleteDoc(true);
  };
  const handleCloseDeleteDoc = () => setOpenDeleteDoc(false);

  //update link modal
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  //add doc modal
  const handleOpenAddDoc = () => {
    setOpenAddDoc(true);
  };

  const handleCloseAddDoc = () => {
    setOpenAddDoc(false)
    setDocument(null)
  };

  //tab config
  const [inLinks, setInLinks] = useState(true);


  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
    icon_tag: ""
  };

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [id] ?? "",
    document: "",
  };

  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
    icon_tag: Yup.string()
  });

  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }
  const imageProfile = useRef();
  const coverImageProfile = useRef();

  const getDocs = () => {
    Api.get(Urls.GET_USER_DOCUMENTS + `/${id}/documents`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setDocs(new Doc(response.data.documents[0].id, response.data.documents[0].title, response.data.documents[0].url_file));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error.response?.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response?.data.message);
      });
  }

  const getUser = () => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}/account-details`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        const data = response?.data?.user
        setData(data);
        setSelectedProfile(data)
        if(data.hasOwnProperty('profiles') && data?.profiles.length > 0 ) {   
          setProfiles(data?.profiles)
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error.response?.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response?.data?.message);
      });
  }

  //on page load (load user details)
  useEffect(() => {
    getUser()
  }, [didUpdate, change, access_token, id, navigate]);

  //on page load (get DOCS)
  useEffect(() => {
    getDocs()
  }, [didDeleteDoc, didAddDoc, change, access_token, id, navigate]);

  //on page load (get LINKS)
  useEffect(() => {
    Api.get(Urls.GET_USER_LINKS + `/${id}/userLinks`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setLinks(response.data.user_links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
      });
  }, [didDeleteLink, didAddLink, didUpdateLink, access_token, id, navigate]);

  function updatePhoneNumber(phoneNumber) {
    const payload = {
        user_id: id, // required
        phone_number: phoneNumber, // required
    };

    if(data?.other_phone_numbers.length === 0){
      API.post('/phone-numbers', payload, {
        headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        if (response.status === 200) {
            setDidUpdate(!didUpdate); // Trigger a refresh if needed
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
        } else {
            toast.error(error?.response?.data?.message);
        }
    });
    }
    else {
      API.patch('/phone-numbers/' + data?.other_phone_numbers[0]?.id,
        {
          phone_number: phoneNumber
      }, {
        headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        if (response.status === 200) {
            setDidUpdate(!didUpdate); // Trigger a refresh if needed
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
        } else {
            toast.error(error?.response?.data?.message);
        }
    });
    }

    
  }

  function deletePhoneNumber(){
    API.delete('/phone-numbers/' + data?.other_phone_numbers[0]?.id,{
      headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
      },
  })
  .then((response) => {
      if (response.status === 200) {
          toast.success("Numéro de téléphone supprimée avec succès");
          setDidUpdate(!didUpdate); // Trigger a refresh if needed
      }
  })
  .catch((error) => {
      if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
      }
      if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
      } else {
          toast.error(error?.response?.data?.message);
      }
  });
  }

  //submit update user
  const onSubmitUpdateUser = (values) => {
    if (values.hasOwnProperty('additional_phone_number') && values.additional_phone_number) { 
      updatePhoneNumber(values.additional_phone_number);
    }

    let realValues = nonEmptyObject(values);
    Api.patch(
      Urls.UPDATE_USER_INFOS_ENTREPRISE + `${data?.id}`,
      {
        first_name: realValues.first_name,
        last_name: realValues.last_name,
        department: realValues.department,
        function: realValues.function,
        phone_number: realValues.phone_number,
        country_name: realValues.country_name,
        city_name: realValues.city_name,
        postal_code: realValues.postal_code,
        address: realValues.address,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setDidUpdate(!didUpdate);
          toast.success("Informations du collaborateur changé avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
      });
  };

  //update profile picture
  const [progress, setProgress] = useState(0);
  const [onUploadImage, setOnUploadImage] = useState(false);

  const [coverImage, setCoverImage] = useState();
  const [onCoverImage, setOnCoverImage] = useState(false);
  const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
  const [coverZoom, setCoverZoom] = useState(1);
  const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
  const [coverFormat, setCoverFormat] = useState("");
  const [coverProgress, setCoverProgress] = useState(0);
  const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
  const [openDeleteCoverPic, setOpenDeleteCoverPic] = useState(false);

  const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
    setCoverCroppedAreaPixels(croppedAreaPixels);
  };

  const onCoverCrop = async () => {
    const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
    setCoverImage(URL.createObjectURL(croppedImage));
    var file = new File([croppedImage], `${Math.floor(Math.random() * 1000000000)}.${coverFormat.split("/")[1]}`, { lastModified: 1534584790000, type: [`${coverFormat}`] });
    if (file && croppedImage) {
      setOnCoverImage(false);
      formikRef.current.values.cover_picture = file;
      file = null;
    }
  };

  const deleteCoverPic = () => {
    Api.delete('/users/' + id + "/CoverPicture", {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setCoverImage(null);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
      });
  };

  const onSubmitUpdateCoverPicture = (values) => {
    setOnUploadCoverImage(true);
    Api.post(`/users/${data?.id}/CoverPicture`,
      {
        cover_picture: values.cover_picture,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCoverProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setOnUploadCoverImage(false);
          setCoverProgress(0);
        }
        setChange(!change);
      })
      .catch((error) => {
        setOnUploadCoverImage(false);
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  var configsImage = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };

  const onSubmitUpdateProfilePicture = (values) => {
    setOnUploadImage(true);
    Api.post(
      Urls.UPDATE_PROFILE_PICTURE + `/${data?.id}/profilePicture`,
      {
        profile_picture: values.profile_picture,
      },
      configsImage
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setOnUploadImage(false);
          setProgress(0);
        }
        setChange(!change);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          setOnUploadImage(false);

          navigate(0, { replace: true });
        }
        setOnUploadImage(true);
      });
      setImage(null)
  };

  //get list possible links
  useEffect(() => {
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        let array = [];
        setPossibleLinksList(response.data.links);
        for (let index = 0; index < response.data.links.length; index++) {
          if (response.data.links[index].link.slug === "other") {
            response.data.links[index].link.slug = "Autre";
          }
          array.push(response.data.links[index]);
          setPossibleLinksList(array);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response?.data.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [change, access_token, id, navigate]);

  //add link
  const addLink = (values) => {
    Api.post(
      Urls.CREATE_USER_LINK + `/${id}/userLinks`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
        icon_tag: values.icon_tag ?? 'Site web'
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          handleCloseAddLink();
          setDidAddLink(!didAddLink);
          toast.success("Lien ajouté avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //add doc
  const addDoc = () => {
    Api.post(
      Urls.ADD_USER_DOCUMENT,
      {
        title: document.name,
        user_ids: [id],
        document: document,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          handleCloseAddDoc();
          getDocs()
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
          setDidAddDoc(!didAddDoc);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  
  //update link
  const updateLink = (values) => {
    Api.patch(
      Urls.DELETE_USER_LINK + `/${values.link_id}`,
      {
        value: values.value,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          handleCloseUpdate();
          setDidUpdateLink(!didUpdateLink);
          toast.success("Lien modifié avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //delete link
  const deleteLink = (id_link) => {
    Api.delete(
      Urls.DELETE_USER_LINK + `/${id_link}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setDidDeleteLink(!didDeleteLink);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //delete link
  const deleteDoc = (id_doc) => {
    Api.delete(
      Urls.DELETE_USER_DOCUMENT + `/${id_doc}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setDidDeleteDoc(!didDeleteDoc);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  //crop image config
  const formikRef = useRef();
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File([croppedImage], `${Math.floor(Math.random() * 100000000)}.${format.split("/")[1]}`, { lastModified: 1534584790000, type: [`${format}`] });
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.profile_picture = file;
      file = null;
    }
  };

  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "20px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };

  const deleteProfilePic = () => {
    Api.delete(Urls.DELETE_PROFILE_PIC + id + "/profilePicture", {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setImage(null);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
      });
  };

  const [openDeletePic, setOpenDeletePic] = useState(false);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const getAvatarStyle = () => {
    let width;

    if (isXsScreen || isSmScreen) {
      width = '100%';
    } else if (isMdScreen) {
      width = '45.3125rem'; // 725px
    } else if (isLgScreen) {
      width = '45.3125rem'; // 725px
    }

    return {
      width
    };
  };

  const validateImageSize = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 725 && img.height >= 360) {
          formikRef.current.setFieldValue("cover_picture", file);
          resolve(true);
        } else {
          reject(new Error("L'image doit avoir une taille minimale de 725 x 360 pixels."));
        }
      };
      img.onerror = () => reject(new Error("Erreur lors du chargement de l'image."));
    });
  };

  const [activeTab, setActiveTab] = useState(0);
  const [selectedSection, setSelectedSection] = useState('stats')

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSectionChange = (event, newValue) => {
    setSelectedSection(newValue ?? event)
  }

  const FieldStyle = ()=> {
    return {
      p:3,
      backgroundColor: colors.paperLightGray,
      borderRadius:'8px'
    }
  }

  const iconOptions = [
    { value: 'DatasetLinked', label: 'Dataset Linked', icon: <DatasetLinkedIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Link', label: 'Link', icon: <LinkIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'LeakAdd', label: 'Leak Add', icon: <LeakAddIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'OpenInNew', label: 'Open In New', icon: <OpenInNewIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Launch', label: 'Launch', icon: <LaunchIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'OfflineShare', label: 'Offline Share', icon: <OfflineShareIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLinkRing', label: 'Phone Link Ring', icon: <PhonelinkRingIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLink', label: 'Phone Link', icon: <PhonelinkIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Web', label: 'Web', icon: <WebIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Pen', label: 'Pen', icon: <EditIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Card', label: 'Card', icon: <CreditCardIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Profile', label: 'Profile', icon: <AccountCircleIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Page', label: 'Page', icon: <DescriptionIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
  ];

  const getLogoUrl = (url) => {
    const link = possibleLinksList?.find((link) => link.title === url.title);
    return link ? link.url_logo : getIcon(url) ;
  };

  const getIcon = (url) => {
    return iconOptions.find(option => option.value === url.icon_tag)?.icon || <LinkIcon sx={{color: colors.bd_Purple, fontSize:24}}/>; 
  }

  const [document, setDocument] = useState(null);


  const onDrop = (acceptedFiles) => {
    setDocument(acceptedFiles[0]);
    handleOpenAddDoc()
}

const renderComponent = (small, large) => {
  return (isXsScreen || isSmScreen) ? small : large;
}

const expDate = 
              <Typography variant="body2">
                {data?.expiration_date === null ? null : (
                  <Box sx={{
                    display: 'inline-block',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    backgroundColor: data?.status_card === 'ACTIVE'
                      ? colors.active_chip_bg 
                      : data?.status_card === 'COMMANDED'
                      ? colors.commanded_chip_bg
                      : colors.inactive_chip_bg,
                    color: data?.status_card === 'ACTIVE'
                      ? colors.active_chip_text
                      : data?.status_card === 'COMMANDED' 
                      ? colors.commanded_chip_bg
                      : colors.inactive_chip_text
                    }}
                  >
                    {data?.status_card === 'ACTIVE' && 'Carte active jusqu\'au '}
                    {data?.status_card === 'COMMANDED' && 'Carte commandée '}
                    {data?.status_card === 'SUSPENDED' && 'Carte suspendue le '}
                    <span className="font-medium underline underline-offset-1">
                      {data?.expiration_date?.split("-")[2] + "/" + data?.expiration_date?.split("-")[1] + "/" + data?.expiration_date?.split("-")[0]}
                    </span>
                  </Box>
                )}
              </Typography>


const handleExpiryDate = () => { 
  return (
    expDate
  )
}

const list = [
  { id: 0, symbol: 'info', name: 'Mes Informations' },
  { id: 1, symbol: 'assets', name: 'Liens et Documents' },
  { id: 2, symbol: 'contacts', name: 'Contacts' },
  { id: 3, symbol: 'stats', name: 'Statistiques' }
  
];

const initialValues = {
  email: data?.email ?? "",
  first_name: data?.first_name ?? "",
  last_name: data?.last_name ?? "",
  department: data?.department ?? "",
  function: data?.function ?? "",
  phone_number: data?.phone_number ?? "",
  additional_phone_number: data?.other_phone_numbers[0]?.phone_number ?? "",
  country_name: data?.country_name ?? "",
  city_name: data?.city_name ?? "",
  postal_code: data?.postal_code ?? "",
  address: data?.address ?? "",
  status_card: data?.status_card ?? "",
}

const [countries, setCountries] = useState([]);

const fetchCountries = async () => {
  const response = await axios.get('https://restcountries.com/v3.1/all');
  return response.data.map(country => ({
    code: country.cca2,
    name: country.name.common,
    geonameId: country.geonameId || null,
  }));
};

const handleRoles = () => {
  switch(data?.role){
    case 'COLLABORATOR':
      return true;
    case 'CLIENT_ADMIN':
      return true;
    default:
      return false
  }
}

useEffect(() => {
  const getCountries = async () => {
    const countries = await fetchCountries();
    setCountries(countries);
  };

  getCountries();
}, []);

  return (
    <Box sx={{ display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "flex-start", height:'100%' }} maxWidth="xl">
      <Box width="100%">

        {/* Header Section */}
        <Box sx={{ marginBottom: "2rem" }}>

          {/* fixed header */}

          <Box
            display="flex"
            sx={{
              flexDirection: { md: "row", xs: "column" },
              gap: "2rem",
              p:2,
              borderRadius:{
                xs:'8px',
                sm:'8px'
              },
              width:'auto',
              position: {
                xs: (window.scrollY !== 0 ? 'sticky' : 'static'),
                sm: (window.scrollY !== 0 ? 'sticky' : 'static'),
                md: 'static',
                lg: 'static',
                xl: 'static'
              },
              top: 0,
              zIndex:200,
              backgroundColor: {
                xs: 'white',
                sm: 'white', 
                md: 'transparent',
                lg: 'transparent',
                xl: 'transparent'
              },
              // boxShadow: {
              //   xs: (window.scrollY > 0 ? '0 2px 4px rgba(0,0,0,0.1)' : 'none'),
              //   sm: (window.scrollY > 0 ? '0 2px 4px rgba(0,0,0,0.1)' : 'none'),
              //   md: 'none',
              //   lg: 'none',
              //   xl: 'none'
              // }
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{width:'100%'}}>
              {/* formik 2 logo */}
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  profile_picture: data?.url_profile_picture ?? "",
                  ImageHeight: 192,
                  ImageWidth: 192,
                }}
                validationSchema={validationSchemaProfilePicture}
                onSubmit={(values) => {
                  onSubmitUpdateProfilePicture(values, access_token);
                }}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <Box display="flex" sx={{ alignItems: "center", flexDirection: "column" }} gap="0.5rem">                    

                    {/* Profile data */}
                    {data === null
                    ? 
                      <CircularProgress/> 
                    :
                      <Box display="flex" justifyContent="center" alignItems='center' width="320px">

                      {/* basic data */}
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems={{
                        xs:'center',
                        sm:'center',
                        md:'center',
                        lg:'center',
                        xl:'center'
                      }} 
                      gap="0.5rem"
                      >
                        {/* Profile Picture Section */}
                          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                              <Box sx={{position:'relative'}}>
                                <Avatar
                                  sx={{
                                    position: "relative",
                                    borderRadius: "20px",
                                    width: "7.5rem",
                                    height: "7.5rem",
                                    border: "1px solid #C9C5D2",
                                    marginBottom: "1rem",
                                    borderRadius: '50%'
                                  }}
                                  variant="rounded"
                                >
                                {image ? (
                                  <img alt="profile-pic" ref={imageProfile} src={image} style={{ width: "7.5rem", height: "7.5rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                                ) : data?.url_profile_picture ? (
                                  <img alt="profile-pic" ref={imageProfile} src={data?.url_profile_picture} style={{ width: "7.5rem", height: "7.5rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                                ) : (
                                  <img alt="profile-pic" src={userIcon} style={{ width: "7.5rem", height: "7.5rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                                )}
                                </Avatar>
                                <IconButton
                                    sx={{
                                      zIndex: "33",
                                      position: "absolute",
                                      right: "10%",
                                      top: "65%",
                                      bgcolor: `${colors.main_purple}`,
                                      "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                                    }}
                                    component="label"
                                  >
                                    <input
                                      hidden
                                      id="profile_picture"
                                      name="profile_picture"
                                      type="file"
                                      onChange={(event) => {
                                        setFieldValue("profile_picture", event.target.files[0] ? event.target.files[0] : null);
                                        if (event.target.files[0]) {
                                          setOnImage(true);
                                          setImage(URL.createObjectURL(event.target.files[0]));
                                          setFormat(event.target.files[0].type);
                                        }
                                      }}
                                    />
                                      {data?.url_profile_picture ? <EditIcon htmlColor="white"/> : <AddRoundedIcon htmlColor="white" />}
                                </IconButton>
                              </Box>
                                          
                              <Modal
                                            sx={{ backgroundColor: "#3f3f3f" }}
                                            open={onImage}
                                            onClose={() => {
                                              setImage(null);
                                              formikRef.current.values.profile_picture = "";
                                              setOnImage(false);
                                            }}
                                          >
                                            <Box height="100%" width="100%" margin="0 auto">
                                              <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <Box height="100%" width="100%" display="flex" flexDirection="column">
                                                  <Cropper style={styleCropper} image={image} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                                                </Box>
                                              </Box>
                                              <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                <MzErrorText>{errors.profile_picture ? errors.profile_picture : null}</MzErrorText>
                                                <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                                  <Button disabled={errors?.profile_picture ? true : false} onClick={onCrop} variant="primary">
                                                    Confirmer
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                      setImage(null);
                                                      formikRef.current.values.profile_picture = "";
                                                      setOnImage(false);
                                                    }}
                                                  >
                                                    Annuler
                                                  </Button>
                                                </Box>
                                                <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                                  <Typography color="white">Niveau de zoom : </Typography>
                                                  <input type="range" min={1} max={3} step={0.1} value={zoom} onInput={(e) => setZoom(e.target.value)} />
                                                </Box>
                                              </Box>
                                            </Box>
                              </Modal>
                                          
                              <Box>
                                {errors.profile_picture ? <MzErrorText>{errors.profile_picture}</MzErrorText> : null}
                                {values.ImageHeight < 191 ? <MzErrorText>Hauteur minimale de 192 pixels</MzErrorText> : null}
                                {values.ImageWidth < 191 ? <MzErrorText>Largeur minimale de 192 pixels</MzErrorText> : null}
                              </Box>
                  
                              <Box display="flex" flexDirection="column" alignItems="center" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                                            <Box display="flex" gap="0.5rem" alignItems="center">
                                              {image && 
                                                <Button variant="primary" onClick={()=> onSubmitUpdateProfilePicture(values, access_token)}>
                                                  Enregistrer
                                                </Button>
                                              }
                                              {data?.url_profile_picture && (
                                                <IconButton
                                                  onClick={() => setOpenDeletePic(true)}
                                                  sx={{
                                                    zIndex: "33",
                                                    bgcolor: `${colors.bd_suspended}`,
                                                    "&:hover": { bgcolor: `${colors.bd_suspended_hover}` },
                                                  }}
                                                  component="label"
                                                >
                                                  <DeleteRoundedIcon htmlColor="white" />
                                                </IconButton>
                                              )}
                                            </Box>
                                            <Box>
                                              {onUploadImage && (
                                                <Box display="flex" alignItems="center" gap="1rem">
                                                  <CircularProgress size={30} />
                                                  <Typography width="20px" color={colors.main_purple}>
                                                    {progress ? progress + "%" : null}
                                                  </Typography>
                                                </Box>
                                              )}
                                            </Box>
                              </Box>
                          </Box>

                        <Typography variant="h6">{data?.first_name + ' ' + data?.last_name}</Typography>
                        <Typography variant="secondary" fontSize={14}>{data?.function}</Typography>

                        <Typography variant="secondary" fontSize={14}>
                            {handleExpiryDate()}
                        </Typography>
                      </Box>

                      </Box>
                    }
                        
                  </Box>
                </Form>
              )}
              </Formik>
            </Box>

          </Box>
        </Box>

        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: { lg: "row", xs: "column" },
          }}
        >
          {/* Mes informations Section*/}

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmitUpdateUser(values, access_token);
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <Form style={{width:'100%'}}>
                <Box sx={{backgroundColor: 'white', px:3, py:1.5, borderRadius:'18px'}}>
                  {/* Tabulation */}
                  <Box width={{
                      xs:"340px",
                      sm:"340px",
                      md:"100%",
                      lg:"100%",
                      xl:"100%"
                    }} 
                    overflowX={{ xs: 'overflow', sm: 'overflow' }}
                    mb={3}
                  >
              {(isLgScreen || isMdScreen) &&
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ minWidth: '300px' }}>
                <Tabs value={selectedSection} onChange={handleSectionChange} variant="scrollable" scrollButtons="auto">
                  <Tab label="Statistiques" value={'stats'} wrapped/>
                  <Tab label="Mes Informations" value={'info'} wrapped/>
                  <Tab label="Liens & documents" value={'assets'} wrapped/>
                  <Tab label="Contacts" value={'contacts'} wrapped/>
                  {/* <Tab label="Activités" value={'activities'} wrapped/> */}
                </Tabs>
              </Box>}

              {(isXsScreen || isSmScreen) && 
                  <TabsList tabs={list} active={selectedSection} onToggle={handleSectionChange} />
              }
                    </Box>
                  <Box mb={3}>
                    <Typography variant="h6" mb>{selectedSection == 'info' && 'Informations sur le compte'}</Typography>
                  </Box>
                {/* Account Details */}
                { selectedSection === 'info' &&
                  <>
                  <Grid container spacing={4} marginBottom="1.3rem">

                    <Grid item xs={12} md={6}>
                      <Field  
                        name="last_name" 
                        as={TextField} 
                        variant="outlined" 
                        label="Nom" 
                        size="medium"
                        InputProps={{ style: FieldStyle() }}
                        fullWidth
                      />
                      <ErrorMessage name="last_name" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field  
                        name="first_name" 
                        as={TextField} 
                        variant="outlined" 
                        label="Prénom" 
                        size="medium"
                        InputProps={{ style: FieldStyle() }}
                        fullWidth
                      />
                      <ErrorMessage name="first_name" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field 
                        disabled 
                        name="email" 
                        as={TextField} 
                        variant="outlined" 
                        label="Email*" 
                        size="medium"
                        InputProps={{ style: FieldStyle() }}
                        fullWidth
                      />
                      <ErrorMessage name="email" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field 
                        name="department" 
                        as={TextField} 
                        variant="outlined" 
                        label="Département" 
                        size="medium" 
                        InputProps={{ style: FieldStyle() }}
                        fullWidth 
                      />
                      <ErrorMessage name="department" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field 
                        name="phone_number" 
                        as={TextField} 
                        variant="outlined" 
                        label="Téléphone professionnel" 
                        size="medium" 
                        InputProps={{ style: FieldStyle() }}
                        fullWidth
                      />
                      <ErrorMessage name="phone_number" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field 
                        name="function" 
                        as={TextField} 
                        variant="outlined" 
                        label="Fonction" 
                        size="medium" 
                        InputProps={{ style: FieldStyle() }}
                        fullWidth 
                      />
                      <ErrorMessage name="function" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <Field 
                          name="additional_phone_number" 
                          as={TextField} 
                          variant="outlined" 
                          label="Téléphone supplémentaire" 
                          size="medium" 
                          InputProps={{ style: FieldStyle() }}
                          fullWidth 
                        />
                        {data?.other_phone_numbers.length > 0 && <Close sx={{color:'red', cursor:'pointer'}} onClick={()=> deletePhoneNumber()}/>}
                      </Box>
                      <ErrorMessage name="additional_phone_number" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field 
                        name="address" 
                        as={TextField} 
                        variant="outlined" 
                        label="Adresse" 
                        size="medium" 
                        InputProps={{ style: FieldStyle() }}
                        fullWidth
                      />
                      <ErrorMessage name="address" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field 
                        name="postal_code" 
                        as={TextField} 
                        variant="outlined" 
                        label="Code postal" 
                        size="medium" 
                        InputProps={{ style: FieldStyle() }}
                        fullWidth
                      />
                      <ErrorMessage name="postal_code" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        options={countries}
                        getOptionLabel={(option) => option.name || ''}
                        value={countries.find(c => c.name === initialValues.country_name) || null}
                        onChange={(event, newValue) => {
                          setFieldValue('country_name', newValue ? newValue.name : '');
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Pays*"
                            variant="outlined"
                            size="medium"
                            InputProps={{
                              sx: { backgroundColor: colors.paperLightGray, borderRadius:'8px',  p: '2px !important' },
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="country_name" component={MzErrorText} />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Field 
                        name="city_name" 
                        as={TextField} 
                        variant="outlined" 
                        label="Ville" 
                        size="medium" 
                        InputProps={{ style: FieldStyle() }}
                        fullWidth 
                      />
                      <ErrorMessage name="city_name" component={MzErrorText} />
                    </Grid>

                  </Grid>

                    <Box display="flex" gap="1rem" alignItems="center" justifyContent="flex-end">
                    {Object.keys(values).some(key => values[key] !== initialValues[key]) && (
                      <>
                      <Button variant="form" type="submit" sx={{px:3}}>
                        Enregistrer
                      </Button>
                      <Button variant="secondary" onClick={() => {
                        Object.keys(initialValues).forEach(key => {
                          setFieldValue(key, initialValues[key]);
                        });
                      }} sx={{ px: 3, borderRadius: '8px'}}>
                        Annuler
                      </Button>
                      </>
                    )}
                    </Box>
                    
                  </>
                }

                {/* Links and documents */}
                { selectedSection === 'assets' &&
                  <>
                  <Box display={'flex'} 
                    flexDirection={{
                      xs:'column',
                      sm:'column',
                      md:'column',
                      lg:'row',
                      xl:'row'
                    }} gap={2}>

                  {/* Cover Picture Section */}
                    {
                      data?.can_have_multiple_profiles === false && 
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2" mb>{data?.url_cover_picture ? 'Votre coverture' : 'Ajouter un coverture'}</Typography>
                                          <Avatar
                                            sx={[getAvatarStyle(),{
                                              position: "relative",
                                              borderRadius: "20px",
                                              height: "22.5rem", // 360px
                                              border: "1px solid #C9C5D2",
                                              marginBottom: "1rem",
                                            }]}
                                            variant="rounded"
                                          >
                                            {coverImage ? (
                                              <img alt="cover-pic" ref={coverImageProfile} src={coverImage} style={{ width: "100%", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                                            ) : data?.url_cover_picture ? (
                                              <img alt="cover-pic" ref={coverImageProfile} src={data?.url_cover_picture} style={{ width: "100%", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                                            ) : (
                                              <img alt="cover-pic" src={'https://placehold.co/725x360'} style={{ width: "45.3125rem", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                                            )}
                                            <IconButton
                                              sx={{
                                                zIndex: "33",
                                                position: "absolute",
                                                right: "0.5rem",
                                                bottom: "0.5rem",
                                                bgcolor: `${colors.main_purple}`,
                                                "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                                              }}
                                              component="label"
                                            >
                                              <input
                                                hidden
                                                id="cover_picture"
                                                name="cover_picture"
                                                type="file"
                                                onChange={async (event) => {
                                                  const file = event.target.files[0];
                                                  if (file) {
                                                    try {
                                                      await validateImageSize(file);
                                                      setOnCoverImage(true);
                                                      setCoverFormat(file.type);
                                                      setCoverImage(URL.createObjectURL(file));
                                                    } catch (error) {
                                                      toast.error(error.message); 
                                                    }
                                                  }
                                                }}
                                              />
                                            {data?.url_cover_picture ? <EditIcon htmlColor="white"/> : <AddRoundedIcon htmlColor="white" />}
                                            </IconButton>
                                          </Avatar>
                                          <Modal
                                            sx={{ backgroundColor: "#3f3f3f" }}
                                            open={onCoverImage}
                                            onClose={() => {
                                              setCoverImage(null);
                                              formikRef.current.values.cover_picture = "";
                                              setOnCoverImage(false);
                                            }}
                                          >
                                            <Box height="100%" width="100%" margin="0 auto">
                                              <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <Box height="100%" width="100%" display="flex" flexDirection="column">
                                                  <Cropper style={styleCropper} image={coverImage} crop={coverCrop} zoom={coverZoom} aspect={725 / 360} onCropChange={setCoverCrop} onCropComplete={onCoverCropComplete} onZoomChange={setCoverZoom} />
                                                </Box>
                                              </Box>
                                              <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                <MzErrorText>{errors.cover_picture ? errors.cover_picture : null}</MzErrorText>
                                                <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                                  <Button disabled={errors?.cover_picture ? true : false} onClick={onCoverCrop} variant="primary">
                                                    Confirmer
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                      setCoverImage(null);
                                                      formikRef.current.values.cover_picture = "";
                                                      setOnCoverImage(false);
                                                    }}
                                                  >
                                                    Annuler
                                                  </Button>
                                                </Box>
                                                <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                                  <Typography color="white">Niveau de zoom : </Typography>
                                                  <input type="range" min={1} max={3} step={0.1} value={coverZoom} onInput={(e) => setCoverZoom(e.target.value)} />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Modal>
                                          <Box>
                                            {errors.cover_picture ? <MzErrorText>{errors.cover_picture}</MzErrorText> : null}
                                          </Box>
                  
                                          <Box display="flex" flexDirection="column" alignItems="end" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                                            <Box display="flex" gap="0.5rem" alignItems="center">
                                              <Button variant="primary" onClick={()=> onSubmitUpdateCoverPicture(values)}>
                                                Enregistrer la photo
                                              </Button>
                                              {data?.url_cover_picture && (
                                                <IconButton
                                                  onClick={() => deleteCoverPic()}
                                                  sx={{
                                                    zIndex: "33",
                                                    bgcolor: `${colors.bd_suspended}`,
                                                    "&:hover": { bgcolor: `${colors.bd_suspended_hover}` },
                                                  }}
                                                  component="label"
                                                >
                                                  <DeleteRoundedIcon htmlColor="white" />
                                                </IconButton>
                                              )}
                                            </Box>
                                            <Box>
                                              {onUploadCoverImage && (
                                                <Box display="flex" alignItems="center" gap="1rem">
                                                  <CircularProgress size={30} />
                                                  <Typography width="20px" color={colors.main_purple}>
                                                    {coverProgress ? coverProgress + "%" : null}
                                                  </Typography>
                                                </Box>
                                              )}
                                            </Box>
                                          </Box>
                        </Box>
                    }
                  </Box>

                  {/* document */}
                    <Box>
                    <h6 className="text-start my-4 font-regular text-md">{docs.length > 0 ? 'Votre brochure' : 'Ajouter votre brochure'}</h6>
                      <Box display={'flex'} justifyContent='space-between' alignItems='center' 
                        sx={{
                          border: '2px dashed #C9C5D2',
                          borderRadius: '8px',
                          padding: '1.5rem',
                          textAlign: 'start',
                          cursor: openAddDoc ? 'pointer' : 'default',
                          backgroundColor: '#F7F7F7',
                          width:'100%'
                        }} 
                      >
                      { !openAddDoc && docs.length > 0 ?
                        <>
                        <Box
                          display="flex"
                          alignItems='center'  
                        >
                            <Document size="38" color={colors.light_purple}/>
                            <Link to={docs.url_file} target="_blank">
                              <Typography variant='body2' sx={{textDecoration: 'underline'}} fontSize={16} color={colors.light_purple} fontWeight={500}>
                                {docs.title}
                              </Typography>
                            </Link>
                        </Box>

                        <MyIconButton
                          sx={{ padding: "1rem" }}
                          name="edit"
                          onClick={() => {
                            setSelectedDocToDelete(docs);
                            handleOpenAddDoc();
                          }}
                        />
                        </>
                      :
                        <>
                          <Dropzone onDrop={onDrop} accept=".pdf">
                          {({ getRootProps, getInputProps }) => (
                            <Box 
                                {...getRootProps()} 
                                sx={{
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    width:'100%'
                                }}
                            >
                                <input {...getInputProps()} />
                                <CloudUploadOutlined sx={{ fontSize: '3rem', color: colors.main_purple }} />
                                {!document && <Typography variant='body2'>Sélectionnez un fichier ou faites glisser et déposez-le ici.</Typography>}
                                {document && <Typography variant='body2' mt={1} color={colors.light_purple} fontWeight={600}>{document.name}</Typography>}
                                {!document && <Typography variant='body2' fontSize={'12px'} color={colors.gray}> PDF, taille de fichier de pas plus de 5 Mo.</Typography>}
                            </Box>
                            )}
                          </Dropzone>
                        </>
                      }
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} mt={2}>
                        { (openAddDoc && document) && 
                          <Button variant='form' sx={{px:3}} onClick={()=> addDoc()}>Uploader</Button>
                        }
                        { openAddDoc && 
                          <Button variant="secondary" sx={{borderRadius:'8px', px:3}} onClick={()=> handleCloseAddDoc()}>Annuler</Button>
                        }
                      </Box>
                    </Box>

                  {/* links */}
                    <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                    <h6 className="text-start my-4 font-regular text-md">Ajouter votre liens</h6>
                    {inLinks && (
                      <Box sx={{ width:'100%', backgroundColor: "#F5F5F5", borderRadius: ".5rem", padding: "0.5rem" }}>                          
                      {links?.map((l, index) => (
                      <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <IconLink title={l.title} icon={getLogoUrl(l)} value={l.value} />
                        <Box>
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="edit"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        if (l.slug === "other") {
                          setAllowOtherField(true);
                        } else {
                          setAllowOtherField(false);
                        }
                        handleOpenUpdate();
                      }}
                    />
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="delete"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        deleteLink(l.id)
                      }}
                    />
                  </Box>
                  </Box>
                ))}
                    <IconButton
                              sx={{
                                margin: "0.5rem 1rem",
                                bgcolor: `${colors.main_purple}`,
                                "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                              }}
                              onClick={handleOpenAddLink}
                            >
                              <AddRoundedIcon htmlColor="white" />
                    </IconButton>

                  {/* Modal add link */}
                  <Modal open={openAddLink} onClose={handleCloseAddLink}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" textAlign={'center'} fontWeight="600" mb=".5rem">
                    Ajouter un lien
                  </Typography>
                  <Typography variant="body2" fontsize={'8px'} textAlign={'center'} mb="1rem" color={colors.gray} sx={{whiteSpace:'normal'}}>Entrez les détails du nouveau lien à ajouter, en incluant une URL valide</Typography>

                  {/* form add link */}
                  <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box className="field" sx={{ marginY: "2rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = {};
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");
                              setFieldValue("icon_tag", value ? value.icon_tag : "Site web");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value.title);
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                            InputProps={{
                              style: {
                                backgroundColor: '#F5F5F5',
                                width:'100%',
                                borderRadius: '8px',
                                border: 'transparent'
                              },
                            }}
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" InputProps={{
                                      style: {
                                        backgroundColor: '#F7F7F7',
                                        width:'100%',
                                        borderRadius: '8px',
                                        border: 'transparent'
                                      },
                                    }} 
                            label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field
                              name="icon"
                              component={Autocomplete}
                              options={iconOptions}
                              getOptionLabel={(option) => option.label}
                              onChange={(event, value) => {
                                setFieldValue("icon", value ? value.value : "");
                                setFieldValue("icon_tag", value ? value.value : "Site web"); 
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionner votre icône" fullWidth size="small" />
                              )}
                              renderOption={(props, option) => (
                                <Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                                  {option.icon}
                                  <Typography sx={{ marginLeft: 1 }}>{option.label}</Typography>
                                </Box>
                              )}
                            />
                            <ErrorMessage name="icon" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" InputProps={{
                                      style: {
                                        backgroundColor: '#F7F7F7',
                                        width:'100%',
                                        borderRadius: '8px',
                                        border: 'transparent'
                                      },
                                    }}
                          label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" width={'100%'} flexDirection="row" gap="0.5rem" justifyContent={'stretch'}>
                          <Button onClick={handleCloseAddLink} variant="secondary"
                            sx={{paddingX: 5, paddingY:1, borderRadius:'8px', width:'100%'}}
                          >
                            Annuler
                          </Button>
                          <Button variant="form" type="submit"
                            sx={{paddingX: 5, paddingY:1, width:'100%'}}
                          >
                            Ajouter
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add link */}
                </Box>
                  </Modal>

                  {/* modal update link */}
                    <Modal open={openUpdate} onClose={handleCloseUpdate}>
                <Box sx={ModalStyle}>
                <Typography fontSize="1.5rem" textAlign={'center'} fontWeight="600" mb=".5rem">
                    Mettre a jour un lien
                  </Typography>
                  <Typography variant="body2" fontsize={'8px'} textAlign={'center'} mb="1rem" color={colors.gray} sx={{whiteSpace:'normal'}}>Entrez la nouvelle URL pour le lien.</Typography>

                  {/* form update link */}
                  <Formik initialValues={{ link_id: selectedLinkToDelete?.id, value: selectedLinkToDelete?.value ?? "" }} 
                          validationSchema={
                            Yup.object({
                              value: Yup.string().required("Ce champ est obligatoire").url("URL invalide"),
                            })
                          }
                  >
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        {/* Editable value field */}
                        <Box className="field" sx={{ marginBottom: "1rem", width:'100%' }}>
                          <Field name="value" type="input"
                            InputProps={{
                              style: {
                                backgroundColor: '#F5F5F5',
                                width:'100%',
                                borderRadius: '8px',
                                border: 'transparent'
                              },
                            }}
                           label="Nouvelle URL" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" width={'100%'} flexDirection="row" gap="0.5rem" justifyContent={'stretch'}>
                          <Button onClick={handleCloseUpdate} variant="secondary" sx={{paddingX: 5, paddingY:1, borderRadius:'8px', width:'100%'}}>
                            Annuler
                          </Button>

                          <Button
                            variant="form"
                            sx={{paddingX: 6, paddingY:.5, width:'100%'}}
                            onClick={() => {
                              if (isObjEmpty(errors)) {
                                updateLink(values)
                              }
                            }}
                          >
                            Enregistrer
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form update link */}
                </Box>
                    </Modal>

                  {/* end modal update link  */}
                  </Box>
                    )}

                    </Box>
                  </>
                }

                {/* Contacts */}
                { selectedSection === 'contacts' &&
                    renderComponent(<ContactsCard/>, <Contacts/>)
                }

                {/* Statistics */}
                { selectedSection === 'stats' &&
                    <Dashboard role={'COLLABORATOR'}/>
                }

                {/* Activities */}
                { (selectedSection === 'activities' && handleRoles()) &&
                    <ProfileCard profiles={profiles} onUpdate={()=> getUser() }/>
                }

                </Box>
              </Form>
            )}
          </Formik>
          {/* End mes informations Section*/}
            <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

          <ConfirmationModal
            title="Supprimer la photo de profile"
            message="Êtes-vous sûr de bien vouloir supprimer la photo de profile  ?"
            acceptLabel="oui"
            refuseLabel="non"
            open={openDeletePic}
            onClose={() => setOpenDeletePic(false)}
            acceptMethod={deleteProfilePic}
            acceptMethodParams="SUSPENDED"
          />
        </Box>
      </Box>

    </Box>
  );
}

export default AbonneDetails;
