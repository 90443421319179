import { useState } from "react";
import { colors } from "../../theme/Colors";
import { Typography, Box } from '@mui/material'

const TabsList = ({tabs, active, onToggle}) => {
    const [selectedTab, setSelectedTab] = useState(active);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        onToggle(tab)
    };

    const activeTabStyle = (item) => { 
        return {
            backgroundColor: selectedTab === item.symbol ? colors.main_purple : 'transparent',
            color: selectedTab === item.symbol ? 'white' : colors.carbon_black,
            border: selectedTab === item.symbol ? 'none' : `.8px solid ${colors.gray}`,
            borderRadius: '8px',
            fontWeight: selectedTab === item.symbol && 600,
            whiteSpace: 'nowrap'
        }
    }

    return ( 
        <Box
            sx={{
                backgroundColor: colors.smokeGray, 
                borderRadius: '8px',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                width: '320px'
            }}
            p={1}
        >
            <Box 
                display="flex" 
                alignItems="center"
                gap={1}
                sx={{
                    minWidth: 'min-content'
                }}
            >
                {tabs.map((tab) => (
                    <Box 
                        key={tab.id} 
                        p={0.5} 
                        onClick={() => handleTabChange(tab.symbol)} 
                        sx={{ cursor: 'pointer', flexShrink: 0 }}
                    >
                        <Typography variant="body1" fontSize={14} p={1.5} sx={activeTabStyle(tab)}>
                            {tab.name}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
     );
}
 
export default TabsList;