import { Box, Typography, Button, Avatar, Checkbox, Grid, Modal } from '@mui/material'
import { colors } from "../../theme/Colors";
import AddIcon from '@mui/icons-material/Add';
import { Trash } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import API from '../../api/Api_1_3';
import { useAuthContext } from '../../hooks/useAuthContext';
import CreateProfile from './CreateProfile';
import { toast, ToastContainer } from 'react-toastify'

const ProfileCard = ({profiles, onUpdate}) => {
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [openCreateProfile, setOpenCreateProfile] = useState(false);
    const { user } = useAuthContext()
    const id = user?.user?.id
    const access_token = user?.access_token
    const navigate = useNavigate()

    function viewProfile (profileId) {
      const root = user?.user?.role === 'COLLABORATOR' ? 'abonnee' : 'account'
      navigate(`/${root}/${id}/profiles/${profileId}`)
    }

    const deleteProfiles = async () => {
        try {
            const response = await API.post('/profiles/delete-multiple-profiles',
              { 
                profile_ids: selectedIDs
              },
              {
                headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'application/json',
              }}
            );
            if (response.status === 200) {
                toast.success(response.message)
                setSelectedIDs([])
                onUpdate()
            }
        } catch (error) {
            toast.error(`Error deleting profiles: ${error?.response?.data?.message || error.message}`);
        }
    };

    const handleSelectProfile = (id) => {
        setSelectedIDs(prev => 
            prev.includes(id) ? prev.filter(selectedId => selectedId !== id) : [...prev, id]
        );
    };

    const visibilityToggle = () => {
      return {
        xs:'none',
        sm:'none',
        md:'none',
        lg:'block',
        xl:'block',
      }
    }

    const handleOpenCreateProfile = () => {
      setOpenCreateProfile(true);
    };

    const handleCloseCreateProfile = () => {
      setOpenCreateProfile(false);
      onUpdate()
    };

    const renderFields = (profile) => {
      return (
          <>
            <Typography variant="body2" color={colors.gray} sx={{display: visibilityToggle()}} fontWeight={400} mb>Fonction: <span className='text-medium text-black'>{profile?.function ?? '--'}</span></Typography>
            <Typography variant="body2" color={colors.gray} sx={{display: visibilityToggle()}} fontWeight={400} mb>Pays: <span className='text-medium text-black'>{profile?.country_name ?? '--'}</span></Typography>
            <Typography variant="body2" color={colors.gray} sx={{display: visibilityToggle()}} fontWeight={400} mb>Ville: <span className='text-medium text-black'>{profile?.city_name ?? '--'}</span></Typography>
            <Typography variant="body2" color={colors.gray} sx={{display: visibilityToggle()}} fontWeight={400} mb>Address: <span className='text-medium text-black'>{profile?.address ?? '--'}</span></Typography>
          </>
      )
    }

    return ( 
      <Box>
          <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
          <Box display="flex" flexDirection={{xs: 'column', sm: 'column', md:'row', lg:'row'}} alignItems='start' mt={-1} mb={2} justifyContent='space-between'>
            <Typography variant="h6">List de mes activités</Typography>
            <Box display="flex" flexDirection="column" alignSelf="end" alignItems="flex-end" gap={2} m={2}>
              {profiles.length < 3 && 
                <Button variant="form" onClick={()=> handleOpenCreateProfile()} startIcon={<AddIcon/>}>
                    Créer un nouveau profil
                </Button>
              }
              {selectedIDs.length > 0 &&
                <Button variant="third" width={10} onClick={deleteProfiles} sx={{border:'none', py:1.2,  backgroundColor: selectedIDs.length === 0 ? colors.gray : colors.mjRedOutline, borderRadius:'10px'}}>
                  <Trash color={colors.textWhite} size={24}/>
                </Button>
              }
            </Box>
          </Box>
      <Grid container spacing={2}>
        {profiles?.map((prfl, index) => (
          <Grid item xs={12} md={index == 2 ? 12 : 6} key={prfl.id}>
            <Box display='flex' mb justifyContent='space-between' width="100%" alignItems='flex-start' sx={{ border: '1px solid' + colors.tFieldGray, borderRadius: '18px', padding: 2 }}>
            <Box display="flex" alignItems="flex-start">
              <Checkbox 
                checked={selectedIDs.includes(prfl.id)} 
                onChange={() => handleSelectProfile(prfl.id)} 
                sx={{ 
                  '& .Mui-checked': {
                    backgroundColor: colors.light_purple,
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                  py:0,
                  mt:1,
                  mb:1.5
                }} 
              />
              {/* profile infos */}
                <Box>
                  <Box display="flex" alignItems="center" mt={1} mb={1.5} gap={1}>
                    <Avatar 
                      src={prfl.url_business_logo} 
                      alt={prfl.business_commercial_name} 
                      sx={{ 
                        width: 40, 
                        height: 40, 
                        borderRadius: '50%', 
                        backgroundColor: prfl.url_business_logo ? 'transparent' : colors.main_purple
                      }}
                    >
                      {prfl.business_commercial_name ? prfl.business_commercial_name.charAt(0) : ''}
                    </Avatar>
                    <Typography variant="h6" mb>{prfl?.business_commercial_name}</Typography>{' '}
                  </Box>

                    <Typography variant="body2" fontWeight={400} color={colors.gray} mb>Téléphone: <span className='text-medium text-black'>{prfl?.phone_number ?? '--'}</span></Typography>
                      
                    <Typography variant="body2" fontWeight={400} color={colors.gray} mb>Email: <span className='text-medium text-black'>{prfl?.email ?? '--'}</span></Typography>
                    {renderFields(prfl)}
                  
                  {/* View more - mobile */}
                  <Box sx={{ transition: 'max-height 0.3s ease',
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'none',
                      lg: 'none',
                      xl: 'none',
                    } 
                    }}
                  >
                    <Typography variant="body1" fontWeight={500} onClick={()=> viewProfile(prfl.id)} sx={{ color: colors.light_purple, textDecoration: 'underline', cursor: 'pointer' }}>
                      Voir Profile 
                    </Typography>
                  </Box>
                </Box>
                
            </Box>

            {/* View more - desktop */}
            <Box mt={1} mb={1.5} sx={{ transition: 'max-height 0.3s ease', 
              display:{
                xs:'none',
                sm:'none',
                md:'block',
                lg:'block',
                xl:'block',
              } 
              }}
            >
                <Typography variant="body1" fontWeight={500} onClick={()=> viewProfile(prfl.id)} sx={{ color: colors.light_purple, textDecoration: 'underline', cursor: 'pointer' }}>
                  Voir Profile 
                </Typography>
            </Box>
          </Box>
          </Grid>
          ))
        }
      </Grid>

      <Modal open={openCreateProfile} onClose={() => setOpenCreateProfile(false)}>
          <Box 
            sx={{
              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
              maxHeight: '90vh',
              overflowY: 'auto',
              width: { xs: '90%', sm: '80%', md: '40%', lg: '40%' },
              margin: 'auto',
            }}
          >
            <CreateProfile id={id} onClose={handleCloseCreateProfile} />
          </Box>
      </Modal>
      </Box>
  )
}
 
export default ProfileCard;