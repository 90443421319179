import React, { useReducer } from "react";
import { useState, useEffect, useRef } from "react";
import { Container, Box, TextField, Button, Typography, Grid, Tabs, Tab, Autocomplete, IconButton, Avatar, Modal, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import Person from "../../imgs/user.png";
import API from "../../api/Api_1_3";
import { Close } from "@mui/icons-material";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TabsList from "../../components/ui/TabsList";
import { Link } from 'react-router-dom';
import { Trash } from 'iconsax-react'
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import LinkIcon from '@mui/icons-material/Link';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import EditIcon from '@mui/icons-material/Edit';
import WebIcon from '@mui/icons-material/Web';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import MyIconButton from '../../components/ui/MyIconButton';
import Dropzone from 'react-dropzone';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import { Document } from 'iconsax-react'
import IconLink from '../../components/ui/IconLink';
import { ModalStyle } from '../../theme/ModalStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ProfileDetailsProsumer() {
  const { user } = useAuthContext();
  const access_token = user.access_token;


  //is empty object
  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }


  //allow "other" field add
  const [allowOtherField, setAllowOtherField] = useState();

  //location settings (get the id from onClick table)
  const { userId, profileId } = useParams();

  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS_PROFILE_PIC = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];

  //validation schema profile picture
  const validationSchemaProfilePicture = Yup.object({
    url_business_logo: Yup.mixed()
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS_PROFILE_PIC.includes(value.type)),
  });

  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [selectedDocToDelete, setSelectedDocToDelete] = useState();

  const [image, setImage] = useState();
  const [data, setData] = useState();
  const [profiles, setProfiles] = useState([]);

  const [links, setLinks] = useState();

  const [possibleLinksList, setPossibleLinksList] = useState();
  const [docs, setDocs] = useState([]);
  const [docToAddName, setDocToAddName] = useState();

  //trigger reload infos
  const [didUpdate, setDidUpdate] = useState(false);
  const [didUpdateLink, setDidUpdateLink] = useState(false);
  const [didSuspend, setDidSuspend] = useState(false);
  const [didActivate, setDidActivate] = useState(false);
  const [didDeleteLink, setDidDeleteLink] = useState(false);
  const [didAddLink, setDidAddLink] = useState(false);
  const [didDeleteDoc, setDidDeleteDoc] = useState(false);
  const [didAddDoc, setDidAddDoc] = useState(false);
  const [didUpdateProfilePicture, setDidUpdateProfilePicture] = useState(false);

  //modal config
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openSuspend, setOpenSuspend] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [openDeletePic, setOpenDeletePic] = useState(false);

  const iconOptions = [
    { value: 'DatasetLinked', label: 'Dataset Linked', icon: <DatasetLinkedIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Link', label: 'Link', icon: <LinkIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'LeakAdd', label: 'Leak Add', icon: <LeakAddIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'OpenInNew', label: 'Open In New', icon: <OpenInNewIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Launch', label: 'Launch', icon: <LaunchIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'OfflineShare', label: 'Offline Share', icon: <OfflineShareIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLinkRing', label: 'Phone Link Ring', icon: <PhonelinkRingIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLink', label: 'Phone Link', icon: <PhonelinkIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Web', label: 'Web', icon: <WebIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Pen', label: 'Pen', icon: <EditIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Card', label: 'Card', icon: <CreditCardIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Profile', label: 'Profile', icon: <AccountCircleIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Page', label: 'Page', icon: <DescriptionIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
  ];

  // suspend card modal
  const handleOpenSuspend = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenSuspend(true);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);

  //activate card modal
  const handleOpenActive = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenActive(true);
  };
  const handleCloseActive = () => setOpenActive(false);

  //add link modal
  const handleOpenAddLink = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenAddLink(true);
  };
  const handleCloseAddLink = () => setOpenAddLink(false);

  //delete link modal
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //delete document modal
  const handleOpenDeleteDoc = () => {
    setOpenDeleteDoc(true);
  };
  const handleCloseDeleteDoc = () => setOpenDeleteDoc(false);

  //update link modal
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  //add doc modal
  const handleOpenAddDoc = () => {
    setOpenAddDoc(true);
  };
  const handleCloseAddDoc = () => setOpenAddDoc(false);

  const [change, setChange] = useState(false);

  //tab config
  const [inLinks, setInLinks] = useState(true);
  const [inDocuments, setInDocuments] = useState(false);
  const [currentVariantLink, setCurrentVariantLink] = useState("primary");
  const [currentVariantDocument, setCurrentVariantDocument] = useState("secondary");

  const handleButtonVariantChangeLink = () => {
    if ((currentVariantDocument === "secondary" && currentVariantLink === "primary") || (currentVariantDocument === "primary" && currentVariantLink === "secondary")) {
      setCurrentVariantLink("primary");
      setCurrentVariantDocument("secondary");
    }
  };
  const handleButtonVariantChangeDocument = () => {
    if ((currentVariantLink === "secondary" && currentVariantDocument === "primary") || (currentVariantLink === "primary" && currentVariantDocument === "secondary")) {
      setCurrentVariantDocument("primary");
      setCurrentVariantLink("secondary");
    }
  };

  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
  };

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [userId] ?? "",
    document: "",
  };

  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
  });

  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  function capitalizeName(name) {
    // Split the name into an array of words
    var words = name.toLowerCase().trim().split(/\s+/);

    // Capitalize the first letter of each word
    var capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back together into a string
    var capitalizedName = capitalizedWords.join(" ");

    // Return the capitalized name
    return capitalizedName;
  }

  // eliminate empty data

  const navigate = useNavigate();
  const imageProfile = useRef();

  //on page load (load user details)
  const getUser = ()=>{
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${userId}/account-details`, {
     headers: {
       "Content-type": "application/json",
       accept: "application/json",
       authorization: `Bearer ${access_token}`,
     },
   })
     .then((response) => {
       setProfiles(response.data?.user?.profiles);
       const selectedProfile = response.data?.user?.profiles.find(profile => profile.id == profileId);
        setData(selectedProfile);
        if(selectedProfile.links.length !== 0) setLinks(selectedProfile.links)
        if(selectedProfile.documents.length !== 0) setDocs(selectedProfile.documents)
     })
     .catch((error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });
        navigate(0, { replace: true });
      }
      if (error.response?.status === 404) navigate("/not_found");
      if (error?.response?.status === 403) {
        toast.error("Une erreur est survenue");
      } else {
        toast.error(error?.response?.data?.message);
      }
    });
  }

  useEffect(() => {
      getUser()
  }, [didUpdate, didSuspend, didActivate, change, didUpdateProfilePicture, didAddDoc, didAddLink, didUpdateLink, didDeleteDoc, didDeleteLink]);

  function updatePhoneNumber(phoneNumber) {
    const payload = {
        user_id: userId, // required
        profile_id: data?.id,
        phone_number: phoneNumber, // required
    };

    if(data?.other_phone_numbers.length === 0){
      API.post('/phone-numbers', payload, {
        headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        if (response.status === 200) {
            setDidUpdate(!didUpdate); // Trigger a refresh if needed
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
        } else {
            toast.error(error?.response?.data?.message);
        }
    });
    }
    else {
      API.patch('/phone-numbers/' + data?.other_phone_numbers[0]?.id,
        {
          phone_number: phoneNumber
      }, {
        headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        if (response.status === 200) {
            setDidUpdate(!didUpdate); // Trigger a refresh if needed
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
        } else {
            toast.error(error?.response?.data?.message);
        }
    });
    }

    
  }

  //submit update user
  const onSubmitUpdateUser = (values) => {
    const formData = new FormData(); // Create a new FormData object
    if(values.additional_phone_number && values.additional_phone_number !== null) {updatePhoneNumber(values.additional_phone_number)}

    // Append values to the FormData object
    Object.keys(values).forEach(key => {
      if (values[key] !== null && values[key] !== "") {
        formData.append(key, values[key]);
      }
    });

    API.post(
      '/profiles/' + `${data?.id}`,
      formData,
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        params: {
          _method: 'PATCH'
        }
      }
    )
      .then((response) => {

        if (response.status === 200) {
          setDidUpdate(!didUpdate);
          toast.success("Informations du profile changé avec succès");
          setTimeout(()=>{
              navigate('/account')
          },1000)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  
  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  //update profile picture
  const [onUploadImage, setOnUploadImage] = useState(false);

  const onSubmitUpdateProfilePicture = (values) => {
    setOnUploadImage(true);
    Api.post(
      Urls.UPDATE_PROFILE_PICTURE + `/${profileId}/profilePicture`,
      {
        url_business_logo: values.business_logo,
      },
      configs
    )
      .then((response) => {

        if (response.status === 200) {
          toast.success(response.data.message);
        }
        setOnUploadImage(false);
        setDidUpdateProfilePicture(!didUpdateProfilePicture);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setOnUploadImage(false);
      });
  };
  //update card status
  const updateCardStatus = (action) => {
    Api.patch(
      Urls.UPDATE_CARD_STATUS,
      {
        user_ids: [userId],
        status_card: action,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          if (action === "ACTIVE") {
            toast.success("Carte activéee");
            setDidActivate(!didActivate);
          }
          if (action === "SUSPENDED") {
            toast.success("Carte suspendue");
            setDidSuspend(!didSuspend);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //get list possible links
  useEffect(() => {
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  //add link
  const addLink = (values) => {
    API.post(
      '/profiles/store-link/' + `${data?.id}`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
        icon_tag: values.icon_tag
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 201) {
          handleCloseAddLink();
          setDidAddLink(!didAddLink);
          toast.success("Lien ajouté avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const [progress, setProgress] = useState();
  const [document, setDocument] = useState(null);

  //add doc
  const addDoc = (values) => {
    API.post(
      '/profiles/store-document/'+ data?.id,
      {
        title: values.name,
        document: values,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {

        if (response.status === 201) {
          handleCloseAddDoc();
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
          setDidAddDoc(!didAddDoc);
          setProgress();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //update link
  const updateLink = (values, id_link) => {
    Api.patch(
      Urls.DELETE_USER_LINK + `/${id_link}`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          handleCloseUpdate();
          setDidUpdateLink(!didUpdateLink);
          toast.success("Lien modifié avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //delete user
  const deleteUser = () => {
    try{
      API.delete('/profiles/' + data?.id, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          toast.success("Profile supprimé");
          setOnDeletNext(false);
          setTimeout(() => {
            navigate("/account");
          }, 500);
        })
    }
      catch(error) {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      };
  };

  //delete link
  const deleteLink = (id_link) => {
    Api.delete(
      Urls.DELETE_USER_LINK + `/${id_link}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          setDidDeleteLink(!didDeleteLink);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };


  //delete link
  const deleteDoc = (id) => {
    Api.delete(
      Urls.DELETE_USER_DOCUMENT + `/${id}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          setDidDeleteDoc(!didDeleteDoc);
          toast.success(response.data.message);
          setDocs([])
          setDocument(null)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //delete photo de profil
  const deleteProfilePic = () => {
    Api.delete(Urls.DELETE_PROFILE_PIC + userId + "/profilePicture", {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setImage(null);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const deleteCoverPic = () => {
    Api.delete('/users/' + data?.id + "/CoverPicture", {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setCoverImage(null);
        setCoverPicture(null)
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
      });
  };

  //crop image config
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [logo, setLogo] = useState(null)
  const [coverPicture, setCoverPicture] = useState(null)
  
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File(
      [croppedImage],
      `${format.split("/")[1]}`,
      { type: [`${format}`] }
    );
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.business_logo = file;
    }
  };

  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };
  //delete a user state
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("0zFgtYmLv9");

  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

// Cover Section

const [coverImage, setCoverImage] = useState();
const [onCoverImage, setOnCoverImage] = useState(false);
const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
const [coverZoom, setCoverZoom] = useState(1);
const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
const [coverFormat, setCoverFormat] = useState("");
const [coverProgress, setCoverProgress] = useState(0);
const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
const coverImageProfile = useRef();

const initValues = {
  business_logo: "",
  business_cover_picture: ""
}


const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
  setCoverCroppedAreaPixels(croppedAreaPixels);
};

const onCoverCrop = async () => {
  const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
  setCoverImage(URL.createObjectURL(croppedImage));
  var file = new File([croppedImage], `${coverFormat.split("/")[1]}`, { type: [`${coverFormat}`] });
  if (file && croppedImage) {
    setOnCoverImage(false);
    formikRef.current.values.business_cover_picture = file;
    file = null;
  }
};


const theme = useTheme();
const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

const getAvatarStyle = () => {
  let width;

  if (isXsScreen || isSmScreen) {
    width = '100%';
  } else if (isMdScreen) {
    width = '45.3125rem'; // 725px
  } else if (isLgScreen) {
    width = '45.3125rem'; // 725px
  }

  return {
    width
  };
};

const formikRef = useRef();

function deletePhoneNumber(){
    API.delete('/phone-numbers/' + data?.other_phone_numbers[0]?.id,{
      headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
      },
  })
  .then((response) => {
      if (response.status === 200) {
          toast.success("Numéro de téléphone supprimée avec succès");
          setDidUpdate(!didUpdate); // Trigger a refresh if needed
      }
  })
  .catch((error) => {
      if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
      }
      if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
      } else {
          toast.error(error?.response?.data?.message);
      }
  });
}

const validateImageSize = (file, type) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {

      if (type === 'cover') {
        if (img.width >= 725 && img.height >= 360) {
          formikRef.current.setFieldValue("business_cover_picture", file);
          resolve(true);
        } else {
          reject(new Error("L'image doit avoir une taille minimale de 725 x 360 pixels."));
        }
      } 
      
      else if (type === 'logo') {
        if (img.width >= 200 && img.height >= 200) {
          formikRef.current.setFieldValue("business_logo", file);
          resolve(true);
        } else {
          reject(new Error("L'image doit avoir une taille minimale de 200 x 200 pixel."));
        }
      } 
      
      else {
        reject(new Error("Type d'image non valide."));
      }
    };
    img.onerror = () => reject(new Error("Erreur lors du chargement de l'image."));
  });
};

const list = [
  { id: 1, symbol: 'info', name: 'Mes Informations' },
  { id: 2, symbol: 'assets', name: 'Liens et Documents' }
];

const [tab, SetTab] = useState('details')

const handleSetTab = (value) => {
  SetTab(value)
}

const FieldStyle = ()=> {
  return {
    p:.5,
    backgroundColor: colors.smokeGray,
    borderRadius:'8px'
  }
}

const getIcon = (url) => {
  return iconOptions.find(option => option.value === url.icon_tag)?.icon || <LinkIcon sx={{color: colors.bd_Purple, fontSize:24}}/>; 
}

const getLogoUrl = (url) => {
  const link = possibleLinksList?.find((link) => link.title === url.title);
  return link ? link.url_logo : getIcon(url) ;
};


const onDrop = (acceptedFiles) => {
  setDocument(acceptedFiles[0]);
  handleOpenAddDoc()
}

const goBack = () => {
  window.scrollTo(0,0)
  navigate('/account')
}


const initialValues = {
  email: data?.email ?? "",
  business_commercial_name: data?.business_commercial_name ?? "",
  business_logo: "",
  business_cover_picture: "",
  function: data?.function ?? "",
  phone_number: data?.phone_number ?? "",
  additional_phone_number: data?.other_phone_numbers[0]?.phone_number ?? "",
  country_name: data?.country_name ?? "",
  city_name: data?.city_name ?? "",
  postal_code: data?.postal_code ?? "",
  address: data?.address ?? "",
}

const handleTabOnScreens = () => {
  if (isMdScreen || isLgScreen) {
    SetTab('details')
  } else if (isXsScreen || isSmScreen) {
    SetTab('info')
  }
}

const [expandedSections, setExpandedSections] = useReducer((state, action) => {
  switch (action.type) {
    case 'TOGGLE_SECTION':
      return {
        ...state,
        [action.section]: !state[action.section]
      };
    default:
      return state;
  }
}, {
  info: true,
  assets: true
});

const toggleSection = (section) => {
  setExpandedSections({ type: 'TOGGLE_SECTION', section });
};


useEffect(()=>{
  handleTabOnScreens()
},[isXsScreen,isSmScreen,isMdScreen, isLgScreen])
    
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" gap={2} mt mb={{
        xs:0,
        sm:0,
        md:4,
        lg:4,
        xl:4
      }}>

        <Box display="flex" alignItems='center' gap={2}>
          <ArrowBackIosIcon sx={{cursor: 'pointer'}} onClick={() => navigate('/account')}/>
          <Typography fontSize={23} sx={{ fontWeight: "500" }}>Détail du Profile</Typography>
        </Box>

      </Box>

      {(isXsScreen || isSmScreen) && 
        <Box display="flex" justifyContent="center" my={3.5}>
          <TabsList tabs={list} active={tab} onToggle={(tab)=> handleSetTab(tab)}/>
        </Box>
      }

    <Box width={{
      md:'90%',
      lg:'90%',
      xl:'90%'
    }} sx={{p:4, backgroundColor: colors.textWhite, borderRadius:'18px', alignSelf:'center'}}>

      <Box display="flex" flexDirection="column" gap="0.5rem">
        {data?.business_commercial_name && <Typography variant="h5">Information du profile {data?.business_commercial_name}</Typography>}
        {data?.function && <Typography variant="secondary" fontSize={18} fontWeight={500}>{`${data?.function}`}</Typography>}
      </Box>

      <Box display="flex" justifyContent="flex-end" my={3}>
        <Button startIcon={<Trash/>} onClick={() => setOnDelete(true)} sx={{backgroundColor:'white', color: colors.carbon_black, textTransform: 'none', borderRadius:'8px', border: '1px solid' + colors.gray ,":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }}>
          Supprimer le profile
        </Button>
      </Box>

      {(tab === 'info' || tab === 'details') &&
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
        >
          
        {(isLgScreen || isMdScreen ) &&
          <Box display="flex" alignItems="center" justifyContent="space-between" mb onClick={()=> toggleSection('info')}>
            <Typography variant="h6">Mes Informations</Typography>
            {expandedSections.info ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </Box>
        }
        
        {expandedSections.info &&
          <Box sx={{
            animation: 'slideDown 0.3s ease-in-out forwards',
            '@keyframes slideDown': {
              '0%': {
                opacity: 0,
                transform: 'translateY(-10px)'
              },
              '100%': {
                opacity: 1, 
                transform: 'translateY(0)'
              }
            }
          }}>
          <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            const newValues = {
              email: values.email,
              business_commercial_name: values.business_commercial_name,
              business_logo: formikRef.current?.values?.business_logo ?? null,
              business_cover_picture: formikRef.current?.values?.business_cover_picture ?? null,
              function: values.function,
              phone_number: values.phone_number,
              additional_phone_number: values.additional_phone_number,
              country_name: values.country_name,
              city_name: values.city_name,
              postal_code: values.postal_code,
              address: values.address,
            };
            
            onSubmitUpdateUser(newValues, access_token); 
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="business_commercial_name" as={TextField} variant="outlined" label="Nom Commercial" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="business_commercial_name" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="email" as={TextField} variant="outlined" label="Email*" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="email" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="phone_number" as={TextField} variant="outlined" label="Téléphone professionel" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="phone_number" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                      <Field name="additional_phone_number" as={TextField} variant="outlined" fullWidth label="Téléphone supplémentaire" size="medium" InputProps={{ sx: FieldStyle() }} />
                      {data?.other_phone_numbers.length > 0 && <Close sx={{ color: 'red', cursor: 'pointer' }} onClick={() => deletePhoneNumber()} />}
                    </Box>
                    <ErrorMessage name="additional_phone_number" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="function" as={TextField} variant="outlined" label="Fonction" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="function" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="country_name" as={TextField} variant="outlined" label="Pays" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="country_name" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="city_name" as={TextField} variant="outlined" label="Ville" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="city_name" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Field name="postal_code" as={TextField} variant="outlined" label="Code Postal" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="postal_code" component={MzErrorText} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Field name="address" as={TextField} variant="outlined" label="Address" fullWidth size="medium" InputProps={{ sx: FieldStyle() }} />
                    <ErrorMessage name="address" component={MzErrorText} />
                  </Box>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent={{
                xs:"space-between",
                sm:"space-between",
                md:"flex-end",
                lg:"flex-end",
                xl:"flex-end"
              }} width="100%" gap={2}>
                {Object.keys(values).some(key => values[key] !== initialValues[key]) && (
                  <>
                  <Button variant="secondary" onClick={() => {
                        Object.keys(initialValues).forEach(key => {
                          setFieldValue(key, initialValues[key]);
                        });
                      }}  
                    sx={{borderRadius:'8px', width:{
                  xs:'50%',
                  sm:'50%',
                  md:'20%',
                  lg:'20%',
                  xl:'20%'
                }}}>
                    Annuler
                  </Button>
                  <Button variant="form" type="submit" sx={{
                    width:{
                    xs:'50%',
                    sm:'50%',
                    md:'20%',
                    lg:'20%',
                    xl:'20%'
                  }}}>
                    Enregistrer
                  </Button>
                  </>
                )}
              </Box>

            </Form>
          )}
          </Formik>
          </Box>
        }

        </Box>
      }


      {(tab === 'assets' || tab === 'details') &&
        <Box sx={{ marginBottom: "2rem" }}>
        <Box
          display="flex"
          sx={{
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >

        {(isMdScreen || isLgScreen) && 
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={3} onClick={()=> toggleSection('assets')}>
            <Typography variant="h6">Liens et Documents</Typography>
            {expandedSections.assets ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </Box>
        }
        
          {expandedSections.assets &&
            <Box sx={{
              animation: 'slideDown 0.3s ease-in-out forwards',
              '@keyframes slideDown': {
                '0%': {
                  opacity: 0,
                  transform: 'translateY(-10px)'
                },
                '100%': {
                  opacity: 1, 
                  transform: 'translateY(0)'
                }
              }
            }}>
              {/* Logos */}
              <Box>
            {/* formik 2 logo */}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initValues}
              validationSchema={validationSchemaProfilePicture}
              onSubmit={(values) => {
                onSubmitUpdateProfilePicture(values, access_token);
              }}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <Box display="flex" 
                    alignItems={{
                      xs:"center",
                      sm:"center",
                      md:"center",
                      lg:"center",
                      xl:"center"
                    }} 
                    justifyContent={{
                      xs:"center",
                      sm:"center",
                      md:"center",
                      lg:"center",
                      xl:"center"
                    }}
                    flexDirection={{
                      xs:"column",
                      sm:"column",
                      md:"row",
                      lg:"row",
                      xl:"row"
                    }} 
                    gap={3}
                  >
                    {/* Business Picture */}
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" mb>{data?.url_business_logo ? 'Votre Logo' : 'Ajouter un Logo'}</Typography>
                      <Avatar
                        sx={{
                          position: "relative",
                          borderRadius: "20px",
                          width: "12rem",
                          height: "12rem",
                          border: "1px solid #C9C5D2",
                          marginBottom: "1rem",
                        }}
                        variant="rounded"
                      >
                        {image ? (
                          <img alt="profile" ref={imageProfile} src={image} style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                        ) : data?.url_business_logo ? (
                          <img alt="profile" ref={imageProfile} src={data?.url_business_logo} style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                        ) : (
                          <img alt="profile" src={Person} style={{ width: "12rem", height: "12rem" }} />
                        )}
                        <IconButton
                          sx={{
                            zIndex: "33",
                            position: "absolute",
                            right: "0.5rem",
                            bottom: "0.5rem",
                            bgcolor: `${colors.main_purple}`,
                            "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                          }}
                          component="label"
                        >
                          <input
                            hidden
                            id="business_logo"
                            name="business_logo"
                            type="file"
                            onChange={async (event) => {
                              const file = event.target.files[0];
                              if (file) {
                                try {
                                  await validateImageSize(file,'logo');
                                  setOnImage(true);
                                  setImage(URL.createObjectURL(event.target.files[0]));
                                  setFormat(event.target.files[0].type);
                                  setLogo(event.target.files[0])
                                } catch (error) {
                                  toast.error(error.message); 
                                }
                              }
                            }}
                          />
                          {data?.url_business_logo ? <EditIcon htmlColor="white"/> : <AddRoundedIcon htmlColor="white" />}
                        </IconButton>
                      </Avatar>
                      <Modal
                        sx={{ backgroundColor: "#3f3f3f" }}
                        open={onImage}
                        onClose={() => {
                          setImage(null);
                          formikRef.current.values.business_logo = null;
                          setOnImage(false);
                        }}
                      >
                        <Box height="100%" width="100%" margin="0 auto">
                          <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box height="100%" width="100%" display="flex" flexDirection="column">
                              <Cropper style={styleCropper} image={image} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                            </Box>
                          </Box>
                          <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <MzErrorText>{errors.business_logo ? errors.business_logo : null}</MzErrorText>
                            <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                              <Button disabled={errors?.business_logo ? true : false} onClick={onCrop} variant="primary">
                                Confirmer
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setImage(null);
                                  formikRef.current.values.business_logo = null;
                                  setOnImage(false);
                                }}
                              >
                                Annuler
                              </Button>
                            </Box>
                            <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", md: "row", lg: "row", sm: "row" }, alignItems: "center" }}>
                              <Typography color="white">Niveau de zoom : </Typography>
                              <input type="range" min={1} max={3} step={0.1} value={zoom} onInput={(e) => setZoom(e.target.value)} />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                      <MzErrorText>{errors.business_logo}</MzErrorText>
                      <Box display="flex" flexDirection="column" alignItems="center" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                        <ConfirmationModal
                          title="Supprimer la photo de profile"
                          message="Êtes-vous sûr de bien vouloir supprimer la photo de profile ?"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={openDeletePic}
                          onClose={() => setOpenDeletePic(false)}
                          acceptMethod={deleteProfilePic}
                          acceptMethodParams="SUSPENDED"
                        />
                        <Box>
                          {onUploadImage && (
                            <Box display="flex" alignItems="center" gap="1rem">
                              <CircularProgress size={30} />
                              <Typography width="20px" color={colors.main_purple}>
                                {progress ? progress + "%" : null}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    
                     {/* Cover Picture Section */}
                     <Box display="flex" flexDirection="column" width="100%">
                        <Typography variant="body2" mb>{data?.url_business_cover_picture ? 'Votre coverture' : 'Ajouter une photo de coverture'}</Typography>
                        {data?.url_business_cover_picture && 
                          <Avatar
                          sx={[getAvatarStyle(),{
                            position: "relative",
                            borderRadius: "20px",
                            height: "22.5rem", // 360px
                            border: "1px solid #C9C5D2",
                            marginBottom: "1rem",
                          }]}
                          variant="rounded"
                        >
                          {coverImage ? (
                              <img alt="cover-pic" ref={coverImageProfile} src={coverImage} style={{ width: "100%", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                            ) : 
                           data?.url_business_cover_picture ? (
                              <img alt="cover-pic" ref={coverImageProfile} src={data?.url_business_cover_picture} style={{ width: "100%", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                            )
                            :
                            (
                              <img alt="cover-pic" src={'https://placehold.co/725x360'} style={{ width: "45.3125rem", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                          )}                          
                          <IconButton
                            sx={{
                              zIndex: "33",
                              position: "absolute",
                              right: "0.5rem",
                              bottom: "0.5rem",
                              bgcolor: `${colors.main_purple}`,
                              "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                            }}
                            component="label"
                          >
                            <input
                              hidden
                              id="business_cover_picture"
                              name="business_cover_picture"
                              type="file"
                              onChange={async (event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  try {
                                    await validateImageSize(file,'cover');
                                    setOnCoverImage(true);
                                    setCoverImage(URL.createObjectURL(file));
                                    setCoverFormat(file.type);
                                    setCoverPicture(file);
                                  } catch (error) {
                                    toast.error(error.message); 
                                  }
                                }
                              }}
                            />
                            {data?.url_business_cover_picture ? <EditIcon htmlColor="white"/> : <AddRoundedIcon htmlColor="white" />}
                          </IconButton>
                          </Avatar>
                        }

                        <Modal
                          sx={{ backgroundColor: "#3f3f3f" }}
                          open={onCoverImage}
                          onClose={() => {
                            setCoverImage(null);
                            formikRef.current.values.business_cover_picture = "";
                            setOnCoverImage(false);
                          }}
                        >
                          <Box height="100%" width="100%" margin="0 auto">
                            <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <Box height="100%" width="100%" display="flex" flexDirection="column">
                                <Cropper style={styleCropper} image={coverImage} crop={coverCrop} zoom={coverZoom} aspect={725 / 360} onCropChange={setCoverCrop} onCropComplete={onCoverCropComplete} onZoomChange={setCoverZoom} />
                              </Box>
                            </Box>
                            <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                              <MzErrorText>{errors.business_cover_picture ? errors.business_cover_picture : null}</MzErrorText>
                              <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                <Button disabled={errors?.business_cover_picture ? true : false} onClick={onCoverCrop} variant="primary">
                                  Confirmer
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    setCoverImage(null);
                                    formikRef.current.values.business_cover_picture = "";
                                    setOnCoverImage(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Box>
                              <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                <Typography color="white">Niveau de zoom : </Typography>
                                <input type="range" min={1} max={3} step={0.1} value={coverZoom} onInput={(e) => setCoverZoom(e.target.value)} />
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                        
                        {data?.url_business_cover_picture == null &&
                          <Dropzone
                              accept={{
                                'image/*': ['.jpg', '.jpeg', '.png', '.gif'] 
                              }}
                              onDrop={(acceptedFiles) => {
                                const file = acceptedFiles[0];
                                if (file) {
                                  if (file.size <= FILE_SIZE) {
                                    setFieldValue("cover_picture", file);
                                    setCoverImage(URL.createObjectURL(file));
                                    setOnCoverImage(true);
                                    setCoverPicture(file)
                                  } else {
                                    toast.error("Fichier volumineux");
                                  }
                                }
                              }}
                            >
                              {({getRootProps, getInputProps}) => (
                                <Box
                                  {...getRootProps()}
                                  sx={{
                                    border: '2px dashed #C9C5D2',
                                    borderRadius: '8px', 
                                    padding: '1.5rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1,
                                    cursor: 'pointer',
                                    width:'100%'
                                  }}
                                >
                                  <input {...getInputProps()} />
                                  <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                                    <InsertPhotoOutlinedIcon sx={{ fontSize: 40, color: colors.main_purple }} />
                                    <Typography variant="body2" fontWeight={coverPicture ? 500 : 400} color={coverPicture ? colors.light_purple : 'textSecondary'} textAlign="center" >
                                      {coverPicture ? coverPicture.name : 'Cliquez ou glissez-déposez votre photo de couverture ici'}
                                    </Typography>
                                    {!coverPicture &&
                                      <Typography variant="caption" color="textSecondary">
                                        Format accepté: JPG, PNG, GIF
                                      </Typography>
                                    }
                                  </Box>
                                </Box>
                              )}
                          </Dropzone>
                        }


                        <Box>
                          {errors.business_cover_picture ? <MzErrorText>{errors.business_cover_picture}</MzErrorText> : null}
                        </Box>

                        <Box display="flex" flexDirection="column" alignItems="end" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                          <Box>
                            {onUploadCoverImage && (
                              <Box display="flex" alignItems="center" gap="1rem">
                                <CircularProgress size={30} />
                                <Typography width="20px" color={colors.main_purple}>
                                  {coverProgress ? coverProgress + "%" : null}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    
                  </Box>
                </Form>
              )}
            </Formik>
              </Box>

              {/* Links and documents */}
              <Box>
            {/* document */}
            <Box>
            <h6 className="text-start my-4 font-regular text-md">{docs?.length > 0 ? 'Votre brochure' : 'Ajouter votre brochure'}</h6>
            <Box display={'flex'} justifyContent='space-between' alignItems='center' 
                sx={{
                  border: '2px dashed #C9C5D2',
                  borderRadius: '8px',
                  padding: '1.5rem',
                  textAlign: 'start',
                  cursor: openAddDoc ? 'pointer' : 'default',
                  backgroundColor: '#F7F7F7',
                  width:'100%'
                }} 
                          >
                          { (openAddDoc === false && docs?.length > 0 ) ?
                            <>
                            <Box
                              display="flex"
                              alignItems='center'
                              gap={2}  
                            >
                                <Document size="38" color={colors.light_purple}/>
                                {docs !== null ?
                                  <Link to={docs[0]?.url_file} target="_blank">
                                    <Typography variant='body2' sx={{textDecoration: 'underline'}} fontSize={16} color={colors.light_purple} fontWeight={500}>
                                      {docs[0]?.title}
                                    </Typography>
                                  </Link>
                                  :
                                  <Typography variant="body2" fontWeight={500} color={colors.light_purple} fontSize={16}>Aucun Document ajouté</Typography>
                                }
                            </Box>
    
                            <Box>
                              <MyIconButton
                                sx={{ padding: "1rem" }}
                                name="edit"
                                onClick={(l) => {
                                  setSelectedDocToDelete(docs);
                                  handleOpenAddDoc();
                                }}
                              />
                              {data?.documents.length > 0 && (
                                  <MyIconButton
                                    name={'delete'}
                                    onClick={() => deleteDoc(docs[0].id)}
                                  >
                                    <DeleteRoundedIcon htmlColor="white" />
                                  </MyIconButton>
                              )}
                            </Box>
                            </>
                          :
                            <>
                              <Dropzone onDrop={onDrop} accept=".pdf">
                              {({ getRootProps, getInputProps }) => (
                                <Box 
                                    {...getRootProps()} 
                                    sx={{
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        width:'100%'
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <CloudUploadOutlined sx={{ fontSize: '3rem', color: colors.main_purple }} />
                                    {!document && <Typography variant='body2'>Sélectionnez un fichier ou faites glisser et déposez-le ici.</Typography>}
                                    {document && <Typography variant='body2' mt={1} color={colors.light_purple} fontWeight={600}>{document.name}</Typography>}
                                    {!document && <Typography variant='body2' fontSize={'12px'} color={colors.gray}> PDF, taille de fichier de pas plus de 5 Mo.</Typography>}
                                </Box>
                                )}
                              </Dropzone>
                            </>
                          }
            </Box>
    
            <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} mt={2}>
                { (openAddDoc && document) && 
                  <Button variant='form' sx={{px:3}} onClick={()=> addDoc(document)}>Uploader</Button>
                }
                { openAddDoc && 
                  <Button variant="secondary" sx={{borderRadius:'8px', px:3}} onClick={()=> handleCloseAddDoc()}>Annuler</Button>
                }
            </Box>
            </Box>
    
            {/* links */}
              <Box display={'flex'} width={'100%'} flexDirection={'column'} mb={2}>
            <h6 className="text-start my-4 font-regular text-md">Ajouter votre liens</h6>
                {inLinks && (
                          <Box sx={{ width:'100%', backgroundColor: "#F5F5F5", borderRadius: ".5rem", padding: "0.5rem" }}>              
                          {links?.map((l, index) => (
                          <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <IconLink title={l.title} icon={getLogoUrl(l)} value={l.value} />
                            <Box>
                        <MyIconButton
                          sx={{ padding: "1rem" }}
                          name="edit"
                          onClick={() => {
                            setSelectedLinkToDelete(l);
                            if (l.slug === "other") {
                              setAllowOtherField(true);
                            } else {
                              setAllowOtherField(false);
                            }
                            handleOpenUpdate();
                          }}
                        />
                        <MyIconButton
                          sx={{ padding: "1rem" }}
                          name="delete"
                          onClick={() => {
                            setSelectedLinkToDelete(l);
                            deleteLink(l.id)
                          }}
                        />
                      </Box>
                      </Box>
                    ))}
                        <IconButton
                                  sx={{
                                    margin: "0.5rem 1rem",
                                    bgcolor: `${colors.main_purple}`,
                                    "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                                  }}
                                  onClick={handleOpenAddLink}
                                >
                                  <AddRoundedIcon htmlColor="white" />
                        </IconButton>
    
                      {/* Modal add link */}
                      <Modal open={openAddLink} onClose={handleCloseAddLink}>
                    <Box sx={ModalStyle}>
                      <Typography fontSize="1.5rem" textAlign={'center'} fontWeight="600" mb=".5rem">
                        Ajouter un lien
                      </Typography>
                      <Typography variant="body2" fontsize={'8px'} textAlign={'center'} mb="1rem" color={colors.gray} sx={{whiteSpace:'normal'}}>Entrez les détails du nouveau lien à ajouter, en incluant une URL valide</Typography>
    
                      {/* form add link */}
                      <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                        {({ values, setFieldValue }) => (
                          <Form>
                            <Box className="field" sx={{ marginY: "2rem" }}>
                              <Field
                                fullWidth
                                name="link_id"
                                component={Autocomplete}
                                options={possibleLinksList}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    value = {};
                                    setFieldValue("title", "");
                                    setFieldValue("link_id", "");
                                  }
    
                                  setFieldValue("link_id", value ? value.id : "");
                                  setFieldValue("title", value ? value.title : "");
                                  setFieldValue("icon_tag", value ? value.icon_tag : "Site web");
    
                                  if (value.slug === "other") {
                                    setAllowOtherField(true);
                                    setFieldValue("title", value.title);
                                  } else {
                                    setAllowOtherField(false);
                                  }
                                }}
                                renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                    width:'100%',
                                    borderRadius: '8px',
                                    border: 'transparent'
                                  },
                                }}
                              />
                              <ErrorMessage name="link_id" component={MzErrorText} />
                            </Box>
    
                            {allowOtherField ? (
                              <Box className="field" sx={{ marginBottom: "1rem" }}>
                                <Field name="title" type="input" InputProps={{
                                          style: {
                                            backgroundColor: '#F7F7F7',
                                            width:'100%',
                                            borderRadius: '8px',
                                            border: 'transparent'
                                          },
                                        }} 
                                label="Titre" id="title" as={TextField} fullWidth size="small" />
                                <ErrorMessage name="title" component={MzErrorText} />
                              </Box>
                            ) : (
                              ""
                            )}
    
                            {allowOtherField ? (
                              <Box className="field" sx={{ marginBottom: "1rem" }}>
                                <Field
                                  name="icon"
                                  component={Autocomplete}
                                  options={iconOptions}
                                  getOptionLabel={(option) => option.label}
                                  onChange={(event, value) => {
                                    setFieldValue("icon", value ? value.value : "");
                                    setFieldValue("icon_tag", value ? value.value : "Site web"); 
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Sélectionner votre icône" fullWidth size="small" />
                                  )}
                                  renderOption={(props, option) => (
                                    <Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                                      {option.icon}
                                      <Typography sx={{ marginLeft: 1 }}>{option.label}</Typography>
                                    </Box>
                                  )}
                                />
                                <ErrorMessage name="icon" component={MzErrorText} />
                              </Box>
                            ) : (
                              ""
                            )}
    
                            <Box className="field" sx={{ marginBottom: "1rem" }}>
                              <Field name="value" type="input" InputProps={{
                                          style: {
                                            backgroundColor: '#F7F7F7',
                                            width:'100%',
                                            borderRadius: '8px',
                                            border: 'transparent'
                                          },
                                        }}
                              label="Url" id="value" as={TextField} fullWidth size="small" />
                              <ErrorMessage name="value" component={MzErrorText} />
                            </Box>
    
                            <Box display="flex" width={'100%'} flexDirection="row" gap="0.5rem" justifyContent={'stretch'}>
                              <Button onClick={handleCloseAddLink} variant="secondary"
                                sx={{paddingX: 5, paddingY:1, borderRadius:'8px', width:'100%'}}
                              >
                                Annuler
                              </Button>
                              <Button variant="form" type="submit"
                                sx={{paddingX: 5, paddingY:1, width:'100%'}}
                              >
                                Ajouter
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                      {/* end form add link */}
                    </Box>
                      </Modal>
    
                      {/* end modal update link  */}
                      </Box>
                )}
    
              </Box>

              <Box display="flex" justifyContent={{
                xs:"space-between",
                sm:"space-between",
                md:"flex-end",
                lg:"flex-end",
                xl:"flex-end"
              }} width="100%" 
                gap={2}
              >
                <Button variant="secondary" onClick={()=> goBack() } sx={{borderRadius:'8px', width:{
                  xs:'50%',
                  sm:'50%',
                  md:'20%',
                  lg:'20%',
                  xl:'20%'
                }}}>
                    Annuler
                  </Button>
                  <Button variant="form" onClick={()=> onSubmitUpdateUser(formikRef.current.values)} sx={{
                    width:{
                    xs:'50%',
                    sm:'50%',
                    md:'20%',
                    lg:'20%',
                    xl:'20%'
                  }}}>
                    Enregistrer
                  </Button>
              </Box>
              </Box>
            </Box>
          }

        </Box>
        </Box>
      }
    </Box>
        <ConfirmationModal title="Supprimer une carte" message="Êtes-vous sûr de bien vouloir supprimer cette profile ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => deleteUser()} />
        <ConfirmationModal title="Suspendre la carte" message="Êtes-vous sûr de bien vouloir suspendre cette profile  ?" acceptLabel="oui" refuseLabel="non" open={openSuspend} onClose={handleCloseSuspend} acceptMethod={updateCardStatus} acceptMethodParams="SUSPENDED" />
        <ConfirmationModal title="Activer la carte" message="Êtes-vous sûr de bien vouloir activer cette profile ?" acceptLabel="oui" refuseLabel="non" open={openActive} onClose={handleCloseActive} acceptMethod={updateCardStatus} acceptMethodParams="ACTIVE" />
        <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Box>
  );
}

export default ProfileDetailsProsumer;
