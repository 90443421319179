import React, { useEffect, useRef, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import Skeleton from '@mui/material/Skeleton';
import { Select, MenuItem, Button, Box, TextField, InputLabel, FormControl, Menu, IconButton, Modal, Autocomplete, Typography, TextareaAutosize, Tabs, Tab } from "@mui/material";
import { CalendarMonth, Call, Check, Close, CoPresent, ContactsOutlined, Edit, Email, LinkedIn, List, MoreHoriz, Upload, AirplayOutlined } from "@mui/icons-material";
import API from "../../api/Api_1_3";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ModalStyle } from "../../theme/ModalStyles";
import MzErrorText from "../../components/ui/MzErrorText";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from 'axios';
import { colors } from "../../theme/Colors";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { Setting4 } from 'iconsax-react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export const ContactsCard = () => {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [contactId, setContactId] = useState("");

  const userObj = JSON.parse(localStorage.getItem('user'))
  const [role, setRole] = useState('')

  const [tabValue, setTabValue] = useState('COLLECTED');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchUserRole = async () => {
    if(userObj?.access_token){
      try {
        const response = await API.get('/user/who-am-i', {
          headers: { "content-type": "application/json", authorization: `Bearer ${userObj?.access_token}` },
        });
        const userRole = response.data?.user?.role;
        setRole(userRole)
      } 
      catch (error) {
        console.error("Error fetching user role:", error);
      }
    }
  };

  const handleCloseReject = () => {
    setOpenReject(false);
    setContactId('');
  };

  const exportContacts = async () =>{
    try {
      const response = await API.get('/export-contacts', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob',
      });
  
      const blob = response.data;
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Contacts.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  }

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: '',
    phone: '',
    company: ''
  });

  const validationSchema = Yup.object({
    first_name: Yup.string(),
    last_name: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
  });

  const onSubmitSearchBdisplay = async (values, token) => {
    setLoading(true)
    try {
      Api.get("/get-contacts", {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${token}`,
        },
        params: {
          assignment_status: values.assignment_status,
          first_name: values.first_name !== '' ? values.first_name : null,
          last_name: values.last_name !== '' ? values.last_name : null,
          phone: values.phone !== '' ? values.phone : null,
          company_name: values.company !== '' ? values.company : null
        }
      })
        .then((response) => {
          setContacts(response.data?.contacts)
        })
    } 

    catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
      toast.error(errorMessage);
    }

    finally {
      setLoading(false)
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedContacts(contacts.map((contact) => contact.id));
    } else {
      setSelectedContacts([]);
    }
  };

  const handleAddComment = async (id, values) => {
    const { comment_type, comment } = values;
    let payload = {
      contact_id: id,
      comment: comment,
    };

    if (comment_type.type_designation !== 'Autre') {
      payload.comment_type_id = comment_type.id;
    }
    try {
      await API.post(
        "/contacts/comments",
        payload,
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts()
    } catch (error) {
      console.error("Error adding contact:", error);
      toast.error("Failed to add comment");
    }
    handleCloseReject();
  };

  const [vcfUrlFile, setVcfUrlFile] = useState(null);

  const handleShowMenu = (event, url_vcf_file) => {
    setAnchorEl(event.currentTarget);
    setVcfUrlFile(url_vcf_file);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setVcfUrlFile(null);
  };

  const handleNavLinkClick = () => {
    if (vcfUrlFile) {
      window.open(vcfUrlFile, "_blank");
    }
    handleCloseMenu();
  };

  const showMenu = Boolean(anchorEl);

  const pageNumbers = [3, 10, 50, 100];
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [page, setPage] = useState(1);


  const getContacts = () => {
    setLoading(true)
    const params = {
      perPage: perPage,
      page: page
    };
  
    if (role === "COLLABORATOR") {
      params.assignment_status = tabValue || 'COLLECTED'
    }
    Api.get(Urls.GET_CONTACTS,{
      headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      authorization: `Bearer ${access_token}`,
    },
    params: params
  })
    .then((response) => {
      const contacts = response.data?.contacts ?? [];
      setTotal(response.data.meta.total);
      setContacts(contacts);
    })
    .catch((error) => {
      console.log('Error fetching contacts:', error);
    })
    setLoading(false)
  };

  const [possibleActionsList,setList] = useState([
    {
      id: 9999,
      type_designation: 'Autre',
    },
  ])

  const getTypes = () => {
    API.get('/comment-types', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const apiData = response.data.data ?? [];
      // Filter out any item with id 9999 to avoid duplication
      const filteredData = apiData.filter(item => item.id !== 9999);
      // Combine the default value with the filtered API data
      setList([
        ...filteredData,
        {
          id: 9999,
          type_designation: 'Autre',
        },
      ]);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const [editedComments, setEditedComments] = useState({});

  const handleEditComment = (id, newComment) => {
    setEditedComments({ ...editedComments, [id]: newComment });
  };

  const handleEditCommentOnSubmit = async (id, newComment) => {
    const trimmed = newComment.trim()
    handleShowEdit(null)
    try {
      await API.patch(
        "/contacts/comments/" + id,
        {
          comment: trimmed,
        },
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts();
    } catch (error) {
      console.error("Error editing comment:", error);
      toast.error("Failed to edit comment");
    }
  };

  const [openComments,setOpenComments] = useState(false)
  const handleClose = () => setOpenComments(false)
  const handleOpen = () => setOpenComments(true)
  
  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleOpenComments = () => {
    setSelectedContactId(contactId);
  };

  const getIconByType = (type, color) => {
    switch (type) {
      case 'Call':
        return <Call sx={{color: color}} />;
      case 'Email':
        return <Email sx={{color: color}} />;
      case 'LinkedIn':
        return <LinkedIn sx={{color: color}} />;
      case 'RDV Présentation':
        return <CalendarMonth sx={{color: color}}/>;
      case 'Démo':
        return <CoPresent sx={{color: color}} />;
      default:
        return <DesignServicesIcon sx={{color: color}} />;
    }
  };

  const [editCommentId, setEditCommentId] = useState(null);

  const handleShowEdit = (commentId) =>{
    setEditCommentId(commentId);
  }
  
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editCommentId]);


  useEffect(()=>{
    fetchUserRole()
  },[])


  useEffect(() => { 
    if(role !== ''){
      getContacts();
    }
    getTypes();
  }, [tabValue, perPage, page, role]);


  const getSource = (contact) => {
    switch (true) {
      case contact.hasOwnProperty('bdisplay'):
        return 'Bdisplay';
      case contact.hasOwnProperty('user'):
        return 'Bcard';
      default:
        return null;
    }
  }

  const [viewMore, setViewMore] = useState(false)
  const [filter, setFilter] = useState(false)
  const [loading, setLoading] = useState(false)


  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mx={2} mb={2}>
        <Typography variant="h6">List des contacts</Typography>
        <Button onClick={()=> setFilter(!filter)} sx={{borderRadius:'18px', paddingX:'1rem', backgroundColor:'white', color: 'black', border:'1px solid' + colors.paperLightGrayBorder, fontWeight:500, textTransform: 'none'}} startIcon={<Setting4 size="22"/>}>
            Filtre
        </Button>
      </Box>
        {filter &&
        <Box display={'flex'} flexDirection={'column'} gap={2} p={1}>
          <Typography variant="h6" fontSize={17}>Filter</Typography>
          <Typography variant="body2" fontWeight={400} fontSize={15}>Utilisez un ou plusieurs champs pour affiner votre recherche</Typography>
          <Box className="filter" display="flex" width="100%">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitSearchBdisplay(values, access_token);
              }}
            >
              {({ values, setFieldValue }) => (
                <FormControl style={{width:'100%'}}>
                  <Box display="flex">

                    {/*Search fields */}
                    <Box display="flex" alignItems="stretch" sx={{ flexDirection: "column", width: "100%" }} marginBottom="1rem" gap={2}>
                      <Box flex={1} alignItems="center" mb>
                        <TextField
                          placeholder="Le nom et le prénom"
                          name="first_name"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              first_name: event.target.value,
                            }));
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 }, width:'100%' }}
                        />
                        <ErrorMessage name="first_name" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" mb>
                        <TextField
                          placeholder="Téléphone"
                          name="phone"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              phone: event.target.value,
                            }));
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 }, width:'100%' }}
                        />
                        <ErrorMessage name="phone" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" mb>
                        <TextField
                          placeholder="Entreprise"
                          name="company"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              company: event.target.value,
                            }));
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 }, width:'100%' }}
                        />
                        <ErrorMessage name="company" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" mb>
                        <FormControl fullWidth>
                          <Select
                            name="assignment_status"
                            value={initialValues.assignment_status || ''}
                            onChange={(event) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                assignment_status: event.target.value,
                              }));
                            }}
                            displayEmpty
                            sx={{ '::placeholder': { 'fontWeight': 600 } }}
                            size="small"
                          >
                            <MenuItem value="" disabled>
                              Statut d'assignation
                            </MenuItem>
                            <MenuItem value="COLLECTED">COLLECTED</MenuItem>
                            <MenuItem value="AFFECTED">AFFECTED</MenuItem>
                          </Select>
                          <ErrorMessage name="assignment_status" component={MzErrorText} />
                        </FormControl>
                      </Box>

                      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                        <Button variant="form" onClick={()=> onSubmitSearchBdisplay(values, access_token)} sx={{width:'100%'}}>
                          Filtrer
                        </Button>
                        <Button variant="secondary" sx={{borderRadius:'8px', border: '2px solid' + colors.light_purple, width:'100%'}} >
                          Annuler
                        </Button>
                      </Box>

                    </Box>
                  </Box>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
        }
      
      {/* Contact Card */}
      {loading ? (
        <Skeleton variant="rectangular" width={'100%'} height={118} sx={{borderRadius:'8px'}} />
      ) : (
        contacts.map((cntct, index) => (
          <Box display='flex' justifyContent='space-between' alignItems='flex-start' key={cntct.id} sx={{ border: '1px solid' + colors.tFieldGray, borderRadius: '18px', padding: 2 }} mb={3}>
            {/* contact infos */}
            <Box>
              <Typography variant="h6" mb>{cntct.first_name + " " + cntct.last_name}</Typography>
              <Typography variant="body2" color={colors.gray} fontWeight={400} mb>Enterprise: <span className='text-medium text-black'>{cntct?.company_name ?? '--'}</span></Typography>
              <Typography variant="body2" color={colors.gray} fontWeight={400} mb>Téléphone: <span className='text-medium text-black'>{cntct?.phone ?? '--'}</span></Typography>
              {/* View more */}
              {viewMore &&
                <Box>
                  <Typography variant="body2" color={colors.gray} fontWeight={400} mb>Email: <span className='text-medium text-black'>{cntct?.email ?? '--'}</span></Typography>
                  <Typography variant="body2" color={colors.gray} fontWeight={400} mb>Source: <span className='text-medium text-black'>{getSource(cntct)}</span></Typography>
                  <Typography variant="body2" color={colors.gray} fontWeight={400} mb mr>Type:
                    <>
                      <Select
                        id={`select-${cntct.id}`}
                        name="contact_type"
                        value={cntct.contact_type}
                        onChange={(event) => {
                          const { value } = event.target;
                          const updatedContacts = contacts.map((c) =>
                            c.id === cntct.id ? { ...c, contact_type: value } : c
                          );
                          setContacts(updatedContacts);

                          try {
                            API.patch(`/contacts/update-type/${cntct.id}`, { contact_type: value }, {
                              headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${access_token}`,
                              },
                            });
                            toast.success('Contact mis à jour');
                          } catch (error) {
                            toast.error('Une erreur est survenue');
                          }
                        }}
                        displayEmpty
                        sx={{ width: "150px", height: "25px", borderRadius: "15px", borderColor: "rgba(0, 0, 0, 0.23)", ml: .5 }}
                      >
                        <MenuItem value={"CLIENT"}>Client</MenuItem>
                        <MenuItem value={"HOT_LEAD"}>Hot Lead</MenuItem>
                        <MenuItem value={"CALLED_LEAD"}>Called Lead</MenuItem>
                        <MenuItem value={"LEAD"}>Lead</MenuItem>
                        <MenuItem value={"TO_BE_INITIATED"}>To Be Initiated</MenuItem>
                      </Select>
                    </>
                  </Typography>
                </Box>
              }
              <Box sx={{ transition: 'max-height 0.3s ease', overflow: 'hidden', maxHeight: viewMore ? '100px' : '0' }}>
                <Typography variant="body1" fontWeight={500} onClick={() => setViewMore(!viewMore)} sx={{ color: colors.light_purple, textDecoration: 'underline', cursor: 'pointer' }}>
                  {viewMore ? 'Voir moins' : 'Voir plus'}
                </Typography>
              </Box>
            </Box>

            {/* Action */}
            <Box>
              <IconButton onClick={(event) => {
                handleShowMenu(event, cntct.url_vcf_file);
                setContactId(cntct.id);
              }}>
                <MoreHoriz />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={showMenu}
                onClose={handleCloseMenu}
                PaperProps={{ elevation: 2, borderRadius: '18px' }}
              >
                <MenuItem onClick={() => handleNavLinkClick(cntct.url_vcf_file)} sx={{ display: 'flex', gap: 1 }}>
                  <ContactsOutlined sx={{ width: 25 }} />
                  Ajouter Contact
                </MenuItem>
                <MenuItem onClick={() => {
                  handleOpenComments(cntct.id);
                  handleOpen();
                }} sx={{ display: 'flex', gap: 1 }}>
                  <List />
                  List des Commentaires
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        ))
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 2 }}>
        <Box display="flex" gap={1} alignItems="stretch" >
          <Button
            variant="secondary"
            sx={{borderRadius:'18px', border:'1px solid' + colors.tFieldGray, width:'50px'}} 
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))} 
            disabled={page === 1}
          >
            <ArrowBackIosNewIcon fontSize={'24px'}/>
          </Button>
          <FormControl variant="outlined" size="small">
          <Select
            value={perPage}
            onChange={(e) => setPerPage(e.target.value)}
            sx={{ width: "80px", borderRadius:'18px' }}
          >
            {pageNumbers.map((number) => (
              <MenuItem key={number} value={number}>{number}</MenuItem>
            ))}
          </Select>
        </FormControl>
          <Button 
            variant="secondary"
            sx={{borderRadius:'18px', border:'1px solid' + colors.tFieldGray, width:'50px'}} 
            onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(total / perPage)))} 
            disabled={page === Math.ceil(total / perPage)}
          >
            <ArrowForwardIosIcon fontSize={'24px'}/>
          </Button>
        </Box>
      </Box>

      {openReject && (
        <Formik
          initialValues={{ comment: '', comment_type_id: null }}
          onSubmit={(values) => handleAddComment(contactId, values)}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Modal
              open={openReject}
              onClose={handleCloseReject}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <h2 className="text-2xl mb-4">Ajouter un Commentaire</h2>
                <Form>
                  <Box className="field" sx={{ marginBottom: "1rem" }}>
                    <Field
                      name="comment_type"
                      component={Autocomplete}
                      options={possibleActionsList}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) => option.type_designation}
                      onChange={(event, value) => {
                        setFieldValue("comment_type", value);
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Action" />}
                      size="small"
                      error={touched.comment_type_id}
                    />
                    <ErrorMessage name="comment_type" component={MzErrorText} />
                  </Box>
                  <Field
                    as={TextField}
                    label="Commentaire"
                    name="comment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={<ErrorMessage name="comment" component="div" className="error" />}
                  />
                  <div className="flex justify-end">
                    <Button variant="secondary" onClick={handleCloseReject} sx={{ mr: 2 }}>Annuler</Button>
                    <Button type="submit" variant="primary">Ajouter</Button>
                  </div>
                </Form>
              </Box>
            </Modal>
          )}
        </Formik>
      )}

      {
        openComments && (
          <Modal
          open={openComments}
          onClose={handleClose}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openComments}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" color="initial">Commentaires</Typography>
                  <Box onClick={handleClose}>
                    <Close/>
                  </Box>
                </Box>
              </MenuItem>
              {contacts.length !== 0 ? contacts.map((contact, index) => (
              <div key={index}>
                {selectedContactId === contact.id && contact.comments && contact.comments.map((comment, commentIndex) => (
                  <MenuItem key={commentIndex} sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'start', borderBottom: '.5px solid #232323', gap: '.5rem' }}>
                    <Box>
                      {getIconByType(comment.comment_type,colors.bd_Purple)}
                    </Box>
                    <Box width={'340px'} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                      <Typography variant="body1" color="initial" sx={{width:'100%', whiteSpace: 'break-spaces', fontSize: '.8rem', opacity: 0.7 }}>{comment.user} - {comment.is_updated ? comment.updated_at : comment.created_at} {comment.is_updated && '(Modifié)'}</Typography>
                      {editCommentId === comment.id 
                      ? 
                      <TextareaAutosize
                      rowsMin={3}
                      maxRows={5}
                      value={editedComments[comment.id] || comment.comment}
                      onChange={(e) => handleEditComment(comment.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault(); 
                          handleEditCommentOnSubmit(comment.id, editedComments[comment.id]);
                        }
                      }}
                      autoFocus
                      ref={textAreaRef}
                      style={{ width: '100%', fontSize: '1rem', padding:3, fontWeight: 450, backgroundColor:'transparent' }}
                      />
                      :
                       <Typography variant="body1" sx={{
                         width: '100%',
                         wordBreak: 'break-word',
                         whiteSpace: 'pre-wrap',
                         overflowWrap: 'break-word'
                       }}>
                        {editedComments[comment.id] || comment.comment}
                       </Typography>
                      }
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                      <Box display={'flex'} gap={1}>
                        {
                        editCommentId === comment.id &&
                        <>
                          <Check sx={{color: colors.mjGreenText}} onClick={() => handleEditCommentOnSubmit(comment.id, editedComments[comment.id])}/>
                          <Close sx={{color: colors.mj_red}} onClick={()=> handleShowEdit(null)}/>
                        </>
                        }
                      </Box>
                    </Box>
                    </Box>
                    <Box>
                      <Button variant={ editCommentId === comment.id ? "disabled" : "secondary"} onClick={() => handleShowEdit(comment.id)}><Edit/></Button>
                    </Box>
                  </MenuItem>
          ))}
              </div>
              )) : <div>Aucun contact ajoutée.</div>}


            </Menu>

          </Modal>
        )
      }
    </>
  );
}
