import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography, IconButton, FormControl, useMediaQuery, useTheme, Avatar, Modal, CircularProgress, Autocomplete, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import * as Yup from 'yup';
import api from '../../api/Api_1_3';
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MzErrorText from '../../components/ui/MzErrorText';
import Cropper from 'react-easy-crop';
import MzLabel from '../../components/ui/MzLabel';
import { colors } from '../../theme/Colors';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import getCroppedImg from "../../utils/cropImage";
import defaultLogo from "../../imgs/logo.png";
import userAvatar from '../../imgs/user.png'
import StepperComponent from '../../components/ui/Stepper';
import { ArrowBack, CloudUploadOutlined } from '@mui/icons-material';
import bdigitall from "../../imgs/BDIGITALL.svg";
import IconLink from '../../components/ui/IconLink';
import MyIconButton from '../../components/ui/MyIconButton';
import { ModalStyle } from '../../theme/ModalStyles';
import API from '../../api/Api';
import { Urls } from '../../api/Urls';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import LinkIcon from '@mui/icons-material/Link';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import EditIcon from '@mui/icons-material/Edit';
import WebIcon from '@mui/icons-material/Web';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import Dropzone from 'react-dropzone';
import AddActivity from '../../imgs/AddActivity.svg'
import CreateActivity from './CreateActivity';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Trash } from 'iconsax-react'

const FillProfileProsumer = ({id, prosumer}) => {
    const { user } = useAuthContext();
    const { pass_code, user_email} = prosumer

    // Cover Section

const [coverImage, setCoverImage] = useState();
const [activeStep, setActiveStep] = useState(0);
const [onCoverImage, setOnCoverImage] = useState(false);
const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
const [coverZoom, setCoverZoom] = useState(1);
const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
const [coverFormat, setCoverFormat] = useState("");
const [coverProgress, setCoverProgress] = useState(0);
const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
const coverImageProfile = useRef();

const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };


const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
  setCoverCroppedAreaPixels(croppedAreaPixels);
};

const onCoverCrop = async () => {
  const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
  setCoverImage(URL.createObjectURL(croppedImage));
  var file = new File([croppedImage], `${coverFormat.split("/")[1]}`, { type: [`${coverFormat}`] });
  if (file && croppedImage) {
    setOnCoverImage(false);
    formikRef.current.values.business_cover_picture = file;
    file = null;
  }
};


const theme = useTheme();
const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

const getAvatarStyle = () => {
  let width;

  if (isXsScreen || isSmScreen) {
    width = '100%';
  } else if (isMdScreen) {
    width = '45.3125rem'; // 725px
  } else if (isLgScreen) {
    width = '45.3125rem'; // 725px
  }

  return {
    width
  };
};


const formikRef = useRef();

// End: Cover Section


// Profile Picture Section
const [image, setImage] = useState(userAvatar);
const [format, setFormat] = useState("");
const [onImage, setOnImage] = useState(false);
const [crop, setCrop] = useState({ x: 0, y: 0 });
const [zoom, setZoom] = useState(1);
const [progress, setProgress] = useState(0);
const [croppedAreaPixels, setCroppedAreaPixels] = useState();

// Logo
const [logo, setLogo] = useState(defaultLogo);
const [formatLogo, setFormatLogo] = useState("");
const [onLogo, setOnLogo] = useState(false);
const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState();
const [cropLogo, setCropLogo] = useState({ x: 0, y: 0 });
const [dataImage, setDataImage] = useState('')
const [dataLogo, setDataLogo] = useState('')

const onCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};

const onCropCompleteLogo = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixelsLogo(croppedAreaPixels);
};
const onCrop = async () => {
  const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
  setImage(URL.createObjectURL(croppedImage));
  const pictureFile = new File(
    [croppedImage],
    `${Math.floor(Math.random() * 100000000000000)}.${
      format.split("/")[1]
    }`,
    { lastModified: 1534584790000, type: [`${format}`] }
  );
  if (pictureFile && croppedImage) {
    setOnImage(false);
    formikRef.current.values.profile_picture = pictureFile;
    setDataImage(pictureFile)
  }
};

const onCropLogo = async () => {
  const croppedImage = await getCroppedImg(logo, croppedAreaPixelsLogo, formatLogo);
  setLogo(URL.createObjectURL(croppedImage));
  const logoFile = new File(
    [croppedImage],
    `${
      format.split("/")[1]
    }`,
    { type: [`${format}`] }
  );
  if (logoFile && croppedImage) {
    setOnLogo(false);
    formikRef.current.values.business_logo = logoFile;
    setDataLogo(logoFile)
  }
};

const [onUploadImage, setOnUploadIage] = useState(false);
const [loading, setLoading] = useState(false)

const [formValues, setFormValues] = useState({
  last_name: '',
  first_name: '',
  phone_number: '',
  email: user_email,
  function: '',
  sex: '',
  business_commercial_name: '',
  business_logo: dataLogo ?? '',
  profile_picture: dataImage ?? ''
});

const validationSchema = Yup.object().shape({
  last_name: Yup.string().required('Nom est requis'),
  first_name: Yup.string().required('Prénom est requis'),
  phone_number: Yup.number().required('Téléphone est requis'),
  email: Yup.string().email('Email invalide').required('Email est requis'),
  sex: Yup.string().required('Sexe est requis'),
  function: Yup.string().required('Fonction est requis'),
  business_commercial_name: Yup.string().optional()
});

// END Profile Picture Section

// New state for document upload
const [document, setDocument] = useState(null);

// Dropzone for document upload
const onDrop = (acceptedFiles) => {
    setDocument(acceptedFiles[0]);
};

const handleSubmit = async (values) => {
        setLoading(true)
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            if (values[key]) { // Only append if the value is not empty
                formData.append(key, values[key]);
            }
        });
        if (dataImage) {
          formData.append('profile_picture', dataImage);
        }
        if (dataLogo) {
            formData.append('business_logo', dataLogo);
        }
        formData.append('pass_code', pass_code)
        formData.append('user_type_selected','PROSUMER')
        if(document){
          formData.append('document', document)
        }
        if(links.length !== 0){
          // Append each link object to the formData object
          links.forEach((link, index) => {
            formData.append(`links[${index}][link_id]`, link.link_id);
            formData.append(`links[${index}][title]`, link.title);
            formData.append(`links[${index}][value]`, link.value);
            if (link.icon_tag) {
              formData.append(`links[${index}][icon_tag]`, link.icon_tag);
            }
          });
        }
        
        // New code to append activities if not empty
        if (activities.length !== 0) {
          activities.forEach((activity, index) => {
            Object.keys(activity).forEach(field => {
              if (activity[field]) { 
                formData.append(`additional_activities[${index}][${field}]`, activity[field]);
            }
            });
            // New code to append links from each activity
            if (activity.links && activity.links.length !== 0) {
              activity.links.forEach((link, linkIndex) => {
                formData.append(`additional_activities[${index}][links][${linkIndex}][link_id]`, link.link_id);
                formData.append(`additional_activities[${index}][links][${linkIndex}][title]`, link.title);
                formData.append(`additional_activities[${index}][links][${linkIndex}][value]`, link.value);
                if (link.icon_tag) {
                  formData.append(`additional_activities[${index}][links][${linkIndex}][icon_tag]`, link.icon_tag);
                }
              });
              formData.delete(`additional_activities[${index}][links]`)
            }
          });
        }
        
        try {
           const res = await api.post('/prosumers/fill-empty-profile/'+ id + '?_method=PATCH', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            if(res.status === 200){
                toast.success('Profile Ajoutée avec succès')
                setTimeout(()=>{
                  window.location.reload()
                },1000)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
        finally{
        setLoading(false)
        }
};

const handleNext = async () => {
  // Validate required fields before moving to the next step
  const errors = await formikRef.current.validateForm(); // Ensure this is awaited
  formikRef.current.setTouched({
      last_name: true,
      first_name: true,
      phone_number: true,
      email: true,
      business_commercial_name: true,
      function: true,
      sex: true
      // Add other required fields here if necessary
  });

  if (Object.keys(errors).length === 0) {
      window.scrollTo(0, 0);
      setActiveStep((prevStep) => prevStep + 1); // Move to the next step
  } else {
      toast.error('Please fill in all required fields.'); // Show error message
  }
};

const handleBack = () => {
  setActiveStep((prevStep) => prevStep - 1); // Move to the previous step
};

// Links and documents


const [inLinks, setInLinks] = useState(true);

const [currentVariantLink, setCurrentVariantLink] = useState("primary");

const [links, setLinks] = useState([]);

const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();

const [openAddLink, setOpenAddLink] = useState(false);

const [openUpdate, setOpenUpdate] = useState(false);

const [openDelete, setOpenDelete] = useState(false);

const [possibleLinksList, setPossibleLinksList] = useState();

const [didAddLink, setDidAddLink] = useState(false);

const [allowOtherField, setAllowOtherField] = useState();


const initialValuesAddLink = {
  link_id: "",
  value: "",
  title: "",
  icon_tag: ""
};

//validation schema link fields
const validationSchemaAddLink = Yup.object({
  link_id: Yup.string().required("Ce champ est obligatoire"),
  value: Yup.string().required("Ce champ est obligatoire").url(),
  title: Yup.string().required("Ce champ est obligatoire"),
  icon_tag: Yup.string().optional(),
});

  //add link modal
  const handleOpenAddLink = () => {
    setOpenAddLink(true);
  };
  
  const handleCloseAddLink = () => setOpenAddLink(false);

  //delete link modal
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //update link modal
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  const getLogoUrl = (url) => {
    const link = possibleLinksList?.find((link) => link.title === url.title);
    return link ? link.url_logo : getIcon(url) ;
  };

  const getIcon = (url) => {
    return iconOptions.find(option => option.value === url.icon_tag)?.icon || null; // Return the icon if found, otherwise return null
  }

  const getLinks = () =>{
    API.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          window.location.reload()
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }

  const deleteLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
};


  const updateLink = (values) => {
    // Update the link in the state
    const updatedLinks = links.map((link) => {
      if (link.link_id === values.link_id) {
          return { ...link, title: values.title, value: values.value, icon_tag: values.icon };
      }
      return link;
    });
    setLinks(updatedLinks);
    handleCloseUpdate()
    toast.success('Link updated successfully');
  };

   //add link
   const addLink = async (values) => {
    const newLink = {
        link_id: values.link_id,
        title: values.title,
        value: values.value,
        icon_tag: values.icon
    };

    if (values.slug === "other") { 
        newLink.icon_tag = values.icon; 
    }

    setLinks([...links, newLink]);
    setDidAddLink(!didAddLink);
    handleCloseAddLink();
  };

  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(()=>{
    getLinks()
  },[])


  const iconOptions = [
    { value: 'DatasetLinked', label: 'Dataset Linked', icon: <DatasetLinkedIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Link', label: 'Link', icon: <LinkIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'LeakAdd', label: 'Leak Add', icon: <LeakAddIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'OpenInNew', label: 'Open In New', icon: <OpenInNewIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Launch', label: 'Launch', icon: <LaunchIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'OfflineShare', label: 'Offline Share', icon: <OfflineShareIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLinkRing', label: 'Phone Link Ring', icon: <PhonelinkRingIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLink', label: 'Phone Link', icon: <PhonelinkIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Web', label: 'Web', icon: <WebIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Pen', label: 'Pen', icon: <EditIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Card', label: 'Card', icon: <CreditCardIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Profile', label: 'Profile', icon: <AccountCircleIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
    { value: 'Page', label: 'Page', icon: <DescriptionIcon sx={{ color: colors.main_purple }} /> }, // Updated icon color
  ];

  const [hasActivity, setHasActivity] = useState(false)
  const [activities, setActivities] = useState([])

  const handleToggleActivity = () =>{
    setHasActivity(true)
  }

  const handleUpdateActivities = (newActivity) => {
    setActivities(prevActivities => [
        ...prevActivities, // Keep existing activities
        newActivity // Add the new activity object
    ]);
    setHasActivity(false);
};

const deleteActivity = (index) => {
    const updatedActivities = activities.filter((_, actIndex) => actIndex !== index);
    setActivities(updatedActivities);
    if (updatedActivities.length < 3) {
        setHasActivity(false); 
    }
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
  }));
};

useEffect(() => {
  if (hasActivity) {
    setFormValues(prevValues => ({
      ...prevValues,
      business_logo: prevValues.business_logo,
      profile_picture: prevValues.profile_picture
    }));
  }
}, [hasActivity]);


    return (
       <>
         {hasActivity === true 
        ?
          <CreateActivity onSubmit={(values)=> handleUpdateActivities(values)} onClose={()=> setHasActivity(false)}/>
        :
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Box sx={{ 
         display: 'flex', 
         flexDirection: 'column', 
         alignItems: 'center', 
         justifyContent: 'center', 
         width: { lg: '80%', xs: '90%' }, 
         padding: 4
     }}>
         <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
         {/* back action */}
         {activeStep > 0 && <Box display={'flex'} sx={{cursor:'pointer'}} alignSelf={'flex-start'} alignItems={'center'} gap={1} onClick={()=> handleBack()}>
           <ArrowBack/>
           <Typography variant="body2">Back</Typography>
         </Box>}

         {/* Header */}
         <Box
                   gap="0.5rem"
                   width="100%"
                   display="flex"
                   sx={{
                     alignItems: "center",
                     flexDirection: {
                       sm: "column-reverse",
                       md: "column-reverse",
                       lg: "row",
                       xs: "column-reverse",
                     },
                     justifyContent: {
                       sm: "center",
                       md: "center",
                       xs: "center",
                     },
                   }}
                 >
                   <Box
                     sx={{
                       marginTop: {
                         xs: "1rem",
                         sm: "1rem",
                         md: "1rem",
                         lg: "0",
                       },
                       marginBottom:'1rem',
                     }}
                   >
                     <img 
                       alt="icon"
                       src={bdigitall}
                       style={{
                         width: {
                           xs: '10px',
                           sm: '10px',
                           md: '30px',
                           lg: '30px',
                           xl: '30px'
                         }
                       }}
                     />
                   </Box>
         </Box>

         <Typography variant='h5' mb={1} fontSize={'26px'} fontWeight={500} textAlign={'center'}>Welcome to Bdigitall</Typography>
         <Typography variant='body2' fontSize={'12px'} fontWeight={500} color={colors.gray} mb={4} textAlign={'center'}>Create your profile in two steps. Connect and grow online!</Typography>
         <StepperComponent steps={['Account Details', 'Links and Documents','Additional Activities']} activeStep={activeStep}/>
         <Formik
             innerRef={formikRef}
             initialValues={formValues}
             validationSchema={validationSchema}
             onSubmit={handleSubmit}
             enableReinitialize
         >
             {({ isSubmitting, values, setFieldValue, errors }) => (
                 <Form>
                     {/* Assets and user data */}
                     { activeStep === 0 &&
                       <>
                     <Box display={'flex'} flexDirection={{
                         xs:'column',
                         sm:'column',
                         md:'column',
                         lg:'row',
                         xl:'row'
                     }}
                     alignItems={'start'}
                     justifyContent={'center'}
                     gap={2}
                     >

                     </Box>

                     {/* Customer details */}
                       <Grid container spacing={3} mt={2}>

                         <Grid item xs={12} sm={6}>
                         <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Nom</label>
                             <Field
                                name="last_name"
                                as={TextField}
                                placeholder="BdigitAll"
                                fullWidth
                                size="small"
                                InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                }}
                                onChange={handleChange}
                             />
                             <ErrorMessage name="last_name" component={MzErrorText} />
                         </Grid>

                         <Grid item xs={12} sm={6}>
                         <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Prénom</label>
                             <Field
                                 name="first_name"
                                 as={TextField}
                                 fullWidth
                                 placeholder="BdigitAll"
                                 size="small"
                                 InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                 }}
                                 onChange={handleChange}
                             />
                             <ErrorMessage name="first_name" component={MzErrorText} />
                         </Grid>

                         <Grid item xs={12} sm={6}>
                             <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Téléphone</label>
                             <Field
                                 name="phone_number"
                                 as={TextField}
                                 placeholder="213 799 00 00 00"
                                 fullWidth
                                 size="small"
                                 InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                 }}
                                 onChange={handleChange}
                             />
                             <ErrorMessage name="phone_number" component={MzErrorText} />
                         </Grid>

                         <Grid item xs={12} sm={6}>
                             <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Email</label>
                             <Field
                                 name="email"
                                 as={TextField}
                                 placeholder="example@example.com"
                                 type="email"
                                 fullWidth
                                 disabled
                                 size="small"
                                 InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                 }}
                                 onChange={handleChange}
                             />
                             <ErrorMessage name="email" component={MzErrorText} />
                         </Grid>

                         <Grid item xs={12} sm={6}>
                             <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Entreprise</label>
                             <Field
                                 name="business_commercial_name"
                                 as={TextField}
                                 placeholder="Entreprise"
                                 fullWidth
                                 size="small"
                                 InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                 }}
                                 onChange={handleChange}
                             />
                             <ErrorMessage name="business_commercial_name" component={MzErrorText} />
                         </Grid>

                         <Grid item xs={12} sm={6}>
                             <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Fonction</label>
                             <Field
                                 name="function"
                                 as={TextField}
                                 placeholder="Fonction"
                                 fullWidth
                                 size="small"
                                 InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'none'
                                   },
                                 }}
                                 onChange={handleChange}
                             />
                             <ErrorMessage name="function" component={MzErrorText} />
                         </Grid>

                         <Grid item xs={12} sm={6}>
                             <Box display={'flex'} flexDirection={'column'}>
                             <label style={{ fontSize: 15, color: colors.carbon_black, marginBottom: 1 }}>Sexe</label>
                             <Field name="sex" onChange={handleChange} >
                                 {({ field }) => (
                                     <FormControl component="fieldset">
                                         <RadioGroup {...field} row onChange={(e) => handleChange(e)}>
                                             <FormControlLabel value="MALE" control={<Radio />} label="Homme" />
                                             <FormControlLabel value="FEMALE" control={<Radio />} label="Femme" />
                                         </RadioGroup>
                                     </FormControl>
                                 )}
                             </Field>
                             </Box>
                             <ErrorMessage name="sex" component={MzErrorText} />
                         </Grid>
                       </Grid>
                       </>
                     }

                     {/* Links And Documents */}
                     { activeStep === 1 &&
                     <Box mt={3}>
                       <Box display={'flex'} alignItems={{
                         xs:'center',
                         sm:'center',
                         md:'center',
                         lg:'flex-start',
                         xl:'flex-start'
                       }} 
                       flexDirection={{
                         xs:'column',
                         sm:'column',
                         md:'column',
                         lg:'row',
                         xl:'row'
                       }}
                       justifyContent={'stretch'} gap={{
                         xs:0,
                         sm:0,
                         md:0,
                         lg:3,
                         xl:3
                       }}>
                        {/* Profile Picture */}
                         <Box display="flex" flexDirection="column"  >
                 <MzLabel>Photo de profile</MzLabel>
                 <Avatar
                   sx={{
                     position: "relative",
                     borderRadius: "20px",
                     width: "12rem",
                     height: "12rem",
                     border: "1px solid #C9C5D2",
                     marginBottom: "1rem",
                   }}
                   variant="rounded"
                 >
                   {image ? (
                     <img
                       src={image}
                       alt="The current file"
                       style={{ width: "13rem" }}
                     />
                   ) : (
                     <img
                       src={values.profile_picture}
                       alt="The current file"
                       style={{ width: "13rem" }}
                     />
                   )}
                   <IconButton
                     sx={{
                       zIndex: "33",
                       position: "absolute",
                       right: "0.5rem",
                       bottom: "0.5rem",
                       bgcolor: `${colors.light_purple}`,
                       "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                     }}
                     component="label"
                   >
                     <input
                       hidden
                       id="profile_picture"
                       name="profile_picture"
                       type="file"
                       onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setFieldValue("profile_picture", file); 
                          setOnImage(true)
                          setImage(URL.createObjectURL(file));
                          setFormat(file.type);
                        }
                      }}
                     />
                     <AddRoundedIcon htmlColor="white" />
                   </IconButton>
                 </Avatar>
                 <Modal
                   sx={{ backgroundColor: "#3f3f3f" }}
                   open={onImage}
                   onClose={() => {
                     setImage(null);
                     formikRef.current.values.profile_picture = "";
                     setOnImage(false);
                   }}
                 >
                   <Box height="100%" width="100%" margin="0 auto">
                     <Box
                       sx={{
                         height: "80%",
                         width: "80%",
                         display: "flex",
                         flexDirection: "column",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       <Box
                         height="100%"
                         width="100%"
                         display="flex"
                         flexDirection="column"
                       >
                         <Cropper
                           style={styleCropper}
                           image={image}
                           crop={crop}
                           zoom={zoom}
                           aspect={1 / 1}
                           onCropChange={setCrop}
                           onCropComplete={onCropComplete}
                           onZoomChange={setZoom}
                         />
                       </Box>
                     </Box>
                     <Box
                       position="absolute"
                       bottom="5%"
                       left="0"
                       right="0"
                       gap="1rem"
                       display="flex"
                       flexDirection="column"
                       alignItems="center"
                       justifyContent="center"
                     >
                       <MzErrorText>
                         {errors.profile_picture ? errors.profile_picture : null}
                       </MzErrorText>
                       <Box
                         width="fit-content"
                         display="flex"
                         flexDirection="row"
                         gap="1rem"
                         alignItems="center"
                       >
                         <Button
                           disabled={errors?.profile_picture ? true : false}
                           onClick={onCrop}
                           variant="primary"
                         >
                           Confirmer
                         </Button>
                         <Button
                           variant="primary"
                           onClick={() => {
                             setImage(null);
                             formikRef.current.values.profile_picture = "";
                             setOnImage(false);
                           }}
                         >
                           Annuler
                         </Button>
                       </Box>
                       <Box
                         display="flex"
                         gap="0.5rem"
                         sx={{
                           flexDirection: {
                             xs: "column",
                             md: "row",
                             lg: "row",
                             sm: "row",
                           },
                           alignItems: "center",
                         }}
                       >
                         <Typography color="white">
                           Niveau de zoom :{" "}
                         </Typography>
                         <input
                           type="range"
                           min={1}
                           max={3}
                           step={0.1}
                           value={zoom}
                           onInput={(e) => setZoom(e.target.value)}
                         />
                       </Box>
                     </Box>
                   </Box>
                 </Modal>
                 <ErrorMessage name="profile_picture" component={MzErrorText} />
                 <Box
                   display="flex"
                   flexDirection="column"
                   alignItems="center"
                   gap="0.5rem"
                   sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                 >
                   <Box>
                     {onUploadImage && (
                       <Box display="flex" alignItems="center" gap="1rem">
                         <CircularProgress size={30} />
                         <Typography width="20px" color={colors.main_purple}>
                           {progress ? progress + "%" : null}
                         </Typography>
                       </Box>
                     )}
                   </Box>
                 </Box>
                         </Box>

                        {/* Business logo */}
                          <Box display="flex" flexDirection="column"  >
                 <MzLabel>Logo</MzLabel>
                 <Avatar
                   sx={{
                     position: "relative",
                     borderRadius: "20px",
                     width: "12rem",
                     height: "12rem",
                     border: "1px solid #C9C5D2",
                     marginBottom: "1rem",
                   }}
                   variant="rounded"
                 >
                   {logo ? (
                     <img
                       src={logo}
                       alt="Logo du business"
                       style={{ width: "13rem" }}
                     />
                   ) : (
                     <img
                       src={values.business_logo}
                       alt="Logo du business"
                       style={{ width: "13rem" }}
                     />
                   )}
                   <IconButton
                     sx={{
                       zIndex: "33",
                       position: "absolute",
                       right: "0.5rem",
                       bottom: "0.5rem",
                       bgcolor: `${colors.light_purple}`,
                       "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                     }}
                     component="label"
                   >
                     <input
                       hidden
                       id="business_logo"
                       name="business_logo"
                       type="file"
                       onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setFieldValue("business_logo", file);
                          setOnLogo(true)
                          setLogo(URL.createObjectURL(file)); 
                          setFormatLogo(file.type); 
                        }
                      }}
                     />
                     <AddRoundedIcon htmlColor="white" />
                   </IconButton>
                 </Avatar>
                 <Modal
                   sx={{ backgroundColor: "#3f3f3f" }}
                   open={onLogo}
                   onClose={() => {
                     setLogo(null);
                     formikRef.current.values.business_logo = "";
                     setOnLogo(false);
                   }}
                 >
                   <Box height="100%" width="100%" margin="0 auto">
                     <Box
                       sx={{
                         height: "80%",
                         width: "80%",
                         display: "flex",
                         flexDirection: "column",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       <Box
                         height="100%"
                         width="100%"
                         display="flex"
                         flexDirection="column"
                       >
                         <Cropper
                           style={styleCropper}
                           image={logo}
                           crop={cropLogo}
                           zoom={zoom}
                           aspect={1 / 1}
                           onCropChange={setCropLogo}
                           onCropComplete={onCropCompleteLogo}
                           onZoomChange={setZoom}
                         />
                       </Box>
                     </Box>
                     <Box
                       position="absolute"
                       bottom="5%"
                       left="0"
                       right="0"
                       gap="1rem"
                       display="flex"
                       flexDirection="column"
                       alignItems="center"
                       justifyContent="center"
                     >
                       <MzErrorText>
                         {errors.business_logo ? errors.business_logo : null}
                       </MzErrorText>
                       <Box
                         width="fit-content"
                         display="flex"
                         flexDirection="row"
                         gap="1rem"
                         alignItems="center"
                       >
                         <Button
                           disabled={errors?.business_logo ? true : false}
                           onClick={onCropLogo}
                           variant="primary"
                         >
                           Confirmer
                         </Button>
                         <Button
                           variant="primary"
                           onClick={() => {
                             setLogo(null);
                             formikRef.current.values.business_logo = "";
                             setOnLogo(false);
                           }}
                         >
                           Annuler
                         </Button>
                       </Box>
                       <Box
                         display="flex"
                         gap="0.5rem"
                         sx={{
                           flexDirection: {
                             xs: "column",
                             md: "row",
                             lg: "row",
                             sm: "row",
                           },
                           alignItems: "center",
                         }}
                       >
                         <Typography color="white">
                           Niveau de zoom :{" "}
                         </Typography>
                         <input
                           type="range"
                           min={1}
                           max={3}
                           step={0.1}
                           value={zoom}
                           onInput={(e) => setZoom(e.target.value)}
                         />
                       </Box>
                     </Box>
                   </Box>
                 </Modal>
                 <ErrorMessage name="business_logo" component={MzErrorText} />
                 <Box
                   display="flex"
                   flexDirection="column"
                   alignItems="center"
                   gap="0.5rem"
                   sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                 >
                   <Box>
                     {onUploadImage && (
                       <Box display="flex" alignItems="center" gap="1rem">
                         <CircularProgress size={30} />
                         <Typography width="20px" color={colors.main_purple}>
                           {progress ? progress + "%" : null}
                         </Typography>
                       </Box>
                     )}
                   </Box>
                 </Box>
                          </Box>

                         {/* document */}
                         <Box>
                            <h6 className="text-start my-4 font-regular text-md">Ajouter votre brochure</h6>
                            <Box display={'flex'} flexDirection={'column'} alignItems='end' width={'100%'} my={2}>
                            <Dropzone onDrop={onDrop} accept=".pdf">
                                {({ getRootProps, getInputProps }) => (
                                    <Box 
                                        {...getRootProps()} 
                                        sx={{
                                            border: '2px dashed #C9C5D2',
                                            borderRadius: '8px',
                                            padding: '2rem',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: '#F7F7F7',
                                            minWidth:{
                                              xs:'300px',
                                              sm:'300px',
                                              md:'350px',
                                              lg:'450px',
                                              xl:'450px'
                                            }
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <CloudUploadOutlined sx={{ fontSize: '3rem', color: colors.main_purple }} />
                                        {!document && <Typography variant='body2'>Sélectionnez un fichier ou faites glisser et déposez-le ici.</Typography>}
                                        {document && <Typography variant='body2' mt={1} color={colors.light_purple} fontWeight={600}>{document.name}</Typography>}
                                        {!document && <Typography variant='body2' fontSize={'12px'} color={colors.gray}> PDF, taille de fichier de pas plus de 5 Mo.</Typography>}
                                    </Box>
                                )}
                            </Dropzone>
                            {document &&
                                <IconButton sx={{backgroundColor: colors.bd_suspended, 
                                          '&:hover':{
                                            backgroundColor: colors.bd_suspended_hover
                                          }}} 
                                          onClick={()=> setDocument(null)}
                                        >
                                          <Trash color="white"/>
                                </IconButton>
                              }
                            </Box>
                          </Box>
                       </Box>

                       {/* links */}
                       <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                         <h6 className="text-start my-4 font-regular text-md">Ajouter votre liens</h6>

                         {inLinks && (
                           <Box sx={{ width:'100%', backgroundColor: "#F5F5F5", borderRadius: ".5rem", padding: "0.5rem" }}>
                           {links?.map((l, index) => (
                           <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
               <IconLink title={l.title} icon={getLogoUrl(l)} value={l.value} />
               <Box>
                 <MyIconButton
                   sx={{ padding: "1rem" }}
                   name="edit"
                   onClick={() => {
                     setSelectedLinkToDelete(l);
                     if (l.slug === "other") {
                       setAllowOtherField(true);
                     } else {
                       setAllowOtherField(false);
                     }
                     handleOpenUpdate();
                   }}
                 />
                 <MyIconButton
                   sx={{ padding: "1rem" }}
                   name="delete"
                   onClick={() => {
                     setSelectedLinkToDelete(l);
                     deleteLink(index)
                   }}
                 />
               </Box>
                           </Box>
                           ))}

                           <IconButton
                             sx={{
                               margin: "0.5rem 1rem",
                               bgcolor: `${colors.main_purple}`,
                               "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                             }}
                             onClick={handleOpenAddLink}
                           >
                             <AddRoundedIcon htmlColor="white" />
                             </IconButton>

                         {/* Modal add link */}
                         <Modal open={openAddLink} onClose={handleCloseAddLink}>
             <Box sx={ModalStyle}>
               <Typography fontSize="1.5rem" textAlign={'center'} fontWeight="600" mb=".5rem">
                 Ajouter un lien
               </Typography>
               <Typography variant="body2" fontsize={'8px'} textAlign={'center'} mb="1rem" color={colors.gray} sx={{whiteSpace:'normal'}}>Entrez les détails du nouveau lien à ajouter, en incluant une URL valide</Typography>
               {/* form add link */}
               <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                 {({ values, setFieldValue }) => (
                   <Form>
                     <Box className="field" sx={{ marginY: "2rem" }}>
                       <Field
                         fullWidth
                         name="link_id"
                         component={Autocomplete}
                         options={possibleLinksList}
                         isOptionEqualToValue={(option, value) => option.id === value.id}
                         getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                         onChange={(event, value) => {
                           if (value === null) {
                             value = {};
                             setFieldValue("title", "");
                             setFieldValue("link_id", "");
                           }

                           setFieldValue("link_id", value ? value.id : "");
                           setFieldValue("title", value ? value.title : "");
                           setFieldValue("url_logo", value ? value.url_logo : "");

                           if (value.slug === "other") {
                             setAllowOtherField(true);
                             setFieldValue("title", value.title);
                           } else {
                             setAllowOtherField(false);
                           }
                         }}
                         renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                         size="small"
                         InputProps={{
                           style: {
                             backgroundColor: '#F5F5F5',
                             width:'100%',
                             borderRadius: '8px',
                             border: 'transparent'
                           },
                         }}
                       />
                       <ErrorMessage name="link_id" component={MzErrorText} />
                     </Box>

                     {allowOtherField ? (
                       <Box className="field" sx={{ marginBottom: "1rem" }}>
                         <Field name="title" type="input" InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                 }} 
                         label="Titre" id="title" as={TextField} fullWidth size="small" />
                         <ErrorMessage name="title" component={MzErrorText} />
                       </Box>
                     ) : (
                       ""
                     )}

                     {allowOtherField ? (
                       <Box className="field" sx={{ marginBottom: "1rem" }}>
                         <Field
                           name="icon"
                           component={Autocomplete}
                           options={iconOptions}
                           getOptionLabel={(option) => option.label}
                           onChange={(event, value) => {
                             setFieldValue("icon", value ? value.value : "");
                           }}
                           renderInput={(params) => (
                             <TextField {...params} label="Sélectionner votre icône" fullWidth size="small" />
                           )}
                           renderOption={(props, option) => (
                             <Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                               {option.icon}
                               <Typography sx={{ marginLeft: 1 }}>{option.label}</Typography>
                             </Box>
                           )}
                         />
                         <ErrorMessage name="icon" component={MzErrorText} />
                       </Box>
                     ) : (
                       ""
                     )}

                     <Box className="field" sx={{ marginBottom: "1rem" }}>
                       <Field name="value" type="input" InputProps={{
                                   style: {
                                     backgroundColor: '#F7F7F7',
                                     width:'100%',
                                     borderRadius: '8px',
                                     border: 'transparent'
                                   },
                                 }}
                       label="Url" id="value" as={TextField} fullWidth size="small" />
                       <ErrorMessage name="value" component={MzErrorText} />
                     </Box>

                     <Box display="flex" width={'100%'} flexDirection="row" gap="0.5rem" justifyContent={'stretch'}>
                       <Button onClick={handleCloseAddLink} variant="secondary"
                         sx={{paddingX: 5, paddingY:1, borderRadius:'8px', width:'100%'}}
                       >
                         Annuler
                       </Button>
                       <Button variant="form" type="submit"
                         sx={{paddingX: 5, paddingY:1, width:'100%'}}
                       >
                         Ajouter
                       </Button>
                     </Box>
                   </Form>
                 )}
               </Formik>
               {/* end form add link */}
             </Box>
                         </Modal>

                         {/* modal update link */}
                         <Modal open={openUpdate} onClose={handleCloseUpdate}>
             <Box sx={ModalStyle}>
             <Typography fontSize="1.5rem" textAlign={'center'} fontWeight="600" mb=".5rem">
                 Mettre a jour un lien
               </Typography>
               <Typography variant="body2" fontsize={'8px'} textAlign={'center'} mb="1rem" color={colors.gray} sx={{whiteSpace:'normal'}}>Entrez les détails du lien à modifier, en incluant une URL valide</Typography>

               {/* form update link */}
               <Formik initialValues={{ link_id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value ?? "", title: selectedLinkToDelete?.title ?? "" }} validationSchema={validationSchemaAddLink}>
                 {({ values, setFieldValue, errors }) => (
                   <Form>
                     <Box className="field" sx={{ marginBottom: "1rem" }}>
                       <Field
                         fullWidth
                         name="link_id"
                         component={Autocomplete}
                         defaultValue={{ id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value, title: selectedLinkToDelete?.title }}
                         options={possibleLinksList}
                         isOptionEqualToValue={(option, value) => option.id === value.id}
                         getOptionLabel={(pl) => (pl.title === "other" ? "Autre" : pl.title)}
                         onChange={(event, value) => {
                           if (value === null) {
                             value = { link_id: "" };
                             setFieldValue("title", "");
                             setFieldValue("link_id", "");
                           }

                           setFieldValue("link_id", value ? value.id : "");
                           setFieldValue("title", value ? value.title : "");

                           if (value.slug === "other") {
                             setAllowOtherField(true);
                             setFieldValue("title", value ? "Site web" : "");
                           } else {
                             setAllowOtherField(false);
                           }
                         }}
                         renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                         size="small"
                         InputProps={{
                           style: {
                             backgroundColor: '#F5F5F5',
                             width:'100%',
                             borderRadius: '8px',
                             border: 'transparent'
                           },
                         }}
                       />
                       <ErrorMessage name="link_id" component={MzErrorText} />
                     </Box>

                     {allowOtherField ? (
                       <Box className="field" sx={{ marginBottom: "1rem" }}>
                         <Field name="title" type="input"
                           InputProps={{
                             style: {
                               backgroundColor: '#F5F5F5',
                               width:'100%',
                               borderRadius: '8px',
                               border: 'transparent'
                             },
                           }}
                          label="Titre" id="title" as={TextField} fullWidth size="small" />
                         <ErrorMessage name="title" component={MzErrorText} />
                       </Box>
                     ) : (
                       ""
                     )}

                     <Box className="field" sx={{ marginBottom: "1rem" }}>
                       <Field name="value" type="input"
                         InputProps={{
                           style: {
                             backgroundColor: '#F5F5F5',
                             width:'100%',
                             borderRadius: '8px',
                             border: 'transparent'
                           },
                         }}
                        label="Url" id="value" as={TextField} fullWidth size="small" />
                       <ErrorMessage name="value" component={MzErrorText} />
                     </Box>

                     <Box display="flex" width={'100%'} flexDirection="row" gap="0.5rem" justifyContent={'stretch'}>
                       <Button onClick={handleCloseUpdate} variant="secondary" sx={{paddingX: 5, paddingY:1, borderRadius:'8px', width:'100%'}}>
                         Annuler
                       </Button>

                       <Button
                         variant="form"
                         onClick={() => {
                           if (isObjEmpty(errors)) {
                             updateLink(values)
                           }
                         }}
                         sx={{paddingX: 5, paddingY:1, width:'100%'}}
                       >
                         Mettre à jour
                       </Button>
                     </Box>
                   </Form>
                 )}
               </Formik>
               {/* end form update link */}
             </Box>
                         </Modal>
                         {/* end modal update link  */}
                           </Box>
                         )}

                       </Box>
                     </Box>
                     }
                     
                     {activeStep === 2 &&
                       <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={2} mt={4}>
                        {activities?.length > 0 && <Typography variant="body1" alignSelf={'start'}>Your Activities</Typography>}
                         {activities?.length > 0 ? (
                           activities.map((activity, index) => (
                             <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width="100%" gap={1} sx={{border: '.5px solid' + colors.gray, borderRadius:'8px', paddingX:2, paddingY:1}}>
                              <Box display={'flex'} alignItems={'center'} gap={2} >
                                {/* picture */}
                                <Box>
                                  <Avatar
                                    src={activity?.business_logo ? URL.createObjectURL(activity.business_logo) : <AccountCircleIcon />}
                                    sx={{ width: 60, height: 60, borderRadius: '8px' }}
                                    alt="activity_picture"
                                  />
                                </Box>
                                {/* details */}
                                 <Box>
                                    <Typography variant='body2' fontSize={'16px'} mb={'5px'}>{activity.business_commercial_name}</Typography>
                                    <Typography variant='body2' fontSize={'12px'} mb={'2px'} color={colors.gray}>{activity.email}</Typography>
                                    <Typography variant='body2' fontSize={'12px'} color={colors.gray}>{activity.phone_number}</Typography>
                                 </Box>
                              </Box>
                               <span onClick={() => deleteActivity(index)} sx={{'cursor':'pointer'}}><DeleteOutlineIcon/></span>
                             </Box>
                           ))
                         ) : (
                           <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                             <img src={AddActivity} alt="activity_form" width={300} height={300} />  
                             <Box display={'flex'} flexDirection={'column'} >
                               <Typography variant='h5' mb={1} fontSize={'22px'} fontWeight={500} textAlign={'center'}>Create Another Public Profile (Optional)</Typography>
                               <Typography variant='body2' fontSize={'12px'} fontWeight={500} color={colors.gray} mb={4} textAlign={'center'}>You can create another public profile to manage different brands or projects if needed.</Typography>
                             </Box>
                           </Box>
                         )}
                       </Box>
                     }
                     {/* Create Action */}
                     <Box display={'flex'} mt={2} justifyContent={activeStep == 2 ? 'center' : 'flex-end'} alignItems={'center'} gap={2}>
             
                       {activeStep === 2 && 
                         <Box display={'flex'} flexDirection={'column'} alignSelf={'center'} justifyContent={'center'} gap={2}>
                         {activities?.length < 3 && 
                          <Button
                             variant="form"
                             onClick={()=> handleToggleActivity()}
                             sx={{paddingX: 5, paddingY:1}}
                             
                          >
                             Create Another Activity
                           </Button>
                          }
                           <Button
                             variant={activities?.length === 3 ? 'form' : "secondary"}
                             type="submit"
                             sx={{paddingX: 5, paddingY:1, borderRadius:'8px'}}
                           >
                             {activities?.length === 3 ? (loading ? <CircularProgress size={30} sx={{color: 'white'}}/> : 'Validate your Account') : (loading ? <CircularProgress size={30} sx={{color: activities?.length === 3 ? 'white' : colors.main_purple}}/> : 'Skip and validate')}
                         </Button>
                         </Box>
                       }
                       {activeStep < 2 && 
                         <Button
                           variant="form"
                           onClick={() => handleNext()}
                           sx={{paddingX: 5, paddingY:1}}
                         >
                           Suivant
                         </Button>
                       }
                     </Box>
                 </Form>
             )}
         </Formik>

       </Box>
          </Box>}
       </>
    );
};

export default FillProfileProsumer;