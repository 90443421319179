import { colors } from "../../theme/Colors";

export const DataGridHeaderStyle = {
  marginTop: "1rem",
  height: "70vh",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: colors.smokeGray,
    color: "black"
  },
  "& .MuiSvgIcon-fontSizeSmall": {
    color: "black",
  },
};
