import React, { useEffect, useRef, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import { CircularProgress, Select, MenuItem, Button, Box, TextField, FormControl, Checkbox, Menu, IconButton, Modal, Autocomplete, Typography, TextareaAutosize, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { Add, AirplayOutlined, ArrowBack, ArrowBackIos, ArrowForwardIos, CalendarMonth, Call, CardGiftcard, Check, Close, CoPresent, Comment, ContactsOutlined, Download, Edit, Email, LinkedIn, List, MoreHoriz, Upload } from "@mui/icons-material";
import API from "../../api/Api_1_3";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ModalStyle } from "../../theme/ModalStyles";
import MzErrorText from "../../components/ui/MzErrorText";
import { colors } from "../../theme/Colors";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { Navigate, useNavigate } from "react-router-dom";
import { StripedDataGrid } from '../../components/ui/StripedGrid'; // Import StripedDataGrid
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from 'axios';



function BCRM() {
  const navigate = useNavigate()
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedContactType, setSelectedContactType] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [contactId, setContactId] = useState("");
  const [selectedType, setSelectedType] = useState('')
  const [companyID,setCompanyID] = useState('')
  const [users, setUsers] = useState([]); // State to hold users
  const pageNumbers = [10, 25, 50, 100];
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [isLoading, setIsLoading] = useState(false)

  // Fetch users on component mount
  useEffect(() => {
  const fetchUsers = async () => {
    setIsLoading(true)
    try {
      const response = await Api.get('/users', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`,
        },
      });
      setUsers(response.data?.users); // Assuming response.data is the list of users
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setIsLoading(false)
  };

  fetchUsers();
  }, []);

  const handleOpenReject = (id) => {
    setContactId(contactId);
    handleCloseMenu();
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
    setContactId('');
  };

  const exportContacts = async () =>{
    try {
      const response = await API.get('/export-contacts', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob', // Ensure Axios treats the response as a blob
      });
  
      const blob = response.data; // Use response.data to access the blob
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Contacts.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  }

  const handleCheckboxChange = (event, contactId) => {
    if (event.target.checked) {
      setSelectedContacts([...selectedContacts, contactId]);
    } else {
      setSelectedContacts(selectedContacts.filter(id => id !== contactId));
    }
  };

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: '',
    phone: '',
    contact_origin: '',
    collector_name: '',
    bdisplay_name: '',
    service_name: '',
    email: '',
    function: ''
  });

  const validationSchema = Yup.object({
    first_name: Yup.string(),
    last_name: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
    contact_origin: Yup.string(),
    collector_name: Yup.string(),
    bdisplay_name: Yup.string(),
    service_name: Yup.string(),
    email: Yup.string(),
    function: Yup.string()
  });

  const validationSchemaUser = Yup.object({
    type: Yup.string(),
    send_email: Yup.boolean(),
  });

  const onSubmitSearchBdisplay = async (values, token) => {
    Api.get("/get-contacts", {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: {
        contact_group: 'GROUP_COMMERCIAL',
        first_name: values.first_name !== '' ? values.first_name : null,
        last_name: values.last_name !== '' ? values.last_name : null,
        phone: values.phone !== '' ? values.phone : null,
        contact_origin: values.contact_origin !== '' ? values.contact_origin : null,
        collector_name: values.collector_name !== '' ? values.collector_name : null,
        bdisplay_name: values.bdisplay_name !== '' ? values.bdisplay_name : null,
        service_name: values.service_name !== '' ? values.service_name : null,
        email: values.email !== '' ? values.email : null,
        function: values.function !== '' ? values.function : null
      }
    })
      .then((response) => {
        setContacts(response.data?.contacts)
      })
  };

  const handleAddComment = async (id, values) => {
    const { comment_type, comment } = values;
    let payload = {
      contact_id: id,
      comment: comment,
    };

    if (comment_type.type_designation !== 'Autre') {
      payload.comment_type_id = comment_type.id;
    }
    try {
      await API.post(
        "/contacts/comments",
        payload,
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts()
    } catch (error) {
      console.error("Error adding contact:", error);
      toast.error("Failed to add comment");
    }
    handleCloseReject();
  };


  const [vcfUrlFile, setVcfUrlFile] = useState(null);

  const handleShowMenu = (event, url_vcf_file) => {
    setAnchorEl(event.currentTarget);
    setVcfUrlFile(url_vcf_file);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setVcfUrlFile(null);
  };

  const handleNavLinkClick = () => {
    if (vcfUrlFile) {
      window.open(vcfUrlFile, "_blank");
    }
    handleCloseMenu();
  };

  const showMenu = Boolean(anchorEl);

  const getContacts = () => {
    Api.get(`/get-contacts`, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params:{
        page: currentPage, // Use currentPage for pagination
        perPage: perPage,
        contact_group: 'GROUP_COMMERCIAL'
      }
    })
    .then((response) => {
      setContacts(response.data.contacts ?? []);
      setTotalPages(response.data.meta.total); // Ensure this is set correctly
    })
    .catch((error) => {
      console.log('Error fetching contacts:', error);
    });
  };
  

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getTypes = () => {
    API.get('/comment-types', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const apiData = response.data.data ?? [];
      // Filter out any item with id 9999 to avoid duplication
      const filteredData = apiData.filter(item => item.id !== 9999);
      // Combine the default value with the filtered API data
      setList([
        ...filteredData,
        {
          id: 9999,
          type_designation: 'Autre',
        },
      ]);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const [possibleActionsList,setList] = useState([
    {
      id: 9999,
      type_designation: 'Autre',
    },
  ])

  const [editedComments, setEditedComments] = useState({});

  const handleEditComment = (id, newComment) => {
    setEditedComments({ ...editedComments, [id]: newComment });
  };

  const handleEditCommentOnSubmit = async (id, newComment) => {
    const trimmed = newComment.trim()
    handleShowEdit(null)
    try {
      await API.patch(
        "/contacts/comments/" + id,
        {
          comment: trimmed,
        },
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts();
    } catch (error) {
      console.error("Error editing comment:", error);
      toast.error("Failed to edit comment");
    }
  };

  const [openComments,setOpenComments] = useState(false)
  const handleClose = () => setOpenComments(false)
  const handleOpen = () => setOpenComments(true)
  
  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleOpenComments = () => {
    setSelectedContactId(contactId);
  };

  const getIconByType = (type, color) => {
    switch (type) {
      case 'Call':
        return <Call sx={{color: color}} />;
      case 'Email':
        return <Email sx={{color: color}} />;
      case 'LinkedIn':
        return <LinkedIn sx={{color: color}} />;
      case 'RDV Présentation':
        return <CalendarMonth sx={{color: color}}/>;
      case 'Démo':
        return <CoPresent sx={{color: color}} />;
      default:
        return <DesignServicesIcon sx={{color: color}} />;
    }
  };

  const [editCommentId, setEditCommentId] = useState(null);

  const handleShowEdit = (commentId) =>{
    setEditCommentId(commentId);
  }

  const downloadZip = async () => {
    const zip = new JSZip();
    for (const contactId of selectedContacts) {
      const contact = contacts.find(contact => contact.id === contactId);
      if (contact && contact.url_vcf_file) {
        try {
          const response = await axios.get(contact.url_vcf_file, {
            responseType: 'blob',
          });
          const blob = response.data;
          zip.file(`${contact.first_name}_${contact.last_name}.vcf`, blob);
        } catch (error) {
          toast.error(`Failed to fetch VCF for contact ${contact.first_name} ${contact.last_name}`);
          console.error(`Error fetching the VCF file for contact ${contactId}:`, error);
        }
      }
    }
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "contacts.zip");
    });
  };

  const [currentPageSize, setCurrentPageSize] = useState(5); // Add this state

  
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editCommentId]);

  const columns = [ // Define columns based on the current table
    {
      field: 'fullName',
      headerName: 'Nom Complet',
      width: 200,
      renderCell: (params) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
              {params.row.hasOwnProperty('bdisplay') ? <AirplayOutlined sx={{ color: colors.bd_Purple }} /> : <BadgeOutlinedIcon sx={{ color: colors.bd_Purple }} />} {/* Source icon */}
              <Tooltip title={params.row.hasOwnProperty('bdisplay') ? 'Source: Bdisplay' : 'Source: Bcard'} arrow> {/* Tooltip for source */}
                  <span style={{ marginLeft: 10 }}>
                      {`${params.row.first_name || ''} ${params.row.last_name || ''}`}
                  </span>
              </Tooltip>
          </div>
      )
    },
    { field: 'phone', headerName: 'Mobile', width: 150, valueGetter: (params) => params.row.phone || '---' },

    { field: 'company', headerName: 'Entreprise', width: 200, valueGetter: (params) => params.row.company_name || '---' },
    { field: 'owner', headerName: 'Owner', width: 200, valueGetter: (params) => (
      (params.row.hasOwnProperty('bdisplay') && params.row.bdisplay.title) || 
      (params.row.hasOwnProperty('user') && params.row.user.first_name) ? 
      (params.row.bdisplay?.title || (params.row.user.first_name + ' ' + params.row.user.last_name || '---')) : '---'
    )
    },
    { field: 'responsable', headerName: 'Responsable', width: 250, renderCell: (params) => (
    <Autocomplete
      size="small"
      sx={{width:'100%'}}
      options={users}
      getOptionLabel={(option) => option.last_name}
      value={users.find(user => user.id === params.row.responsable?.id) || null} // Set default value based on responsable
      onChange={(event, value) => {
        const responsableId = value ? value.id : ""; // Get selected user's ID
        API.patch(`contacts/affect-to-responsable/${params.row.id}/${responsableId}`, {}, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        })
        .then(() => {
          toast.success('Responsable affecté avec succès');
          getContacts()
        })
        .catch((error) => {
          toast.error('Erreur lors de l\'affectation du responsable');
          console.error("Error affecting responsable:", error);
        });
      }}
      renderInput={(params) => <TextField {...params} label="Select Responsable" />}
    />
    )},
    { field: 'type', headerName: 'Type', width: 200, renderCell: (params) => (
    <div>
                  <Select
                    id={`select-${params.row.id}`}
                    name="contact_type"
                    value={params.row.contact_type}
                    onChange={(event) => {
                      const { value } = event.target;
                      const updatedContacts = contacts.map((c) =>
                        c.id === params.row.id ? { ...c, contact_type: value } : c
                      );
                      setContacts(updatedContacts);

                      try {
                        API.patch(`/contacts/update-type/${params.row.id}`, { contact_type: value }, {
                          headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${access_token}`,
                          },
                        });
                        toast.success('Contact mis à jour');
                      } catch (error) {
                        toast.error('Une erreur est survenue');
                      }
                    }}
                    displayEmpty
                    sx={{ width: "150px", height: "30px", borderRadius: "15px", borderColor: "rgba(0, 0, 0, 0.23)" }}
                  >
                    <MenuItem value={"CLIENT"}>Client</MenuItem>
                    <MenuItem value={"HOT_LEAD"}>Hot Lead</MenuItem>
                    <MenuItem value={"CALLED_LEAD"}>Called Lead</MenuItem>
                    <MenuItem value={"LEAD"}>Lead</MenuItem>
                    <MenuItem value={"TO_BE_INITIATED"}>To Be Initiated</MenuItem>
                  </Select>
    </div>
    )},

    { field: 'actions', headerName: 'Action', width: 150, renderCell: (params) => (
        <div>
          <IconButton onClick={(event) => {
            handleShowMenu(event, params.row.url_vcf_file);
            setContactId(params.row.id);
            setCompanyID(params.row.company_id);
          }}>
            <MoreHoriz />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={showMenu}
            onClose={handleCloseMenu}
            PaperProps={{ elevation: 2 }}
          >
            <MenuItem onClick={() => handleNavLinkClick(params.row.url_vcf_file)} sx={{display:'flex', gap:1}}>
              <ContactsOutlined sx={{width:25}}/>
              Ajouter Contact
            </MenuItem>
            <MenuItem onClick={() => handleOpenReject(params.row.id)} sx={{display:'flex', gap:1}}>
              <Add/>
                Ajouter Commentaire
              </MenuItem>
            <MenuItem onClick={() => {
              handleOpenComments(params.row.id);
              handleOpen();
            }} sx={{display:'flex', gap:1}}>
              <List/>
              List des Commentaires
            </MenuItem>
          </Menu>
        </div>
      )
    }
  ];

    useEffect(() => {
    getContacts(currentPage);
    getTypes()
  }, [currentPage,selectedContactId,perPage]);


  return (
    <div>
      <ToastContainer
        style={{ top: "5rem", width: "300px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex items-start justify-between mb-4">
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <h1 className="text-2xl font-bold">Liste Des Contacts B-CRM</h1>
          <Box className="filter" display="flex" flexDirection="row">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitSearchBdisplay(initialValues, access_token);
              }}
            >
              {({ values, setFieldValue }) => (
                <FormControl>
                  <Box display="flex">
                    {/*Search fields */}
                    <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                      <Box display="flex" flexDirection="column" marginBottom="1rem">
                          <Grid container spacing={2}>
                            {/* Existing Filter: Full Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Prénom"
                                name="first_name"
                                value={initialValues.first_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    first_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Phone */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Téléphone"
                                name="phone"
                                value={initialValues.phone} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    phone: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Contact Origin */}
                            <Grid item xs={6} sm={2}>
                              <FormControl fullWidth>
                                <Select
                                  name="contact_origin"
                                  value={initialValues.contact_origin} // Set value from state
                                  onChange={(event) => {
                                    setInitialValues((prevValues) => ({
                                      ...prevValues,
                                      contact_origin: event.target.value,
                                    }));
                                  }}
                                  size="small"
                                >
                                  <MenuItem value="" disabled>
                                    Selectionner l'origin
                                  </MenuItem>
                                  <MenuItem value="BDISPLAY">BDISPLAY</MenuItem>
                                  <MenuItem value="BCARD">BCARD</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* Existing Filter: Collector Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Collector Name"
                                name="collector_name"
                                value={initialValues.collector_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    collector_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Bdisplay Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Bdisplay Name"
                                name="bdisplay_name"
                                value={initialValues.bdisplay_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    bdisplay_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Service Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Service Name"
                                name="service_name"
                                value={initialValues.service_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    service_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Email */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Email"
                                name="email"
                                value={initialValues.email} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    email: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Function */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Function"
                                name="function"
                                value={initialValues.function} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    function: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            <Grid item xl={3} lg={3} sx={6} sm={2}>
                                <Box display={'flex'} gap={1}>
                                  <Button variant="primary" onClick={() => onSubmitSearchBdisplay(initialValues, access_token)}>Filtrer</Button>
                                  <Button variant="secondary" onClick={() => {
                                    setInitialValues({ first_name: "", last_name: "", phone: "", contact_origin: "", collector_name: "", bdisplay_name: "", service_name: "", email: "", function: "" })
                                    onSubmitSearchBdisplay(values,access_token)
                                  }}>Réinitialiser</Button>                        
                                </Box>
                            </Grid>

                          </Grid>
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
      <Box display={'flex'} justifyContent={'flex-end'}>
        {user.user.role === 'CLIENT_ADMIN' && <Button startIcon={<Upload/>} variant="primary" sx={{ fontWeight: 500, alignSelf:'flex-end' }} onClick={exportContacts}>Exporter</Button>}
      </Box>
      <Box style={DataGridHeaderStyle}>
        <StripedDataGrid
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          rows={contacts}
          columns={columns}
          rowCount={totalPages}
          rowsPerPageOptions={pageNumbers}
          pageSize={perPage}
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
          }}
          onPageChange={(newPage) => handlePageChange(newPage + 1)}
          paginationMode="server"
          autoHeight
          getRowId={(row) => row.id}
          checkboxSelection
          loading={isLoading}
          onSelectionModelChange={handleCheckboxChange}
        />
      </Box>
      {/* <div>
        <Button startIcon={<Download/>} variant="contained" onClick={downloadZip} disabled={selectedContacts.length === 0}>
          Télécharger les contacts séléctionnés (ZIP)
        </Button>
      </div> */}
      {openReject && (
        <Formik
          initialValues={{ comment: '', comment_type_id: null }}
          onSubmit={(values) => handleAddComment(contactId, values)}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Modal
              open={openReject}
              onClose={handleCloseReject}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <h2 className="text-2xl mb-4">Ajouter un Commentaire</h2>
                <Form>
                  <Box className="field" sx={{ marginBottom: "1rem" }}>
                    <Field
                      name="comment_type"
                      component={Autocomplete}
                      options={possibleActionsList}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) => option.type_designation}
                      onChange={(event, value) => {
                        setFieldValue("comment_type", value);
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Action" />}
                      renderOption={(props, option) => (
                        <li style={{display:'flex', gap:10}} {...props}>
                          {getIconByType(option.type_designation, colors.bd_Purple)} {/* Render icon here */}
                          {option.type_designation}
                        </li>
                      )}
                      size="small"
                      error={touched.comment_type_id}
                    />
                    <ErrorMessage name="comment_type" component={MzErrorText} />
                  </Box>
                  <Field
                    as={TextField}
                    label="Commentaire"
                    name="comment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={<ErrorMessage name="comment" component="div" className="error" />}
                  />
                  <div className="flex justify-end">
                    <Button variant="secondary" onClick={handleCloseReject} sx={{ mr: 2 }}>Annuler</Button>
                    <Button type="submit" variant="primary">Ajouter</Button>
                  </div>
                </Form>
              </Box>
            </Modal>
          )}
        </Formik>
      )}

      {
        openComments && (
          <Modal
          open={openComments}
          onClose={handleClose}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openComments}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" color="initial">Commentaires</Typography>
                  <Box onClick={handleClose}>
                    <Close/>
                  </Box>
                </Box>
              </MenuItem>
              {contacts.length !== 0 ? contacts.map((contact, index) => (
              <div key={index}>
                {selectedContactId === contact.id && contact.comments && contact.comments.map((comment, commentIndex) => (
                  <MenuItem key={commentIndex} sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'center', borderBottom: '.5px solid #232323', gap: '.5rem' }}>
                    <Box>
                      {getIconByType(comment.comment_type,colors.bd_Purple)}
                    </Box>
                    <Box width={500} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                      {editCommentId === comment.id ? 
                      <TextareaAutosize
                      rowsMin={3}
                      maxRows={5}
                      value={editedComments[comment.id] || comment.comment}
                      onChange={(e) => handleEditComment(comment.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault(); 
                          handleEditCommentOnSubmit(comment.id, editedComments[comment.id]);
                        }
                      }}
                      autoFocus
                      ref={textAreaRef}
                      style={{ width: '100%', fontSize: '1rem', padding:3, fontWeight: 450, backgroundColor:'transparent' }}
                    />
                      : <Typography variant="body1">{editedComments[comment.id] || comment.comment}</Typography>
                    }
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                      <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize: '.9rem', opacity: 0.7 }}>{comment.user} - {comment.is_updated ? comment.updated_at : comment.created_at} {comment.is_updated && '(Modifié)'}</Typography>
                      <Box display={'flex'} gap={1}>
                        {
                        editCommentId === comment.id &&
                        <>
                          <Check sx={{color: colors.mjGreenText}} onClick={() => handleEditCommentOnSubmit(comment.id, editedComments[comment.id])}/>
                          <Close sx={{color: colors.mj_red}} onClick={()=> handleShowEdit(null)}/>
                        </>
                        }
                      </Box>
                    </Box>
                    </Box>
                    <Box>
                      <Button variant={ editCommentId === comment.id ? "disabled" : "secondary"} onClick={() => handleShowEdit(comment.id)}><Edit/></Button>
                    </Box>
                  </MenuItem>
          ))}
              </div>
              )) : <div>Aucun contact ajoutée.</div>}


            </Menu>

          </Modal>
        )
      }
    </div>
  );
}

export default BCRM;