import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography, Autocomplete, IconButton, useMediaQuery, useTheme, Avatar, Modal, CircularProgress } from '@mui/material';
import * as Yup from 'yup';
import api from '../../api/Api_1_3';
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MzErrorText from '../../components/ui/MzErrorText';
import { ArrowBack, Close } from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import MzLabel from '../../components/ui/MzLabel';
import { colors } from '../../theme/Colors';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import getCroppedImg from "../../utils/cropImage";
import defaultLogo from "../../imgs/logo.png";
import Stepper from '../../components/ui/Stepper'

const CreateProfile = ({id, onClose}) => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const steps = ['Informations Basic', 'Images']
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false);

    // Cover Section

const [coverImage, setCoverImage] = useState();
const [onCoverImage, setOnCoverImage] = useState(false);
const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
const [coverZoom, setCoverZoom] = useState(1);
const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
const [coverFormat, setCoverFormat] = useState("");
const [coverProgress, setCoverProgress] = useState(0);
const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
const coverImageProfile = useRef();
const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };


const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
  setCoverCroppedAreaPixels(croppedAreaPixels);
};

const onCoverCrop = async () => {
  const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
  setCoverImage(URL.createObjectURL(croppedImage));
  var file = new File([croppedImage], `${coverFormat.split("/")[1]}`, { type: [`${coverFormat}`] });
  if (file && croppedImage) {
    setOnCoverImage(false);
    formikRef.current.values.business_cover_picture = file;
    file = null;
  }
};


const theme = useTheme();
const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

const getAvatarStyle = () => {
  let width;

  if (isXsScreen || isSmScreen) {
    width = '100%';
  } else if (isMdScreen) {
    width = '45.3125rem'; // 725px
  } else if (isLgScreen) {
    width = '45.3125rem'; // 725px
  }

  return {
    width
  };
};


const formikRef = useRef();

// End: Cover Section

// Profile Picture Section
const [image, setImage] = useState(defaultLogo);
const [format, setFormat] = useState("");
const [onImage, setOnImage] = useState(false);
const [crop, setCrop] = useState({ x: 0, y: 0 });
const [zoom, setZoom] = useState(1);
const [progress, setProgress] = useState(0);
const [croppedAreaPixels, setCroppedAreaPixels] = useState();
const [countries, setCountries] = useState([]);
const onCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};
const onCrop = async () => {
  const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
  setImage(URL.createObjectURL(croppedImage));
  var file = new File(
    [croppedImage],
    `${Math.floor(Math.random() * 100000000000000)}.${
      format.split("/")[1]
    }`,
    { lastModified: 1534584790000, type: [`${format}`] }
  );
  if (file && croppedImage) {
    setOnImage(false);
    formikRef.current.values.business_logo = file;
  }
};

const [onUploadImage, setOnUploadIage] = useState(false);


const validationSchema = Yup.object().shape({
        business_commercial_name: Yup.string().required('Commercial Name is required'),
        city_name: Yup.string().required('City is required'),
        country_name: Yup.string().required('Country is required'),
        address: Yup.string().required('Address is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone_number: Yup.number().required('Phone number is required'),
        function: Yup.string().required('Function is required'),
        postal_code:Yup.string().required('Code Postal is required'),
});

// END Profile Picture Section

const handleSubmit = async (values, { setSubmitting }) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            if (values[key]) { // Only append if the value is not empty
                formData.append(key, values[key]);
            }
        });
        
        try {
           const res = await api.post('/profiles', formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            if(res.status === 201){
                toast.success('Profile Ajouteé avec succès');
                setTimeout(()=>{
                    onClose()
                },1000)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setSubmitting(false);
        }
};

const handleNext = async () => {
  // Validate required fields before moving to the next step
  const errors = await formikRef.current.validateForm();
  formikRef.current.setTouched({
    business_commercial_name:true,
    email: true,
    phone_number: true,
    department: true,
    function: true,
    country_name: true,
    city_name: true,
    postal_code: true,
    address: true,
  });

  if (Object.keys(errors).length === 0) {
      window.scrollTo(0, 0);
      setActiveStep((prevStep) => prevStep + 1); // Move to the next step
  } else {
      toast.error('Please fill in all required fields.'); 
  }
};

const handleBack = () => {
  window.scrollTo(0, 0);
  setActiveStep((prevStep) => prevStep - 1); // Move to the previous step
};

const validateImageSize = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width >= 725 && img.height >= 360) {
        formikRef.current.setFieldValue("business_cover_picture", file);
        resolve(true);
      } else {
        reject(new Error("L'image doit avoir une taille minimale de 725 x 360 pixels."));
      }
    };
    img.onerror = () => reject(new Error("Erreur lors du chargement de l'image."));
  });
};


const FieldStyle = ()=> {
  return {
    p:.4,
    backgroundColor: colors.smokeGray,
    borderRadius:'8px'
  }
}

const fetchCountries = async () => {
  const response = await axios.get('https://restcountries.com/v3.1/all');
  return response.data.map(country => ({
    code: country.cca2,
    name: country.name.common,
    geonameId: country.geonameId || null,
  }));
};

useEffect(() => {
  const getCountries = async () => {
    const countries = await fetchCountries();
    setCountries(countries);
  };

  getCountries();
}, []);

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            my: 0, 
            width: { xs: '100%' }, 
            mx: {
                xs:0,
                sm:0,
                md:'auto',
                lg:'auto',
                xl:'auto'
            },
        }}>
          <Box display="flex" alignItems="center" justifyContent='space-between' width="100%" gap={2}>
            {activeStep > 0 && 
              <Box display={'flex'} sx={{cursor:'pointer'}} alignSelf={'flex-start'} alignItems={'center'} gap={1} onClick={()=> handleBack()}>
                <ArrowBack/>
              </Box>
            }
            <Typography variant='h6' mb={4} alignSelf={'center'}>Créer un nouveau profil</Typography>
            <Box display={'flex'} onClick={()=> onClose()} sx={{cursor:'pointer'}} alignSelf={'flex-start'} alignItems={'center'} gap={1}>
              <Close/>
            </Box>
          </Box>

            <Box my={2} p={0}>
              <Stepper steps={steps} activeStep={activeStep}/>
            </Box>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    user_id: id,
                    business_commercial_name:'',
                    email: '',
                    phone_number: '',
                    department: '',
                    function: '',
                    country_name: '',
                    city_name: '',
                    postal_code: '',
                    address: '',
                    business_logo: '',
                    business_cover_picture: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, values, setFieldValue, errors }) => (
                    <Form>
                        {activeStep == 1 &&
                          <Box display={'flex'} flexDirection={{
                            xs:'column',
                            sm:'column',
                            md:'column',
                            lg:'column',
                            xl:'column'
                        }}
                        alignItems={'center'}
                        gap={2}
                        width="100%"
                        >
                        {/* Logo */}
                          <Box display="flex" flexDirection="column"  >
                              <MzLabel>Logo</MzLabel>
                              <Avatar
                          sx={{
                            position: "relative",
                            borderRadius: "20px",
                            width: "12rem",
                            height: "12rem",
                            border: "1px solid #C9C5D2",
                            marginBottom: "1rem",
                          }}
                          variant="rounded"
                        >
                          {image ? (
                            <img
                              src={image}
                              alt="The current file"
                              style={{ width: "13rem" }}
                            />
                          ) : (
                            <img
                              src={values.business_logo}
                              alt="The current file"
                              style={{ width: "13rem" }}
                            />
                          )}
                          <IconButton
                            sx={{
                              zIndex: "33",
                              position: "absolute",
                              right: "0.5rem",
                              bottom: "0.5rem",
                              bgcolor: `${colors.main_purple}`,
                              "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                            }}
                            component="label"
                          >
                            <input
                              hidden
                              id="business_logo"
                              name="business_logo"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "business_logo",
                                  event.target.files[0]
                                    ? event.target.files[0]
                                    : null
                                );
                                if (event.target.files[0]) {
                                  setOnImage(true);
                                  setImage(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  setFormat(event.target.files[0].type);
                                }
                              }}
                            />
                            <AddRoundedIcon htmlColor="white" />
                          </IconButton>
                              </Avatar>
                              <Modal
                          sx={{ backgroundColor: "#3f3f3f" }}
                          open={onImage}
                          onClose={() => {
                            setImage(null);
                            formikRef.current.values.business_logo = "";
                            setOnImage(false);
                          }}
                        >
                          <Box height="100%" width="100%" margin="0 auto">
                            <Box
                              sx={{
                                height: "80%",
                                width: "80%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                height="100%"
                                width="100%"
                                display="flex"
                                flexDirection="column"
                              >
                                <Cropper
                                  style={styleCropper}
                                  image={image}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1 / 1}
                                  onCropChange={setCrop}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                />
                              </Box>
                            </Box>
                            <Box
                              position="absolute"
                              bottom="5%"
                              left="0"
                              right="0"
                              gap="1rem"
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <MzErrorText>
                                {errors.business_logo ? errors.business_logo : null}
                              </MzErrorText>
                              <Box
                                width="fit-content"
                                display="flex"
                                flexDirection="row"
                                gap="1rem"
                                alignItems="center"
                              >
                                <Button
                                  disabled={errors?.business_logo ? true : false}
                                  onClick={onCrop}
                                  variant="primary"
                                >
                                  Confirmer
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    setImage(null);
                                    formikRef.current.values.business_logo = "";
                                    setOnImage(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Box>
                              <Box
                                display="flex"
                                gap="0.5rem"
                                sx={{
                                  flexDirection: {
                                    xs: "column",
                                    md: "row",
                                    lg: "row",
                                    sm: "row",
                                  },
                                  alignItems: "center",
                                }}
                              >
                                <Typography color="white">
                                  Niveau de zoom :{" "}
                                </Typography>
                                <input
                                  type="range"
                                  min={1}
                                  max={3}
                                  step={0.1}
                                  value={zoom}
                                  onInput={(e) => setZoom(e.target.value)}
                                />
                              </Box>
                            </Box>
                          </Box>
                              </Modal>
                              <ErrorMessage name="business_logo" component={MzErrorText} />
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                gap="0.5rem"
                                sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                              >
                                <Box>
                                  {onUploadImage && (
                                    <Box display="flex" alignItems="center" gap="1rem">
                                      <CircularProgress size={30} />
                                      <Typography width="20px" color={colors.main_purple}>
                                        {progress ? progress + "%" : null}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                          </Box>

                        {/* Cover Picture Section */}
                          <Box display="flex" flexDirection="column">
                          <MzLabel>Coverture du business </MzLabel>
                            <Avatar
                              sx={[getAvatarStyle(),{
                                position: "relative",
                                borderRadius: "20px",
                                height: "22.5rem", // 360px
                                width:'100%',
                                border: "1px solid #C9C5D2",
                                marginBottom: "1rem",
                              }]}
                              variant="rounded"
                            >
                              {coverImage ? (
                                  <img alt="cover-pic" ref={coverImageProfile} src={coverImage} style={{ width: "100%", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                                ) : (
                                  <img alt="cover-pic" src={'https://placehold.co/725x360'} style={{ width: "45.3125rem", height: "22.5rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                              )}                          
                              <IconButton
                                sx={{
                                  zIndex: "33",
                                  position: "absolute",
                                  right: "0.5rem",
                                  bottom: "0.5rem",
                                  bgcolor: `${colors.main_purple}`,
                                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                                }}
                                component="label"
                              >
                                <input
                                  hidden
                                  id="business_cover_picture"
                                  name="business_cover_picture"
                                  type="file"
                                  onChange={async (event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                      try {
                                        await validateImageSize(file);
                                        setOnCoverImage(true);
                                        setCoverImage(URL.createObjectURL(file));
                                        setCoverFormat(file.type);
                                      } catch (error) {
                                        toast.error(error.message); 
                                      }
                                    }
                                  }}
                                />
                                <AddRoundedIcon htmlColor="white" />
                              </IconButton>
                            </Avatar>
                            <Modal
                              sx={{ backgroundColor: "#3f3f3f" }}
                              open={onCoverImage}
                              onClose={() => {
                                setCoverImage(null);
                                formikRef.current.values.business_cover_picture = "";
                                setOnCoverImage(false);
                              }}
                            >
                              <Box height="100%" width="100%" margin="0 auto">
                                <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                  <Box height="100%" width="100%" display="flex" flexDirection="column">
                                    <Cropper style={styleCropper} image={coverImage} crop={coverCrop} zoom={coverZoom} aspect={725 / 360} onCropChange={setCoverCrop} onCropComplete={onCoverCropComplete} onZoomChange={setCoverZoom} />
                                  </Box>
                                </Box>
                                <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                  <MzErrorText>{errors.business_cover_picture ? errors.business_cover_picture : null}</MzErrorText>
                                  <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                    <Button disabled={errors?.business_cover_picture ? true : false} onClick={onCoverCrop} variant="primary">
                                      Confirmer
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() => {
                                        setCoverImage(null);
                                        formikRef.current.values.business_cover_picture = "";
                                        setOnCoverImage(false);
                                      }}
                                    >
                                      Annuler
                                    </Button>
                                  </Box>
                                  <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                    <Typography color="white">Niveau de zoom : </Typography>
                                    <input type="range" min={1} max={3} step={0.1} value={coverZoom} onInput={(e) => setCoverZoom(e.target.value)} />
                                  </Box>
                                </Box>
                              </Box>
                            </Modal>
                            <Box>
                              {errors.business_cover_picture ? <MzErrorText>{errors.business_cover_picture}</MzErrorText> : null}
                            </Box>

                          <Box display="flex" flexDirection="column" alignItems="end" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                            <Box>
                              {onUploadCoverImage && (
                                <Box display="flex" alignItems="center" gap="1rem">
                                  <CircularProgress size={30} />
                                  <Typography width="20px" color={colors.main_purple}>
                                    {coverProgress ? coverProgress + "%" : null}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          </Box>
                          </Box>
                        }

                        {/* Customer details */}
                        {activeStep == 0 &&
                          <Grid container spacing={3}>

                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="business_commercial_name"
                                    as={TextField}
                                    label="Nom Commercial"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="business_commercial_name" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="email"
                                    as={TextField}
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="email" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="phone_number"
                                    as={TextField}
                                    label="Téléphone"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="phone_number" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="address"
                                    as={TextField}
                                    label="Address"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="address" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                options={countries}
                                getOptionLabel={(option) => option.name || ''}
                                value={countries.find(c => c.name === values.country_name) || null}
                                onChange={(event, newValue) => {
                                  setFieldValue('country_name', newValue ? newValue.name : '');
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Pays*"
                                    variant="outlined"
                                    size="medium"
                                    InputProps={{
                                      sx: { backgroundColor: colors.smokeGray, borderRadius:'8px',  p: '6px !important' },
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                              />
                                <ErrorMessage name="country_name" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="city_name"
                                    as={TextField}
                                    label="Ville"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="city_name" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="postal_code"
                                    as={TextField}
                                    label="Code Postal"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="postal_code" component={MzErrorText} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="function"
                                    as={TextField}
                                    label="Fonction"
                                    fullWidth
                                    size="medium"
                                    InputProps={{
                                      sx: FieldStyle()
                                    }}
                                />
                                <ErrorMessage name="function" component={MzErrorText} />
                            </Grid>
                          </Grid>
                        }

                        {/* Create Action */}
                        <Box display={'flex'} mt={2} width="100%" justifyContent={'center'} alignItems={'stretch'} gap={2}>
                
                          {activeStep === 1 && 
                            <Button
                              type="submit"
                              variant="form"
                              sx={{paddingX: 5, paddingY:1, width:'100%'}}
                            >           
                              {loading ? <CircularProgress size={30} sx={{color: 'white'}} /> : 'Enregistrer'}
                            </Button>
                          }
                          {activeStep === 0 && 
                            <Button
                              variant="form"
                              onClick={() => handleNext()}
                              sx={{paddingX: 5, paddingY:1, width:'100%'}}
                             >
                              Suivant
                            </Button>
                          }
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateProfile;