import React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Autocomplete,
  Avatar,
  Modal,
  CircularProgress,
  styled,
  Paper,
  Stack,
} from "@mui/material";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import MainTitle from "../../components/ui/MainTitle";
import { ToastContainer, toast } from "react-toastify";
import defaultLogo from "../../imgs/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AlertError from '../../components/ui/AlertError'
import API from "../../api/Api_1_3";
import Api from "../../api/Api";
import MzLabel from "../../components/ui/MzLabel";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Excel from '../../imgs/excel.svg'
import IconLink from "../../components/ui/IconLink";
import { ModalStyle } from "../../theme/ModalStyles";
import { Urls } from "../../api/Urls";
import ConfirmationModal from '../../components/ui/ConfirmationModal'
import FileIcon from "../../imgs/file.svg";
import MyIconButton from "../../components/ui/MyIconButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from "axios";


function CadminForm() {
  const { user } = useAuthContext();
  const [errors,setErrors] = useState([])
  const [isExpired,setIsExpired] = useState(false)
  const location = useLocation()
  const token = location.search.split('=')[1];
  const [company,setCompany] = useState(
    {
      social_reason: "",
      commercial_name: "",
      commercial_register: "",
      nif: "",
      nis: "",
      country_name: "",
      city_name: "",
      postal_code: "",
      address: "",
      email:"",
      phone_number:"",
      fix:"", 
      url_collaborators_file:''
    }
  )
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  //initial values
  const initialValues = {
    social_reason: company?.social_reason ?? "",
    commercial_name: company?.commercial_name ?? "",
    commercial_register: company?.commercial_register ?? "",
    nif: company?.nif ?? "",
    nis: company?.nis ?? "",
    receiver_last_name: company?.receiver_last_name ?? "",
    receiver_first_name: company?.receiver_first_name ?? "",
    receiver_phone_number: company?.receiver_phone_number ?? "",
    country_name: company?.country_name ?? "",
    city_name: company?.city_name ?? "",
    postal_code: company?.postal_code ?? "",
    address: company?.address ?? "",
    phone_number: company?.phone_number ?? "",
    email: company?.email ?? "",
    fix: company?.fix ?? "",
    website: company?.website ?? "",
    default_background_color: company?.default_background_color ?? colors.main_purple,
    default_text_color: company?.default_text_color ?? "#FFFFFF",
    logo:null,
    collaborators_file:"",
    brochure:""
  };

  const XLSX_SUPPORTED_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "application/vnd.ms-excel", // XLS
  ];

  const PDF_SUPPORTED_FORMATS = [
    "file/pdf"
  ];

  //validation schema
  const validationSchema = Yup.object({
    social_reason: Yup.string().required("Ce champ est obligatoire"),
    commercial_name: Yup.string().required("Ce champ est obligatoire"),
    receiver_last_name:Yup.string().required("Ce champ est obligatoire"),
    receiver_first_name:Yup.string().required("Ce champ est obligatoire"),
    commercial_register: Yup.string().required("Ce champ est obligatoire"),
    receiver_phone_number:Yup.string().required("Ce champ est obligatoire"),
    country_name: Yup.string().required("Ce champ est obligatoire"),
    postal_code: Yup.string().required("Ce champ est obligatoire"),
    city_name: Yup.string().required("Ce champ est obligatoire"),
    address: Yup.string().required("Ce champ est obligatoire"),
    email: Yup.string().email('Email invalide'),
    phone_number:Yup.number(),
    fix:Yup.number(),
    website: Yup.string(),
    logo: Yup.mixed().required("Ce champ est obligatoire"),
    default_background_color: Yup.string(),
    default_text_color: Yup.string(),
    nif: Yup.string().required("Ce champ est obligatoire"),
    nis: Yup.string().required("Ce champ est obligatoire"),
    collaborators_file: Yup.mixed()
    .required("Ce champ est obligatoire")
    .test(
      "fileFormat",
      "Format invalide",
      function (value) {
        if (this.parent.collaborators_file) {
          return XLSX_SUPPORTED_FORMATS.includes(value?.type);
        }
        return true;
      }
    ),
  });

  const ShowByToken = async ()=> {
    try{
      const res = await API.get('/enterprises/show-by-token/' + token)
      const data = await res.data?.data
      if(res.status === 200){
        setCompany(data)
        setLinks(data.links ?? [])
        setDocs([])
      }
      else{
        toast.error(res.error.message)
      }
    }
    catch(error){
      toast.error(error.message)
    }
  }
  

  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      "Accept":'application/json'
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgress(percentCompleted);
    },
  };
  const [onUploadImage, setOnUploadIage] = useState(false);
  //submit entreprise

  const onSubmitCreateEntreprise = (values) => {
    setErrors([]) 
    setOnUploadIage(true);
    const formData = new FormData();

    formData.delete('plan_id')
    formData.delete('type')
    formData.delete('receiver_email')

    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value !== null && value !== "") {
          formData.append(key, value);
      }
    });

    if(docs[0].brochure && values.brochure !== null  ){
      formData.append('brochure',docs[0].brochure)
    }
    if(links.length !== 0){
      // Append each link object to the formData object
      links.forEach((link, index) => {
        formData.append(`links[${index}][link_id]`, link.link_id);
        formData.append(`links[${index}][title]`, link.title);
        formData.append(`links[${index}][value]`, link.value);
      });
    }

    API.post(
      '/enterprises/store-auto/' + token,
      formData,
      configs
    )
      .then((response) => {

        if (response.status === 200) {
          setIsSubmitted(true)
        }
        setOnUploadIage(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error.response.data.message)
          setErrors(!error.response.data.message ? error.response.data.errors : [])
        }
        setOnUploadIage(false);
      });
  };

  //logo state
  const [image, setImage] = useState(defaultLogo);
  const [selectedFileName, setSelectedFileName] = useState("");
  const FILE_SIZE = 5000 * 1024;


  //crop image config
  const formikRef = useRef();
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const[isSubmitted,setIsSubmitted] = useState(false)
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File(
      [croppedImage],
      `${Math.floor(Math.random() * 10000000000000000)}.${
        format.split("/")[1]
      }`,
      { lastModified: 1534584790000, type: [`${format}`] }
    );
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.logo = file;
    }
  };

  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };

  useEffect(()=>{
    ShowByToken()
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  },[])


  const [inLinks, setInLinks] = useState(true);
  const [inDocuments, setInDocuments] = useState(false);
  const [currentVariantLink, setCurrentVariantLink] = useState("primary");
  const [currentVariantDocument, setCurrentVariantDocument] = useState("secondary");
  const [links, setLinks] = useState([]);
  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [selectedDocToDelete, setSelectedDocToDelete] = useState();
  const [allowOtherField, setAllowOtherField] = useState();
  //modal config
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [possibleLinksList, setPossibleLinksList] = useState();

  //trigger reload infos
  const [didUpdate, setDidUpdate] = useState(false);
  const [didUpdateLink, setDidUpdateLink] = useState(false);
  const [didDeleteLink, setDidDeleteLink] = useState(false);
  const [didAddLink, setDidAddLink] = useState(false);
  const [didDeleteDoc, setDidDeleteDoc] = useState(false);
  const [didAddDoc, setDidAddDoc] = useState(false);
  const [data, setData] = useState();
  const [docs, setDocs] = useState([]);
  const [docToAddName, setDocToAddName] = useState('');

  const addDoc = (values) => {
      setDocs([{
        title: values.brochure.name,
        brochure: values.brochure,
      }])
      handleCloseAddDoc()
  };

  const deleteDoc = (id_doc) => {
    Api.delete(
      Urls.DELETE_USER_DOCUMENT + `/${id_doc}`,

      {
        headers: {
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          setDidDeleteDoc(!didDeleteDoc);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const handleButtonVariantChangeLink = () => {
    if ((currentVariantDocument === "secondary" && currentVariantLink === "primary") || (currentVariantDocument === "primary" && currentVariantLink === "secondary")) {
      setCurrentVariantLink("primary");
      setCurrentVariantDocument("secondary");
    }
  };
  const handleButtonVariantChangeDocument = () => {
    if ((currentVariantLink === "secondary" && currentVariantDocument === "primary") || (currentVariantLink === "primary" && currentVariantDocument === "secondary")) {
      setCurrentVariantDocument("primary");
      setCurrentVariantLink("secondary");
    }
  };

  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
  };

  //initial values doc
  const initialValuesAddDoc = {
    brochure: "",
  };

  
  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
  });

  
  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    brochure: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

    
  //add link modal
    const handleOpenAddLink = () => {
      if (data?.id === null || undefined) {
        toast.error("Aucune ligne séléctionnée");
        return;
      }
      setOpenAddLink(true);
    };
    
    const handleCloseAddLink = () => setOpenAddLink(false);
  
    //delete link modal
    const handleOpenDelete = () => {
      setOpenDelete(true);
    };
    const handleCloseDelete = () => setOpenDelete(false);
  
    //delete document modal
    const handleOpenDeleteDoc = () => {
      setOpenDeleteDoc(true);
    };
    const handleCloseDeleteDoc = () => setOpenDeleteDoc(false);
  
    //update link modal
    const handleOpenUpdate = () => {
      setOpenUpdate(true);
    };
    const handleCloseUpdate = () => setOpenUpdate(false);
  
    //add doc modal
    const handleOpenAddDoc = () => {
      setOpenAddDoc(true);
    };
    const handleCloseAddDoc = () => setOpenAddDoc(false);
  
    const [change, setChange] = useState(false);
  

    //delete link
  const deleteLink = (id_link) => {
     setLinks(links.filter((l)=> l.link_id !== id_link))

  };

   //add link
   const addLink = (values) => {
    setLinks([
      ...links,
      {
        link_id: values.link_id,
        title: values.title,
        value: values.value,
      }
    ]);
    

    setDidAddLink(!didAddLink);
    handleCloseAddLink();
  };

  const updateLink = (values) => {
    const updatedLinks = links.map((l) => {
      if (l.link_id === values.link_id) {
        return { ...l, title: values.title, value: values.value };
      }
      return l;
    });
    setLinks(updatedLinks);
    handleCloseUpdate()
  };
  
  const getLogoUrl = (title) => {
    const link = possibleLinksList?.find((link) => link.title === title);
    return link ? link.url_logo : "";
  };


  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const [countries, setCountries] = useState([])
  const formats = [
    {
      id:0,
      name: 'SARL',
    },
    {
      id:1,
      name: 'EURL',
    },
    {
      id:2,
      name: 'SPA',
    },
    {
      id:3,
      name: 'SNC',
    },
    {
      id:4,
      name: 'DC',
    },
    {
      id:5,
      name: 'SCS',
    },
    {
      id:6,
      name: 'Groupement',
    },
  ]

  const fetchCountries = async () => {
    const response = await axios.get('https://restcountries.com/v3.1/all');
    return response.data.map(country => ({
      code: country.cca2,
      name: country.name.common,
      geonameId: country.geonameId || null,
    }));
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountries();
        setCountries(countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
      getCountries();
  }, []);



  return (
    <Box display={'flex'} paddingY={10} height={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      {/* Header Section */}
      {!isSubmitted ?
    <Box>
    <Typography variant="h5" sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>
      Créer votre entreprise !
    </Typography>
    <ToastContainer
      style={{ top: "5rem" }}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />

    {/* FORMULAIRE  */}

    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmitCreateEntreprise(values);
      }}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <Box
            display="flex"
            sx={{
              flexDirection: { lg: "row", xs: "column" },
              gap: "1.5rem",
            }}
          >
            <Box display="flex" flexDirection="column">
                <MzLabel>Logo de l'entreprise</MzLabel>
                <Avatar
                  sx={{
                    position: "relative",
                    borderRadius: "20px",
                    width: "12rem",
                    height: "12rem",
                    border: "1px solid #C9C5D2",
                    marginBottom: "1rem",
                  }}
                  variant="rounded"
                >
                  {image || values?.logo === "" ? (
                    <img
                      src={image}
                      alt="The current file"
                      style={{ width: "13rem" }}
                    />
                  ) : (
                    <img
                      src={values?.logo}
                      alt="The current template"
                      style={{ width: "13rem" }}
                    />
                  )}
                  <IconButton
                    sx={{
                      zIndex: "33",
                      position: "absolute",
                      right: "0.5rem",
                      bottom: "0.5rem",
                      bgcolor: `${colors.main_purple}`,
                      "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                    }}
                    component="label"
                  >
                    <input
                      hidden
                      id="logo"
                      name="logo"
                      type="file"
                      onChange={(event) => {
                        setFieldValue(
                          "logo",
                          event.target.files[0]
                            ? event.target.files[0]
                            : null
                        );
                        if (event.target.files[0]) {
                          setOnImage(true);
                          setImage(
                            URL.createObjectURL(event.target.files[0])
                          );
                          setFormat(event.target.files[0].type);
                        }
                      }}
                    />
                    <AddRoundedIcon htmlColor="white" />
                  </IconButton>
                </Avatar>
                <Modal
                  sx={{ backgroundColor: "#3f3f3f" }}
                  open={onImage}
                  onClose={() => {
                    setImage(null);
                    formikRef.current.values.logo = "";
                    setOnImage(false);
                  }}
                >
                  <Box height="100%" width="100%" margin="0 auto">
                    <Box
                      sx={{
                        height: "80%",
                        width: "80%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        height="100%"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                      >
                        <Cropper
                          style={styleCropper}
                          image={image}
                          crop={crop}
                          zoom={zoom}
                          aspect={1 / 1}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </Box>
                    </Box>
                    <Box
                      position="absolute"
                      bottom="5%"
                      left="0"
                      right="0"
                      gap="1rem"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MzErrorText>
                        {errors.logo ? errors.logo : null}
                      </MzErrorText>
                      <Box
                        width="fit-content"
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        alignItems="center"
                      >
                        <Button
                          disabled={errors?.logo ? true : false}
                          onClick={onCrop}
                          variant="primary"
                        >
                          Confirmer
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setImage(defaultLogo);
                            formikRef.current.values.logo = "";
                            setOnImage(false);
                          }}
                        >
                          Annuler
                        </Button>
                      </Box>
                      <Box
                        display="flex"
                        gap="0.5rem"
                        sx={{
                          flexDirection: {
                            xs: "column",
                            md: "row",
                            lg: "row",
                            sm: "row",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography color="white">
                          Niveau de zoom :{" "}
                        </Typography>
                        <input
                          type="range"
                          min={1}
                          max={3}
                          step={0.1}
                          value={zoom}
                          onInput={(e) => setZoom(e.target.value)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                <ErrorMessage name="logo" component={MzErrorText} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                >
                  <Box>
                    {onUploadImage && (
                      <Box display="flex" alignItems="center" gap="1rem">
                        <CircularProgress size={30} />
                        <Typography width="20px" color={colors.main_purple}>
                          {progress ? progress + "%" : null}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
            </Box>

            {/* SECTION ENTREPRISE INFOS */}
            <Box>
              <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'}>
                <MainTitle
                  title="Informations de l'entreprise"
                  icon="entreprise_purple"
                />
              </Box>

            {/* Company Section */}
              <Box
                display="grid"
                gap={2}
                gridTemplateColumns="repeat(2, 1fr)"
                gridTemplateRows="repeat(7, auto)"
                marginBottom="1rem"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Autocomplete
                    options={formats}
                    getOptionLabel={(option) => option.name || ''}
                    value={formats.find(c => c.name === values.social_reason) || null}
                    onChange={(event, newValue) => {
                      setFieldValue('social_reason', newValue ? newValue.name : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Raison Social*"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  <ErrorMessage
                    name="social_reason"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="commercial_name"
                    as={TextField}
                    value={values?.commercial_name}
                    variant="outlined"
                    label="Nom commercial*"
                    size="small"
                  />
                  <ErrorMessage
                    name="commercial_name"
                    component={MzErrorText}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="plan_id"
                    as={TextField}
                    disabled
                    value={company?.plan}
                    variant="outlined"
                    label=""
                    size="small"
                  />
                  <ErrorMessage
                    name="plan_id"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="commercial_register"
                    as={TextField}
                    value={values?.commercial_register}
                    variant="outlined"
                    label="Registre du Commerce*"
                    size="small"
                  />
                  <ErrorMessage
                    name="commercial_register"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="nif"
                    as={TextField}
                    value={values?.nif}
                    variant="outlined"
                    label="NIF*"
                    size="small"
                    />
                  <ErrorMessage
                    name="nif"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="nis"
                    as={TextField}
                    value={values?.nis}
                    variant="outlined"
                    label="NIS*"
                    size="small"
                    />
                  <ErrorMessage
                    name="nis"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.name || ''}
                    value={countries.find(c => c.name === values.country_name) || null}
                    onChange={(event, newValue) => {
                      setFieldValue('country_name', newValue ? newValue.name : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pays*"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  <ErrorMessage
                    name="country_name"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                      >
                        <Field
                          name="city_name"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          value={values?.city_name}
                          variant="outlined"
                          label="Ville*"
                          size="small"
                        />
                        <ErrorMessage
                          name="city_name"
                          component={MzErrorText}
                        />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="postal_code"
                    as={TextField}
                    value={values?.postal_code}
                    variant="outlined"
                    label="Code Postal*"
                    size="small"
                  />
                  <ErrorMessage
                    name="postal_code"
                    component={MzErrorText}
                  />
                </Box>
                {/* new: email, phone_number and fix */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="phone_number"
                    value={values?.phone_number}
                    as={TextField}
                    variant="outlined"
                    label="Téléphone*"
                    size="small"
                  />
                  <ErrorMessage
                    name="phone_number"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="email"
                    value={values?.email}
                    as={TextField}
                    variant="outlined"
                    label="Email*"
                    size="small"
                  />
                  <ErrorMessage
                    name="email"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="fix"
                    value={values?.fix}
                    as={TextField}
                    variant="outlined"
                    label="Téléphone Fix*"
                    size="small"
                  />
                  <ErrorMessage
                    name="fix"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="website"
                    as={TextField}
                    value={values?.website}
                    variant="outlined"
                    label="Site Web"
                    size="small"
                  />
                  <ErrorMessage
                    name="website"
                    component={MzErrorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    name="address"
                    as={TextField}
                    variant="outlined"
                    label="Address*"
                    value={values?.address}
                    size="small"
                  />
                  <ErrorMessage
                    name="address"
                    component={MzErrorText}
                  />
                </Box>
              </Box>

            </Box>

            {/* Admin Section */}
            <Box>
              <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'}>
                <MainTitle
                  title="Informations de l'admin"
                  icon="person"
                />
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gridTemplateRows="repeat(7, auto)"
                marginBottom="1rem"
              >

                {/* first name */}

                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="receiver_first_name"
                    as={TextField}
                    value={values?.receiver_first_name}
                    variant="outlined"
                    label="Nom*"
                    size="small"
                  />
                  <ErrorMessage
                    name="receiver_first_name"
                    component={MzErrorText}
                  />
                </Box>

                {/* last name */}

                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="receiver_last_name"
                    as={TextField}
                    value={values?.receiver_last_name}
                    variant="outlined"
                    label="Prénom*"
                    size="small"
                  />
                  <ErrorMessage
                    name="receiver_last_name"
                    component={MzErrorText}
                  />
                </Box>

                {/* email */}
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="receiver_email"
                    as={TextField}
                    disabled
                    value={company?.receiver_email}
                    variant="outlined"
                    label=""
                    size="small"
                  />
                  <ErrorMessage
                    name="receiver_email"
                    component={MzErrorText}
                  />
                </Box>

                {/* phone number */}

                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="receiver_phone_number"
                    as={TextField}
                    type="number"
                    value={values.receiver_phone_number}
                    variant="outlined"
                    label="Numéro de téléphone*"
                    size="small"
                  />
                  <ErrorMessage
                    name="receiver_phone_number"
                    component={MzErrorText}
                  />
                </Box>

                {/*  color sliders */}                
                    {/* Background Color */}
                    <Box display="flex" flexDirection={'column'} alignItems="start" gap="1rem">
                    <Typography>Default Background Color:</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Field
                        name="default_background_color"
                        type="color"
                        as={TextField}
                        variant="outlined"
                        size="small"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Typography>
                        <Field name="default_background_color" />
                      </Typography>
                      </Box>
                    </Box>
                    {/* Text Color */}
                    <Box display="flex" flexDirection={'column'} alignItems="start" gap="1rem">
                    <Typography>Default Text Color:</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Field
                          name="default_text_color"
                          type="color"
                          as={TextField}
                          variant="outlined"
                          size="small"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <Typography>
                          <Field name="default_text_color" />
                        </Typography>
                      </Box>
                    </Box>
                {/* Collaborators */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginY: "1.3rem",
                    gridColumn:'span 2 / span 2'

                  }}
                >
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                      <label htmlFor="collaborators_file font-medium text-lg">Fichier Collaborateurs</label>
                      <Link style={{ textDecoration: "none", display:'flex',alignItems:'center', gap:'.5rem' }} to="/BDigitAll Template Fichier Collaborateur.xlsx" target="_blank" download>
                        <img src={Excel} alt="excel_logo" width={20} height={10}/>
                        <p className="hover:underline font-medium hover:underline-offset-2">
                          Télécharger l'exemplaire
                        </p>
                      </Link>
                    </Box>

                    <Button
                      component="label"
                      role={undefined}
                      sx={{'width':'100%'}}
                      variant="upload"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setFieldValue('collaborators_file', file);
                          setSelectedFileName(file.name);
                        }
                      }}
                    >
                        {selectedFileName ? selectedFileName : 'Upload'}
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  <ErrorMessage name="collaborators_file" component={MzErrorText} />
                  { company.url_collaborators_file !== null  ? <a href={company.url_collaborators_file} target="_blank" rel="noreferrer" className="my-1 flex items-center gap-1 current_link font-medium">
                    <img src={Excel} alt="excel_logo" width={20} height={10}/>
                    <p className="text-sm hover:underline font-normal bg-transparent hover:underline-offset-2">Télécharger votre fichier actuelle</p>
                    <OpenInNewIcon sx={{'fontSize':'large'}}/>
                    </a> : null}
                </Box>


              </Box>

                     {/* Links Docs Section */}

              <Box sx={{ maxWidth: { xs: "500px" }, width: { md: "500px" } }}>
          <Box sx={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
            <Button
              onClick={() => {
                setInLinks(true);
                setInDocuments(false);
                handleButtonVariantChangeLink();
              }}
              variant={currentVariantLink}
            >
              Liens
            </Button>
            <Button
              onClick={() => {
                setInDocuments(true);
                setInLinks(false);
                handleButtonVariantChangeDocument();
              }}
              variant={currentVariantDocument}
            >
              Brochure
            </Button>
          </Box>

          {inLinks && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
              {links?.map((l) => (
                <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <IconLink title={l.title} icon={getLogoUrl(l.title)} value={l.value} />
                  <Box>
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="edit"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        if (l.slug === "other") {
                          setAllowOtherField(true);
                        } else {
                          setAllowOtherField(false);
                        }
                        handleOpenUpdate();
                      }}
                    />
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="delete"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        handleOpenDelete();
                      }}
                    />
                  </Box>
                </Box>
              ))}

              <ConfirmationModal title="Supprimer un lien" message="Êtes-vous sûr de bien vouloir supprimer ce lien ?" acceptLabel="oui" refuseLabel="non" open={openDelete} onClose={handleCloseDelete} acceptMethod={deleteLink} acceptMethodParams={selectedLinkToDelete?.link_id} />

              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={handleOpenAddLink}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>

              {/* Modal add link */}
              <Modal open={openAddLink} onClose={handleCloseAddLink}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un lien
                  </Typography>
                  {/* form add link */}
                  <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = {};
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");
                              setFieldValue("url_logo", value ? value.url_logo : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value.title);
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddLink} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add link */}
                </Box>
              </Modal>

              {/* modal update link */}
              <Modal open={openUpdate} onClose={handleCloseUpdate}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Mettre à jour un lien
                  </Typography>

                  {/* form update link */}
                  <Formik initialValues={{ link_id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value ?? "", title: selectedLinkToDelete?.title ?? "" }} validationSchema={validationSchemaAddLink}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            defaultValue={{ id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value, title: selectedLinkToDelete?.title }}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.title === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = { link_id: "" };
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value ? "Site web" : "");
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button
                            variant="primary"
                            onClick={() => {
                              if (isObjEmpty(errors)) {
                                updateLink(values);
                              }
                            }}
                          >
                            Mettre à jour
                          </Button>
                          <Button onClick={handleCloseUpdate} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                        <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form update link */}
                </Box>
              </Modal>
              {/* end modal update link  */}
            </Box>
          )}

          {inDocuments && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                {(docs.length > 0 && docs[0]?.title) && <IconLink title={docs[0]?.title} value={ docToAddName === '' ? company.url_brochure : null} />}
                </Box>
              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={() => {
                  handleOpenAddDoc();
                }}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>
              {/* Modal add DOC */}
              <Modal open={openAddDoc} onClose={handleCloseAddDoc}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter une Brochure
                  </Typography>
                  {/* form add doc */}
                  <Formik initialValues={initialValuesAddDoc} validationSchema={validationSchemaAddDoc} onSubmit={addDoc}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        {progress !== 0 && (
                          <Stack margin="1rem 0rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Typography color={colors.main_purple}>{progress ? progress + "%" : null}</Typography>
                            <CircularProgress sx={{ color: `${colors.main_purple}` }} />
                          </Stack>
                        )}

                        {/* doc file */}
                        <Box display="flex" flexDirection="column" sx={{ marginBottom: "1rem" }}>
                          <Button variant="upload" sx={{ overflow: "hidden", height: "200px", display: "flex", flexDirection: "column" }} component="label">
                            <img src={FileIcon} width="50px" alt="" style={{ marginBottom: "0.5rem" }} />
                            <input
                              style={{ opacity: "0%", width: "100%", height: "100%", position: "absolute" }}
                              id="document"
                              name="document"
                              type="file"
                              onChange={(event) => {
                                const doc = event.target.files[0];
                                setFieldValue("brochure", event.target.files[0]);
                                setDocToAddName(doc.name);
                              }}
                            />
                            <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>Déposer votre fichier</Typography>
                          </Button>
                          <MzErrorText>{errors.brochure}</MzErrorText>
                        </Box>
                        {docToAddName && (
                          <Box>
                            <Typography sx={{ margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }} fontSize="0.875rem">
                              {docToAddName}
                            </Typography>
                          </Box>
                        )}

                        {/* confirm add doc */}
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddDoc} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add DOC */}
                </Box>
              </Modal>
              <ConfirmationModal title="Supprimer un document" message="Êtes-vous sûr de bien vouloir supprimer ce document ?" acceptLabel="oui" refuseLabel="non" open={openDeleteDoc} onClose={handleCloseDeleteDoc} acceptMethod={deleteDoc} acceptMethodParams={selectedDocToDelete?.id} />
            </Box>
          )}
              </Box>
                {/* End links docs section */}
              <Box display={'flex'} flexDirection={'row'} gap={'.5rem'} mt={3}>
                <Button type="submit" variant="primary">Soumettre</Button>
              </Box>

            </Box>

          </Box>
        </Form>
      )}
      
    </Formik>

      


    {errors.length !== 0 && <AlertError>
      <ul className="list-disc">
        {errors.map((error,index)=>(
          <ul>
            <li key={index} className="flex items-center gap-2">
              <p><strong>Row:</strong> {error.row}</p>
              <p>{error.msg}</p>
            </li>
          </ul>
        ))}
      </ul>
    </AlertError>}
    </Box>  
   :
    <Box display={'flex'} flexDirection={'column'} height={'100%'} alignItems={'center'} justifyContent={'center'} gap={4}>
      <CheckCircleOutlineIcon sx={{'color':colors.mjGreenOutline, width:'12rem', height:'12rem'}}/>
      <Typography variant="h5" fontWeight={600} color="initial">Envoyé Avec Succès</Typography>
      <Typography variant="p" fontWeight={400} textAlign={'center'} color="initial">Votre demande a été envoyée avec succès, vous recevrez bientôt un email !</Typography>
    </Box>
    }
    </Box>
  );
}

export default CadminForm;
