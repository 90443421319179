import React from "react";
import { useState } from "react";
import { Box, TextField, Button, Typography, Modal } from "@mui/material";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";

import { toast, ToastContainer } from "react-toastify";
import { ModalStyle } from "../../theme/ModalStyles";
import { useNavigate } from "react-router-dom";

function CreateTicket() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const idEnt = user.user.enterprise.id;

  //validation schema create ticket fields
  const validationSchema = Yup.object({
    motif: Yup.string().required("Ce champ est obligatoire"),
    message: Yup.string().required("Ce champ est obligatoire").max(500, "Message doit pas dépasser 500 caractères"),
  });

  //modal config
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitCreateTicket = (values) => {
    Api.post(
      Urls.CREATE_TICKET + `/${idEnt}/tickets`,
      {
        motif: values.motif,
        message: values.message,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 201) {
          handleClose();
         
          toast.success("ticket créé avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ marginBottom: "2rem", fontWeight: "500" }}>Création d'un ticket</Typography>
      </Box>
      {/* Formulaire création tiquet */}
      <Box>
        <Formik
          initialValues={{ motif: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitCreateTicket(values, access_token);
          }}
        >
          {({ values, errors }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box>
                  <Field name="motif" type="input" label="Motif" id="motif" as={TextField} size="small" />
                  <ErrorMessage name="motif" component={MzErrorText} />
                </Box>
                <Box maxWidth={650}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Field name="message" label="Message" id="message" multiline fullWidth columns={5} rows={4} as={TextField} />
                    <ErrorMessage name="message" component={MzErrorText} />
                  </Box>
                  <Box display="flex" flexDirection="row-reverse">
                    <Button variant="primary" onClick={handleOpen}>
                      Créer
                    </Button>
                  </Box>
                  <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

                  <Modal open={open} onClose={handleClose}>
                    <Box sx={ModalStyle}>
                      <Typography fontSize="1.5rem" fontWeight="600" mb>
                        Création d'un ticket
                      </Typography>
                      <Typography mb="1.5rem">Etes vous sur de vouloir créer ce ticket</Typography>
                      <Box display="flex" flexDirection="row" gap="0.5rem">
                        <Button variant="primary" onClick={() => onSubmitCreateTicket(values)}>
                          Oui
                        </Button>
                        <Button onClick={handleClose} variant="third">
                          Non
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default CreateTicket;
